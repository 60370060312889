import React from 'react';
import './SimpleTextCard.css';

class SimpleTextCard extends React.Component {
    render() {
        const color = this.props.color || "black";
        return (
            <div className="SimpleTextCard-Container" style={{ backgroundColor: color }}>
                <div className="SimpleTextCard-Center-Text">
                    <h1 className="SimpleTextCard-Title">{this.props.Title}</h1>
                    <h4 className="SimpleTextCard-Description">{this.props.Description
                    }</h4>
                </div>
            </div>
        )
    }
}

export default SimpleTextCard;