import './Service3.css';
import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { checkArray } from '../../../../ValidateInput';
import DOMPurify from 'dompurify';

class Service3 extends React.Component {
    render() {
        return (
            <Row justify='center' style={{ backgroundColor: "white", padding: "20px" }}>
            <Col xs={24}>
                <h2 className="Contact1-Title">My Services</h2>
            </Col>
            <Col xs={24}>
                <Row justify="center" gutter={[10,10]}>
                    {
                        checkArray(this.props.Services).map((service) => {
                            return (
                                <Col xs={24} sm={12}>
                                    <div
                                    style = {{
                                        background: `linear-gradient(rgba(0,0,0,.6), rgba(0,0,0,.6)), url(${service.ImageURL})`,
                                        backgroundPosition: "center",
                                        backgroundSize: "cover",
                                        borderRadius: "5px",
                                    }}
                                    >
                                        <div className="Service3-Text-Div">
                                        <h2 className="Service3-Title">{service.Title}</h2>
                                        <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(service.Description)}} className="Service3-Description" />
                                        {
                                            service.ExternalLink ?
                                            <a href={service.Link} className="Service-Button-Inverted">{service.ButtonText}</a>
                                            :
                                            <Link to={service.Link} className="Service-Button-Inverted">{service.ButtonText}</Link>
                                        }
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Col>
            </Row>
        )
    }
}

export default Service3;