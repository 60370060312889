import { Route, BrowserRouter as Router, Switch} from 'react-router-dom';
import { Landing,
  LiveClasses,
  LiveClassPage,
  BookLiveClass,
  BookingSuccess,
  FreeBookingSuccess,
  OnDemandClasses,
  Checkout,
  CheckoutSuccess,
  CustomerFitness,
  Bootcamps,
  Programs,
  Memberships,
  ProgramOverview,
  ProgramWorkout,
  ProgramExercise,
  } from './Pages';
import { DataProvider } from './Context/Data';
import { AuthProvider } from './Context/Auth';
import PrivateRoute from './PrivateRoute';

// Redux
import { Provider } from 'react-redux';
import store from './Redux/store';

function App() {
  return (
    <AuthProvider>
    <DataProvider>
      <Provider store={store}>
        <Router>
          <Switch>
          <Route exact path='/' component={Landing} />
          <Route exact path='/live-classes' component={LiveClasses} />
          <Route exact path='/live-class/:ID' component={LiveClassPage} />
          <Route exact path='/book-live-class/:ID/:AID' component={BookLiveClass} />
          <Route exact path="/free-live-class-booking" component={FreeBookingSuccess} />
          <Route exact path="/free-booking" component={FreeBookingSuccess} />
          <Route exact path='/live-class-booking-success/:LiveBookingID' component={BookingSuccess} />
          <Route exact path='/on-demand-classes' component={OnDemandClasses} />
          <Route exact path="/checkout" component={Checkout} />
          <Route exact path="/bootcamps" component={Bootcamps} />
          <Route exact path="/programs" component={Programs} />
          <Route exact path="/memberships" component={Memberships} />
          <Route exact path="/checkout-booking-success/:BookingID" component={CheckoutSuccess} />
          <PrivateRoute exact path="/my-fitness" component={CustomerFitness} />
          <PrivateRoute exact path="/program/:ID" component={ProgramOverview} />
          <PrivateRoute exact path="/program/:ID/:WeekIndex/:DayIndex/:WorkoutIndex" component={ProgramWorkout} />
          <PrivateRoute exact path="/program/:ID/:WeekIndex/:DayIndex/:WorkoutIndex/:ExerciseIndex/:SupersetIndex" component={ProgramExercise} />
          </Switch>
        </Router>
      </Provider>
    </DataProvider>
    </AuthProvider>
  );
}

export default App;
