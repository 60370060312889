import './Testimonial1.css';
import React from 'react';
import { Row, Col } from 'antd';
import { checkArray } from '../../../../ValidateInput';

class Testimonial1 extends React.Component {
    render() {
        return (
            <Row justify="center" style={{ backgroundColor: "white", padding: "10px" }}>
            <Col xs={24}>
                <h2 className="Contact1-Title">Testimonials</h2>
            </Col>
            <Col xs={24}>
                <Row gutter={[30,30]}>
                    {
                        checkArray(this.props.Testimonials).map((test) => {
                            return (
                                <Col xs={24}>
                                    <Row align='middle'>
                                        <Col xs={24} sm={12} md={10}>
                                            <div className="Testimonial1-Image-Div">
                                            <img src={test.ImageURL} alt={test.Title} className="Testimonial1-Image" />
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={12} md={14}>
                                            <div className="Testimonial1-Div">
                                            <h2 className="Testimonial1-Title">{test.Name}</h2>
                                            <p className="Testimonial1-Paragraph">{test.Description}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Col>
            </Row>
        )
    }
}

export default Testimonial1;