import './Service1.css';
import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { checkArray } from '../../../../ValidateInput';
import DOMPurify from 'dompurify';

class Service1 extends React.Component {
    render() {
        return (
            <Row justify='center' style={{ backgroundColor: "white" }}>
            <Col xs={24}>
                <h2 className="Contact1-Title">My Services</h2>
            </Col>
            <Col xs={24}>
                <Row justify="center" gutter={[20,20]}>
                    {
                        checkArray(this.props.Services).map((service) => {
                            return (
                                <Col xs={24}>
                                    <Row align='middle'>
                                        <Col xs={24} sm={12} md={10}>
                                            <div className="Service1-Image-Div">
                                            <img src={service.ImageURL} alt={service.Title} className="Service1-Image" />
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={12} md={14}>
                                            <div className="Service1-Div">
                                            <h2 className="Service1-Title">{service.Title}</h2>
                                            <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(service.Description)}} className="Service1-Paragraph" />
                                            {
                                                service.ExternalLink ?
                                                <a href={service.Link} className="Service-Button">{service.ButtonText}</a>
                                                :
                                                <Link to={service.Link} className="Service-Button">{service.ButtonText}</Link>
                                            }
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Col>
            </Row>
        )
    }
}

export default Service1;