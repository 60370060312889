const ADD_VIDEO = "ADD_VIDEO";
const ADD_LIVE_CLASS = "ADD_LIVE_CLASS";
const REMOVE_VIDEO = "REMOVE_VIDEO";
const REMOVE_LIVE_CLASS = "REMOVE_LIVE_CLASS";
const ADD_BOOTCAMP = "ADD_BOOTCAMP";
const REMOVE_BOOTCAMP = "REMOVE_BOOTCAMP";
const ADD_PROGRAM = "ADD_PROGRAM";
const REMOVE_PROGRAM = "REMOVE_PROGRAM";

module.exports = {
    ADD_VIDEO,
    ADD_LIVE_CLASS,
    REMOVE_VIDEO,
    REMOVE_LIVE_CLASS,
    ADD_BOOTCAMP,
    REMOVE_BOOTCAMP,
    ADD_PROGRAM,
    REMOVE_PROGRAM,
}