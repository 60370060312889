import React from 'react';
import { Navbar, } from '../../Components';
import { Helmet } from 'react-helmet';
import { DataContext } from '../../Context/Data';
import { Row, Col, message } from 'antd';
import { H1, P, PhrosButton } from '../../Common';
import axios from 'axios';
import { ENVIRONMENT } from '../../constants';
import { Link } from 'react-router-dom';

class CheckoutSuccess extends React.Component {
    componentDidMount() {
        window.scrollTo(0,0);
        this.updateSession();
    }

    updateSession = async () => {
        const payload = {
            BookingID: this.props.match.params.BookingID,
        }
        const response = await axios.post(`${ENVIRONMENT.BACKEND_URL}/stripe/update-cart-checkout-session`, payload);
        if (response.data.error) {
            message.error("Something went wrong when fulfilling your order. Please contact us")
        }
    }
    render() {
        return (
        <DataContext.Consumer>
            {
            data => (
                <div>
                <Helmet>
                <title>{data.profile.BusinessName ? data.profile.BusinessName : 'My Personal Training Website'}</title>
                <meta name="description" content={data.profile.BusinessStatement} />
                <link rel="icon" type="image/png" href={data.profile.LogoURL} />
                </Helmet>
                <Navbar profile={data.profile} events={data.events} />
                <Row justify='center' style={{ height: '60vh'}} align='middle'>
                    <Col xs={23} sm={15}>
                        <H1 text="Thanks for your purchase!" style={{ textAlign: 'center' }} />
                        <h3 style={{ fontWeight: 400, }}>
                        Thank you for your purchase, please look our for a receipt from Stripe with the purchase information (make sure to check your junk folder!). To access your fitness purchase, please go to the <Link to="/my-fitness">my fitness</Link> section.
                        </h3>
                        <P text={`${data.profile.FirstName} ${data.profile.LastName}`} />
                        <Row justify='center'>
                            <Col xs={24} sm={10}>
                                <PhrosButton
                                styles={{ background: data.websiteTemplate?.ColourPalette?.DARK }}
                                text='view your fitness purchase' link='/my-fitness' />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            )
            }
        </DataContext.Consumer>
        )
    }
}

export default CheckoutSuccess;