import React from 'react';
import './ProgramExercise.css';
import { AuthContext } from '../../../Context/Auth';
import { DataContext } from '../../../Context/Data';
import { ENVIRONMENT, WORKOUT_PROGRESS, UNITS, UID } from '../../../constants';
import { SimpleTextCard, Navbar } from '../../../Components';
import { Input, Button, notification, Spin, Row, Col, Divider } from 'antd';
import axios from 'axios';
import { checkArray, checkObj } from '../../../ValidateInput';
import { generateWorkoutProgressKey } from '../../../util';
import Modal from 'antd/lib/modal/Modal';
import { CheckCircleFilled } from '@ant-design/icons';

class ProgramExercise extends React.Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.state = {
            UserProgram: {},
            Exercises: {},
            Loading: true,
            ExerciseInputs: {},
            Workout: {},
            Completed: false,
            Notes: "",
            SupersetExercise: {},
            Inputs: {},
            VideoVisible: false,
        }
    }

    componentDidMount() {
        this.getData();
    }

    // Get Exercise Data (Workout Program and Exercise)
    getData = async () => {
        const { currentUser } = this.context;;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const payload = {
            ID: this.props.match.params.ID,
            UID: UID,
        }
        const response = await axios.post(`${ENVIRONMENT.BACKEND_URL}/user-programs/get-user-program-with-id`, payload, config);
        if (response.data.error) {
            return notification.error ({
                message: "Error",
                description: `Failed to get the program data (${response.data.error})`,
                duration: null,
            })
        } else {
            const inputs = response.data.Program[this.props.match.params.ID]?.Inputs || {};
            const program = response.data.Program[this.props.match.params.ID].Program?.Program;
            const WeekIndex = this.props.match.params.WeekIndex;
            const DayIndex = this.props.match.params.DayIndex;
            const WorkoutIndex = this.props.match.params.WorkoutIndex;
            const ExerciseIndex = this.props.match.params.ExerciseIndex;
            const SupersetIndex = this.props.match.params.SupersetIndex;
            const workout = program?.[WeekIndex]?.week?.[DayIndex]?.day?.[WorkoutIndex] || {};
            const exercise = checkObj(workout?.WorkoutExercises?.[ExerciseIndex]?.exercise?.[SupersetIndex]);
            const workoutProgress = response.data.Program[this.props.match.params.ID]?.WorkoutProgress || {};

            console.log({ workout });

            // Get the Notes and Completion
            const notes_key = generateWorkoutProgressKey(WeekIndex, DayIndex, WorkoutIndex, ExerciseIndex, SupersetIndex, -1, WORKOUT_PROGRESS.NOTES);
            const completed_key = generateWorkoutProgressKey(WeekIndex, DayIndex, WorkoutIndex, ExerciseIndex, SupersetIndex, -1, WORKOUT_PROGRESS.COMPLETED);
            const notes = workoutProgress[notes_key] || "";
            const completed = workoutProgress[completed_key] || false;

            this.setState({
                UserProgram: response.data.Program[this.props.match.params.ID],
                Loading: false,
                Inputs: inputs,
                SupersetExercise: exercise,
                Workout: workout,
                Notes: notes,
                Completed: completed,
            })
        }
        // Loop through Entire Program, Get Inputs needed (those with %)
        const exercises = {
            ...response.data.UserExercises,
            ...response.data.LibraryExercises,
        }
        this.setState({
            Exercises: exercises,
        })
    }

    completeExercise = async  () => {
        this.setState({
            Completed: !this.state.Completed,
        })
        await this.saveProgramInput();
    }

    saveProgramInput = async (setIndex = -1, setCheck = true) => {
        const { currentUser } = this.context;;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const progress = this.state.UserProgram?.WorkoutProgress || {};
        const WeekIndex = this.props.match.params.WeekIndex;
        const DayIndex = this.props.match.params.DayIndex;
        const WorkoutIndex = this.props.match.params.WorkoutIndex;
        const ExerciseIndex = this.props.match.params.ExerciseIndex;
        const SupersetIndex = this.props.match.params.SupersetIndex;
        const notes_key = generateWorkoutProgressKey(WeekIndex, DayIndex, WorkoutIndex, ExerciseIndex, SupersetIndex, -1, WORKOUT_PROGRESS.NOTES);
        const completed_key = generateWorkoutProgressKey(WeekIndex, DayIndex, WorkoutIndex, ExerciseIndex, SupersetIndex, -1, WORKOUT_PROGRESS.COMPLETED);
        const newProgress = {
            ...progress,
            [notes_key]: this.state.Notes,
            [completed_key]: this.state.Completed,
        }

        if (setIndex >= 0) {
            const setKey = generateWorkoutProgressKey(WeekIndex, DayIndex, WorkoutIndex, ExerciseIndex, SupersetIndex, setIndex, WORKOUT_PROGRESS.COMPLETED);
            newProgress[setKey] = setCheck;
            let progress = this.state.UserProgram?.WorkoutProgress;
            progress[setKey] = setCheck;
            let userProgram = {
                ...this.state.UserProgram,
                WorkoutProgress: progress
            }
            this.handleChange("UserProgram", userProgram);
        }

        const payload = {
            ProgramID: this.props.match.params.ID,
            ID: this.state.UserProgram?.UserProgramID,
            UID: UID,
            WorkoutProgress: newProgress,
        }
        const response = await axios.post(`${ENVIRONMENT.BACKEND_URL}/user-programs/save-program-progress`, payload, config);
        if (response.data.error) {
            return notification.error({
                message: "Error",
                description: `Failed to save the program progress (${response.data.error}). If this persists please contact us at support@phros.ca.`
            })
        } else {
            return notification.success({
                message: "Success",
                description: "Successfully saved the program progress."
            })
        }
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value,
        })
    }

    render() {
    return (
    <DataContext.Consumer>
        {
        data => {
            return (
                <div className="ProgramWorkout-Main-Div">
                    <Navbar />
                    {
                        this.state.Loading ?
                        <Row justify="center">
                            <Col><Spin size="large" /></Col>
                        </Row>
                        :
                        <Row justify="center">
                            <Col xs={23}>
                            <SimpleTextCard
                                Title={this.state.Exercises?.[this.state.SupersetExercise?.ExerciseID]?.Title}
                                Description={this.state.Workout?.WorkoutName}
                                color={data.websiteTemplate?.ColourPalette?.DARK}
                            />
                            <Row gutter={[10,20]} className="ProgramOverview-Row">
                                <Col xs={24}>
                                    <h2 className="Customer-Fitness-Section-Title">{this.state.Exercises?.[this.state.SupersetExercise?.ExerciseID]?.Title}</h2>
                                    <p className="ProgramOverview-Input-Subtext">Click "Complete Exercise" once you've finished this exercise.</p>
                                </Col>
                                <Col xs={24}>
                                    <h2 className="Customer-Fitness-Section-Title">Exercise Notes</h2>
                                    <p className="ProgramOverview-Input-Subtext">{this.state.Exercises?.[this.state.SupersetExercise?.ExerciseID]?.Description}</p>
                                </Col>
                                <Col xs={24} sm={12} md={6}>
                                <Button block type="primary" className="ProgramOverview-Calendar-Workout-Button" onClick={() => this.handleChange("VideoVisible", true)}>View Exercise Demonstration</Button>
                                </Col>
                                {
                                    checkArray(this.state.SupersetExercise?.Instructions).map((inst, index) => {
                                        let target = "";
                                        let weight = "";
                                        let metric = this.state.Inputs?.Metric || UNITS.WEIGHT_INPUT.LB;
                                        if (inst.TargetType === UNITS.TARGET_INPUT.REPS) {
                                            target = `${inst.Target} reps`
                                        } else if (inst.TargetType === UNITS.TARGET_INPUT.TIMED) {
                                            if (inst.Hours > 0) {
                                                target = `${inst.Hours} hours, ${inst.Minutes} minutes, ${inst.Seconds} seconds`;
                                            } else if (inst.Hours === 0 && inst.Minutes > 0) {
                                                target = `${inst.Minutes} minutes, ${inst.Seconds} seconds`;
                                            } else {
                                                target = `${inst.Seconds} seconds`;
                                            }
                                        } else {
                                            target = inst.Target;
                                        }

                                        if (inst.WeightType === UNITS.WEIGHT_INPUT.BODYWEIGHT) {
                                            weight = "bodyweight";
                                        } else if (inst.WeightType === UNITS.WEIGHT_INPUT.KG && metric === UNITS.WEIGHT_INPUT.KG) {
                                            weight = `${inst.Weight} kg`;
                                        } else if (inst.WeightType === UNITS.WEIGHT_INPUT.KG && metric === UNITS.WEIGHT_INPUT.LB) {
                                            weight = `${Math.round(inst.Weight/UNITS.LB_TO_KG)} lb`
                                        } else if (inst.WeightType === UNITS.WEIGHT_INPUT.LB && metric === UNITS.WEIGHT_INPUT.LB) {
                                            weight = `${inst.Weight} lb`;
                                        } else if (inst.WeightType === UNITS.WEIGHT_INPUT.LB && metric === UNITS.WEIGHT_INPUT.KG) {
                                            weight = `${Math.round(inst.Weight*UNITS.LB_TO_KG)} kg`;
                                        } else if (inst.WeightType === UNITS.WEIGHT_INPUT.PERCENTAGE) {
                                            let maxRep = this.state.Inputs?.[this.state.SupersetExercise?.ExerciseID] || 0;
                                            if (maxRep === 0) {
                                                weight = `${inst.Weight}% of 1 rep max`
                                            } else {
                                                weight = `${Math.round(maxRep*(inst.Weight/100))} ${metric}`
                                            }
                                        } else {
                                            weight = inst.Weight;
                                        }

                                        const WeekIndex = this.props.match.params.WeekIndex;
                                        const DayIndex = this.props.match.params.DayIndex;
                                        const WorkoutIndex = this.props.match.params.WorkoutIndex;
                                        const ExerciseIndex = this.props.match.params.ExerciseIndex;
                                        const SupersetIndex = this.props.match.params.SupersetIndex;
                                        const setKey = generateWorkoutProgressKey(WeekIndex, DayIndex, WorkoutIndex, ExerciseIndex, SupersetIndex, index, WORKOUT_PROGRESS.COMPLETED);
                                        const completed = this.state.UserProgram?.WorkoutProgress?.[setKey] || false;

                                        return (
                                            <Col xs={24}>
                                                <Row gutter={[5,5]}>
                                                <Col xs={12} sm={8} md={6}>
                                                <h3 className="Customer-Fitness-Section-Title">Set {index+1}</h3>
                                                <h3 className="Customer-Fitness-Section-Title">{target}, {weight}</h3>
                                                <p className="ProgramOverview-Input-Subtext">{inst.ExtraNotes}</p>
                                                </Col>
                                                <Col xs={12} sm={8} md={6}>
                                                    {
                                                        completed ?
                                                        <Button
                                                        icon={<CheckCircleFilled />}
                                                        className="ProgramOverview-Button-Complete"
                                                        block type="primary"
                                                        onClick={() => this.saveProgramInput(index, false)}
                                                        >Uncheck Set</Button>
                                                        :
                                                        <Button block type="primary" className="ProgramOverview-Calendar-Workout-Button"
                                                        onClick={() => this.saveProgramInput(index, true)}
                                                        >Complete Set</Button>
                                                    }
                                                </Col>
                                                <Col xs={24}>
                                                <Divider />
                                                </Col>
                                                </Row>
                                            </Col>
                                        )
                                    })
                                }
                                <Col xs={24}>
                                    <h3 className="Customer-Fitness-Section-Title">Notes</h3>
                                    <Input.TextArea autoSize={{ minRows: 4 }} value={this.state.Notes} onChange={(e) => this.handleChange("Notes", e.target.value)} />
                                    <p className="ProgramOverview-Input-Subtext">Add notes specific to this exercise.</p>
                                </Col>
                                <Col xs={24} sm={12} md={6}>
                                    <Button type="primary" block
                                    style={{
                                        backgroundColor: data.websiteTemplate?.ColourPalette?.DARK,
                                        borderColor: data.websiteTemplate?.ColourPalette?.DARK
                                    }}
                                    className="ProgramOverview-Button"
                                    onClick={() => this.saveProgramInput()}
                                    >
                                        Save Notes
                                    </Button>
                                </Col>
                                <Col xs={24} sm={12} md={6}>
                                    {
                                        this.state.Completed ?
                                        <Button type="primary" block
                                        icon={<CheckCircleFilled />}
                                        className="ProgramOverview-Button-Complete"
                                        onClick={() => this.completeExercise()}
                                        >
                                            Uncheck Exercise
                                        </Button>
                                        :
                                        <Button type="primary" block
                                        style={{
                                            backgroundColor: data.websiteTemplate?.ColourPalette?.DARK,
                                            borderColor: data.websiteTemplate?.ColourPalette?.DARK
                                        }}
                                        className="ProgramOverview-Button"
                                        onClick={() => this.completeExercise()}
                                        >
                                            Complete Exercise
                                        </Button>
                                    }
                                </Col>
                            </Row>
                            </Col>
                        </Row>
                    }
                    <Modal
                    width={500}
                    footer={null}
                    onCancel={() => {
                        this.handleChange("VideoVisible", false)
                    }}
                    visible={this.state.VideoVisible}
                    closable
                    >
                        <Row justify="center">
                            <Col xs={23} style={{ minHeight: "70vh" }}>
                            <iframe
                            src={this.state.Exercises?.[this.state.SupersetExercise?.ExerciseID]?.VideoLink} title={this.state.Exercises?.[this.state.SupersetExercise?.ExerciseID]?.Title}
                            width="100%" height="100%" frameborder="0"
                            allow="controls"
                            allowfullscreen>
                            </iframe>
                            </Col>
                            <Col xs={23}>
                                {
                                    String(this.state.Exercises?.[this.state.SupersetExercise?.ExerciseID]?.VideoLink).includes("vimeo") || String(this.state.Exercises?.[this.state.SupersetExercise?.ExerciseID]?.VideoLink).includes("youtube") ?
                                    <div>
                                        <p>If you cannot view the video here, please try the link below:</p>
                                        <a href={this.state.Exercises?.[this.state.SupersetExercise?.ExerciseID]?.VideoLink} target="_blank" rel="noreferrer">Exercise Demonstration</a>
                                    </div>
                                    :
                                    null
                                }
                            </Col>
                        </Row>
                    </Modal>
                </div>
            )
        }
        }
    </DataContext.Consumer>
    )
    }
}

export default ProgramExercise;