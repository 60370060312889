import { ADD_LIVE_CLASS,
    ADD_VIDEO,
    REMOVE_VIDEO,
    REMOVE_LIVE_CLASS,
    ADD_BOOTCAMP,
    REMOVE_BOOTCAMP,
    ADD_PROGRAM,
    REMOVE_PROGRAM, } from './actionTypes';

// Actions
const addLiveClass = (liveClass, session) => {
    return {
        type: ADD_LIVE_CLASS,
        payload: {
            event: liveClass,
            session: session,
        },
    }
}

const addOnDemandVid = onDemandVid => {
    return {
        type: ADD_VIDEO,
        payload: onDemandVid,
    }
}

const addProgram = program => {
    return {
        type: ADD_PROGRAM,
        payload: program,
    }
}

const removeProgram = id => {
    return {
        type: REMOVE_PROGRAM,
        payload: id,
    }
}

const removeOnDemandVid = id => {
    return {
        type: REMOVE_VIDEO,
        payload: id,
    }
}

const removeLiveClass = id => {
    return {
        type: REMOVE_LIVE_CLASS,
        payload: id,
    }
}

const addBootcamp = bootcamp => {
    return {
        type: ADD_BOOTCAMP,
        payload: bootcamp
    }
}

const removeBootcamp = id => {
    return {
        type: REMOVE_BOOTCAMP,
        payload: id
    }
}

export {
    addLiveClass,
    addOnDemandVid,
    removeOnDemandVid,
    removeLiveClass,
    addBootcamp,
    removeBootcamp,
    addProgram,
    removeProgram,
}