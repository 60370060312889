import React from 'react';
import './typography.css';

class P extends React.Component {
    render() {
        let style = {};
        if (this.props.style) {
            style = {...this.props.style};
        }
        return (
            <h3 style={style} className={`P ${this.props.className}`}>{this.props.text}</h3>
        )
    }
}

export default P;