import React from 'react';
import './typography.css';

class H2 extends React.Component {
    render() {
        return (
            this.props.style && this.props.spanStyle?
            <h3 style={this.props.style} className={`H2 ${this.props.className}`}>
                <span style={this.props.spanStyle}>{this.props.text}</span>
            </h3>
            :
            this.props.style ?
            <h3 style={this.props.style} className={`H2 ${this.props.className}`}>
                {this.props.text}
            </h3>
            :
            <h3 className={`H2 ${this.props.className}`}>{this.props.text}</h3>
        )
    }
}

export default H2;