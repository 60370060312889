import React from 'react';
import { DataContext } from '../../../Context/Data';
import { AuthContext } from '../../../Context/Auth';
import { WEBSITE_COMPONENTS } from '../../../constants';
import { Navbar1, Navbar2, Navbar3 } from '../index';
import { Authentication } from '../../index';

class Navbar extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            open: false,
        }
    }

    changeVisibility = () => {
        this.setState({
            visible: !this.state.visible
        })
    }

    handleSignOut = async () => {
        const { logout } = this.context;
        await logout();
    }

    changeModalVisibility = () => {
        this.setState({
            open: !this.state.open,
        });
    }

    render() {
        const { currentUser } = this.context;
        return (
            <DataContext.Consumer>
            {
                data => {
                    const websiteData = data.websiteTemplate;
                    const navbar = (
                        websiteData?.SelectedDesigns?.NAVBAR === WEBSITE_COMPONENTS.NAVBARS.NAVBAR1 ?
                        <Navbar1
                            LogoURL = {websiteData?.LogoURL}
                            BusinessName = {websiteData?.BusinessName}
                            ExistingWebsite = {websiteData?.ExistingWebsite}
                            ExistingWebsiteURL = {websiteData?.ExistingWebsiteURL}
                            SocialMedia = {websiteData?.SocialMedia}
                            Profile = {data.profile}
                            currentUser = {currentUser}
                            handleSignIn = {this.changeModalVisibility}
                            handleSignOut = {this.handleSignOut}
                        />
                        :
                        websiteData?.SelectedDesigns?.NAVBAR === WEBSITE_COMPONENTS.NAVBARS.NAVBAR2 ?
                        <Navbar2
                            LogoURL = {websiteData?.LogoURL}
                            BusinessName = {websiteData?.BusinessName}
                            ExistingWebsite = {websiteData?.ExistingWebsite}
                            ExistingWebsiteURL = {websiteData?.ExistingWebsiteURL}
                            SocialMedia = {websiteData?.SocialMedia}
                            Profile = {data.profile}
                            currentUser = {currentUser}
                            handleSignIn = {this.changeModalVisibility}
                            handleSignOut = {this.handleSignOut}
                        />
                        :
                        websiteData?.SelectedDesigns?.NAVBAR === WEBSITE_COMPONENTS.NAVBARS.NAVBAR3 ?
                        <Navbar3
                            LogoURL = {websiteData?.LogoURL}
                            BusinessName = {websiteData?.BusinessName}
                            ExistingWebsite = {websiteData?.ExistingWebsite}
                            ExistingWebsiteURL = {websiteData?.ExistingWebsiteURL}
                            SocialMedia = {websiteData?.SocialMedia}
                            Profile = {data.profile}
                            currentUser = {currentUser}
                            handleSignIn = {this.changeModalVisibility}
                            handleSignOut = {this.handleSignOut}
                        />
                        :
                        null
                    )
                    return (
                        <div>
                            <Authentication open={this.state.open} handleModalChange={this.changeModalVisibility} />
                            {navbar}
                        </div>
                    )
                }
            }
            </DataContext.Consumer>
        )
    }
}

export default Navbar;