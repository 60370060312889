import React from 'react';
import './LiveClassHeader.css';
import { Row, Col } from 'antd';
import LiveClassHeaderSVG from './LiveClassHeaderSVG';
import { renderToStaticMarkup } from 'react-dom/server';
import { COLOUR_PALETTE, TRAINING_EXPERIENCE_LEVEL } from '../../constants';
import { H1, H2, H3, P } from '../../Common';

class LiveClassHeader extends React.Component {
    render() {
        const svgString = encodeURIComponent(
            renderToStaticMarkup(
                <LiveClassHeaderSVG
                fill={COLOUR_PALETTE[this.props.profile.ColourScheme].LIGHT}
                />
            )
        );
        return (
            <Row justify='center'>
                <Col xs={23} sm={22}
                style={{
                    backgroundImage: `url("data:image/svg+xml;utf8,${svgString}")`,
                    backgroundRepeat: 'no-repeat',
                }}
                className='ClassesHeader-Background'
                >
                    <Row justify='center' align='middle'>
                        <Col xs={24} sm={7} style={{ textAlign: 'center' }}>
                            <img src={this.props.keyEvents[this.props.ID].PictureURL} alt={`${this.props.profile.BusinessName}`} className='ClassesHeader-Image' />
                        </Col>
                        <Col xs={24} sm={14}>
                            <Row gutter={[20,20]} align='middle' style={{ marginTop: '10px' }}>
                                <Col>
                                    <H1 text={`${this.props.keyEvents[this.props.ID].Name},`} />
                                </Col>
                                <Col>
                                    <H2 text={`${this.props.profile.FirstName} ${this.props.profile.LastName}`} />
                                </Col>
                            </Row>
                            <Row gutter={[20,20]} align='middle' style={{ marginTop: '10px' }}>
                                <Col>
                                    <H3 text={`Price: $${this.props.keyEvents[this.props.ID].Price} ${this.props.keyEvents[this.props.ID].Currency}`} />
                                </Col>
                                <Col>
                                    <H3 text={`Intensity Level: ${this.props.keyEvents[this.props.ID].IntensityRating}/5`} />
                                </Col>
                                <Col>
                                    <H3 text={`Level: ${TRAINING_EXPERIENCE_LEVEL[this.props.keyEvents[this.props.ID].Level]}`} />
                                </Col>
                            </Row>
                            <Row align='middle' style={{ marginTop: '10px' }}>
                                <Col xs={24}>
                                    <P text={this.props.keyEvents[this.props.ID].Description} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default LiveClassHeader;