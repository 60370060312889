import React from 'react';
import { Row, Col, Modal, Input, Button, message, notification } from 'antd';
import { H2 } from '../../Common';
import './Authentication.css';
import { AuthContext } from '../../Context/Auth';
import axios from 'axios';
import { ENVIRONMENT, UID } from '../../constants';

class Authentication extends React.Component {
    static contextType = AuthContext;
    state = {
        email: '',
        password: '',
        login: true,
        confirmPassword: '',
        name: '',
        phoneNumber: '',
        passwordReset: false,
    }
    handleChange = (name, value) => {
        this.setState({
            [name]: value,
        })
    }

    handleSignIn = async () => {
        const { login } = this.context;
        try {
            const user = await login(this.state.email, this.state.password);
            if (user) {
                this.resetFields();
                this.props.handleModalChange();
            }
        } catch (e) {
            if(e.code === "auth/wrong-password") {
                notification.error({
                    message: "Wrong Password",
                    description: "Wrong password, please try again."
                });
            } else if (e.code === "auth/network-request-failed") {
                notification.error({
                    message: "Request Failed",
                    description: "Your internet connection was lost, please refresh the page and try again."
                });
            } else if (e.code === "auth/too-many-requests") {
                notification.error({
                    message: "Too many requests.",
                    description: "Sorry, your login attempts have failed multiple times. Please come back and try again at a later time or contact our tech team at support@phros.ca."
                })
            } else if (e.code === "auth/user-disabled") {
                notification.error({
                    message: "User disabled",
                    description: "Sorry, your account has been temporarily disabled. Please contact our team at support@phros.ca if you think this is an error."
                })
            } else {
                notification.error({
                    message: "Error",
                    description: "Something went wrong, please contact our team at support@phros.ca to get support."
                })
            }
        }

    }

    handlePasswordReset = async () => {
        const payload = {
            Email: this.state.email,
            TrainerUID: UID,
        }
        const resetPass = await axios.post(`${ENVIRONMENT.BACKEND_URL}/auth/reset-password`, payload);
        if (resetPass.data.error) {
            return notification.error({
                message: "Error",
                description: "There was an error when sending your password reset email, please contact our tech team at support@phros.ca."
            })
        } else {
            return notification.success({
                message: "Success",
                description: `Please check your email, ${this.state.email}, for the Password Reset link (make sure to check your junk/spam folder).`
            })
        }
    }

    addUserProfile = async (user, Name, Email, PhoneNumber, UID, TrainerUID) => {
        const token = await user.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const payload = {
            Name: Name,
            Email: Email,
            PhoneNumber: PhoneNumber,
            UID: UID,
            TrainerUID: TrainerUID
        }
        axios.post(`${ENVIRONMENT.BACKEND_URL}/user-profile/save-user-profile`, payload, config);
    }

    resetFields = () => {
        this.setState({
            email: '',
            password: '',
            login: true,
            confirmPassword: '',
            name: '',
            phoneNumber: '',
        })
    }

    handleSignUp = async () => {
        if (this.state.password !== this.state.confirmPassword) {
            return message.error('Passwords do not match.');
        }
        const { signup } = this.context;
        const user = await signup(this.state.email, this.state.password);
        if (user) {
            this.addUserProfile(user.user, this.state.name, this.state.email, this.state.phoneNumber, user.uid, UID);
            this.resetFields();
            this.props.handleModalChange();
        }
    }

    handleGoogleAuth = async () => {
        const { signInWithGoogle } = this.context;
        const user = await signInWithGoogle();
        if (user) {
            this.addUserProfile(user.user,
                user.user.displayName,
                user.user.email,
                user.user.phoneNumber,
                user.uid,
                UID);
            this.resetFields();
            this.props.handleModalChange();
        }
    }

    handleSignUpModalChange = () => {
        this.setState({
            login: !this.state.login,
        })
    }

    render() {
        return (
            <Modal footer={null} width={520} visible={this.props.open} onCancel={this.props.handleModalChange}>
            <Row justify='center'>
            <Col xs={23}>
                {
                    this.state.passwordReset ?
                    <div>
                        <H2 text="Reset Password" />
                        <Row>
                            <Col xs={24}>
                                <Input placeholder="Enter Email" value={this.state.email} onChange={(e) => this.handleChange("email", e.target.value)} />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <Col xs={24}><Button block type="primary" onClick={() => this.handlePasswordReset()}>Get Password Reset Link</Button></Col>
                        </Row>
                        <Row>
                        <Col xs={24}>Return to <Button onClick={() => this.handleChange("passwordReset", false)} type='link'>Login</Button></Col>
                        </Row>
                    </div>
                    :
                    <div>

                {
                    this.state.login?
                    <H2 text='Login' />
                    :
                    <H2 text='Sign Up' />
                }

                <Row gutter={[10,10]} justify='center'>
                    {
                        this.state.login ?
                        null
                        :
                        <Col xs={24}>
                            Name:
                            <Input value={this.state.name} onChange={(e) => this.handleChange('name', e.target.value)} />
                        </Col>
                    }
                    {
                        this.state.login ?
                        null
                        :
                        <Col xs={24}>
                            Phone Number:
                            <Input value={this.state.phoneNumber} onChange={(e) => this.handleChange('phoneNumber', e.target.value)} />
                        </Col>
                    }
                    <Col xs={24}>
                        Email:
                        <Input value={this.state.email} onChange={(e) => this.handleChange('email', e.target.value)}/>
                    </Col>
                    <Col xs={24}>
                        Password:
                        <Input value={this.state.password} type='password' onChange={(e) => this.handleChange('password', e.target.value)} />
                    </Col>
                    <Col xs={24}>
                    {
                        this.state.login ?
                        <Row gutter={[10,10]}>
                            <Col xs={24}>
                                <Button onClick={this.handleSignIn} block type="primary">Login</Button>
                            </Col>
                            <Col xs={24}>Forgot your password? <Button onClick={() => this.handleChange("passwordReset", true)} type="link">Reset Password</Button></Col>
                            <Col xs={24}>Don't have an account? <Button onClick={this.handleSignUpModalChange} type='link'>Sign Up</Button></Col>
                        </Row>
                        :
                        <Row gutter={[10,10]}>
                            <Col xs={24}>
                            Confirm Password:
                                <Input value={this.state.confirmPassword} type='password' onChange={(e) => this.handleChange('confirmPassword', e.target.value)} />
                            </Col>
                            <Col xs={24}>
                                <Button onClick={this.handleSignUp} block type="primary">Sign Up</Button>
                            </Col>
                            <Col xs={24}>Already have an account? <Button onClick={this.handleSignUpModalChange} type='link'>Login</Button></Col>
                        </Row>
                    }
                    </Col>

                    <Col xs={24}>
                        <Button onClick={this.handleGoogleAuth} block size='large'>
                        <img className='Auth-Google-Image' src="https://img.icons8.com/color/48/000000/google-logo.png" alt="google icon"/>
                        <span> Continue with Google</span>
                        </Button>
                    </Col>
                </Row>
                </div>
                }
            </Col>
            </Row>
            </Modal>
        )
    }
}

export default Authentication;