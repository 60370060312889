import React from 'react';
import './FAQ.css';
import { Row, Col, Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { H2 } from '../../Common';
import { COLOUR_PALETTE } from '../../constants';

class FAQ extends React.Component {
    render () {
        return (
            <div>
            <Row justify='center'>
            <Col xs={23} sm={22}>
                <Row>
                    <Col>
                    <H2 style={{ background: `linear-gradient(to top, ${COLOUR_PALETTE[this.props.profile.ColourScheme].MEDIUM} 30%, transparent 30%)` }} text="FAQ"/>
                    </Col>
                </Row>
                <Collapse
                className="FAQ-Collapse"
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
                    {
                        this.props.profile?.FAQ?.map((faq, index) => {
                            return (
                                <Collapse.Panel className="FAQ-Collapse-Custom" header={faq.Question} key={String(index)}>
                                    <p className="FAQ-Collapse-Custom-Text">{faq.Answer}</p>
                                </Collapse.Panel>
                            )
                        })
                    }
                </Collapse>
            </Col>
            </Row>
            </div>
        )
    }
}

export default FAQ;