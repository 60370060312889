import React from 'react';
import './Landing.css';
import { Navbar, Contact, Banner } from '../../Components';
import { Helmet } from 'react-helmet';
import { DataContext } from '../../Context/Data';
import {
  AboutMe1, AboutMe2, AboutMe3,
  Gallery1, Gallery2,
  Service1, Service2, Service3,
  Testimonial1, Testimonial2, Testimonial3,
  FAQ1, FAQ2,
} from '../../Components/LandingPage';
import { WEBSITE_SECTIONS, WEBSITE_COMPONENTS } from '../../constants';
import { checkArray } from '../../ValidateInput';
import { Row, Col } from 'antd';

class Landing extends React.Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }
  render() {
    return (
      <DataContext.Consumer>
        {
          data => {
            const websiteData = data.websiteTemplate;
        const aboutme = (
          websiteData?.SelectedDesigns?.ABOUTME === WEBSITE_COMPONENTS.ABOUTME.ABOUTME1 ?
            <AboutMe1
                FirstName = {websiteData?.FirstName}
                LastName = {websiteData?.LastName}
                ProfessionalTitle = {websiteData?.ProfessionalTitle}
                AboutMe = {websiteData?.AboutMe}
                AboutMePictureURL = {websiteData?.AboutMePictureURL}
            />
            :
            websiteData?.SelectedDesigns?.ABOUTME === WEBSITE_COMPONENTS.ABOUTME.ABOUTME2 ?
            <AboutMe2
                FirstName = {websiteData?.FirstName}
                LastName = {websiteData?.LastName}
                ProfessionalTitle = {websiteData?.ProfessionalTitle}
                AboutMe = {websiteData?.AboutMe}
                AboutMePictureURL = {websiteData?.AboutMePictureURL}
            />
            :
            websiteData?.SelectedDesigns?.ABOUTME === WEBSITE_COMPONENTS.ABOUTME.ABOUTME3 ?
            <AboutMe3
                FirstName = {websiteData?.FirstName}
                LastName = {websiteData?.LastName}
                ProfessionalTitle = {websiteData?.ProfessionalTitle}
                AboutMe = {websiteData?.AboutMe}
                AboutMePictureURL = {websiteData?.AboutMePictureURL}
            />
            :
            null
        )

        const faq = (
          websiteData?.SelectedDesigns?.FAQ === WEBSITE_COMPONENTS.FAQ.FAQ1 ?
          <FAQ1
              FAQ = {websiteData?.FAQ}
          />
          :
          websiteData?.SelectedDesigns?.FAQ === WEBSITE_COMPONENTS.FAQ.FAQ2 ?
          <FAQ2
              FAQ = {websiteData?.FAQ}
          />
          :
          null
      )

      const services = (
          websiteData?.SelectedDesigns?.SERVICE === WEBSITE_COMPONENTS.SERVICE.SERVICE1 ?
          <Service1
              Services = {websiteData?.Services}
          />
          :
          websiteData?.SelectedDesigns?.SERVICE === WEBSITE_COMPONENTS.SERVICE.SERVICE2 ?
          <Service2
              Services = {websiteData?.Services}
          />
          :
          websiteData?.SelectedDesigns?.SERVICE === WEBSITE_COMPONENTS.SERVICE.SERVICE3 ?
          <Service3
              Services = {websiteData?.Services}
          />
          :
          null
      )

      const gallery = (
          websiteData?.SelectedDesigns?.GALLERY === WEBSITE_COMPONENTS.GALLERY.GALLERY1 ?
          <Gallery1
              Gallery = {websiteData?.Gallery}
          />
          :
          websiteData?.SelectedDesigns?.GALLERY === WEBSITE_COMPONENTS.GALLERY.GALLERY2 ?
          <Gallery2
              Gallery = {websiteData?.Gallery}
          />
          :
          null
      )

      const testimonials = (
          websiteData?.SelectedDesigns?.TESTIMONIAL === WEBSITE_COMPONENTS.TESTIMONIALS.TESTIMONIAL1 ?
          <Testimonial1
              Testimonials = {websiteData?.Testimonials}
          />
          :
          websiteData?.SelectedDesigns?.TESTIMONIAL === WEBSITE_COMPONENTS.TESTIMONIALS.TESTIMONIAL2 ?
          <Testimonial2
              Testimonials = {websiteData?.Testimonials}
          />
          :
          websiteData?.SelectedDesigns?.TESTIMONIAL === WEBSITE_COMPONENTS.TESTIMONIALS.TESTIMONIAL3 ?
          <Testimonial3
              Testimonials = {websiteData?.Testimonials}
          />
          :
          null
      )

            return (
              <div>
              <Helmet>
                <title>{data.profile.BusinessName ? data.profile.BusinessName : 'My Personal Training Website'}</title>
                <meta name="description" content={data.profile.BusinessStatement} />
                <link rel="icon" type="image/png" href={data.profile.LogoURL} />
              </Helmet>
              <Row justify="center" style={{ paddingBottom: "30px" }}>
                  <Col xs={24}>
                  <Navbar />
                  <Banner />
                  </Col>
                  <Col xs={23} className="PreviewWebsite-Max-Col">
                  {
                      checkArray(websiteData?.Order).map((key, index) => {
                          const section = WEBSITE_SECTIONS[key].VALUE;
                          if (section === WEBSITE_SECTIONS.FAQ.VALUE && checkArray(websiteData.FAQ).length > 0) {
                              return (
                              <div
                              className={`PreviewWebsite-Div${index===0 ? "-First" : index === checkArray(websiteData?.Order).length-1 ? "-Last" : "" }`}
                              >
                                  {faq}
                              </div>)
                          }
                          if (section === WEBSITE_SECTIONS.ABOUTME.VALUE && String(websiteData.AboutMe).length > 0) {
                              return (<div className={`PreviewWebsite-Div${index===0 ? "-First" : index === checkArray(websiteData?.Order).length-1 ? "-Last" : "" }`}>{aboutme}</div>)
                          }
                          if (section === WEBSITE_SECTIONS.CONTACT.VALUE) {
                              return (<div id="contact-me" className={`PreviewWebsite-Div${index===0 ? "-First" : index === checkArray(websiteData?.Order).length-1 ? "-Last" : "" }`}><Contact /></div>)
                          }
                          if (section === WEBSITE_SECTIONS.SERVICES.VALUE) {
                              return (<div className={`PreviewWebsite-Div${index===0 ? "-First" : index === checkArray(websiteData?.Order).length-1 ? "-Last" : "" }`}>{services}</div>)
                          }
                          if (section === WEBSITE_SECTIONS.GALLERY.VALUE) {
                              return (<div className={`PreviewWebsite-Div${index===0 ? "-First" : index === checkArray(websiteData?.Order).length-1 ? "-Last" : "" }`}>{gallery}</div>)
                          }
                          if (section === WEBSITE_SECTIONS.TESTIMONIALS.VALUE) {
                              return (<div className={`PreviewWebsite-Div${index===0 ? "-First" : index === checkArray(websiteData?.Order).length-1 ? "-Last" : "" }`}>{testimonials}</div>)
                          }
                          return null;
                      })
                  }
                  </Col>
              </Row>
            </div>
            )
          }
        }
      </DataContext.Consumer>
    )
  }
}

export default Landing;