import React from 'react';
import './LiveClassList.css';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { H2, H3, P, PhrosButton } from '../../Common';
import { TRAINING_EXPERIENCE_LEVEL } from '../../constants';
import { DataContext } from '../../Context/Data';

class LiveClass extends React.Component {
    render() {
        return (
            <Row justify='center' align='middle' style={{ marginTop: '20px', marginBottom: '20px' }}>
                <Col xs={23} sm={24}>
                    <Link to={`/live-class/${this.props.event.ID}`}>
                        <Row className='LiveClass-Row' style={{ borderColor: this.props.ColourScheme.MEDIUM }} gutter={[10,10]}>
                            <Col xs={24} sm={6}>
                                <img src={this.props.event.PictureURL} alt={this.props.event.Name} className='LiveClass-Image' />
                            </Col>
                            <Col xs={24} sm={8}>
                                <Row justify='space-around'>
                                    <Col xs={12} sm={16}>
                                        <H2 text={this.props.event.Name} />
                                    </Col>
                                    <Col xs={12} sm={8}>
                                        <H3 text={`$${this.props.event.Price} ${this.props.event.Currency}`} />
                                    </Col>
                                </Row>
                                <Row justify='space-around'>
                                    <Col xs={24}>
                                        <P text={this.props.event.Description} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} sm={5}>
                                <Row align='middle' style={{ height: '100%' }}>
                                    <Col xs={24}>
                                        <P text={`Intensity Level: ${this.props.event.IntensityRating}/5`} />
                                        <P text={`Level: ${TRAINING_EXPERIENCE_LEVEL[this.props.event.Level]}`} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} sm={5}>
                                <Row align='middle' style={{ height: '100%' }}>
                                    <Col xs={24}>
                                    <PhrosButton link={`/live-class/${this.props.event.ID}`} text="Check Availability" styles={{ background: this.props.ColourScheme.DARK }} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Link>
                </Col>
            </Row>
        )
    }
}

class LiveClassList extends React.Component {
    render() {
        return (
            <DataContext.Consumer>
                {
                    data => {
                        return (
                            <Row justify='center'>
                                <Col xs={23} sm={22}>
                                    {
                                        this.props.events.map((event) => {
                                            return (
                                                <LiveClass event={event} ColourScheme={data.websiteTemplate?.ColourPalette} />
                                            )
                                        })
                                    }
                                </Col>
                            </Row>
                        )
                    }
                }
            </DataContext.Consumer>
        )
    }
}

export default LiveClassList;