import React from 'react';
import './LiveClassPage.css';
import { Navbar, LiveClassHeader, AvailabilityList } from '../../Components';
import { Helmet } from 'react-helmet';
import { DataContext } from '../../Context/Data';

class LiveClassPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }
    render() {
        return (
            <DataContext.Consumer>
            {
              data => (
                <div>
                <Helmet>
                  <title>{data.profile.BusinessName ? data.profile.BusinessName : 'My Personal Training Website'}</title>
                  <meta name="description" content={data.profile.BusinessStatement} />
                  <link rel="icon" type="image/png" href={data.profile.LogoURL} />
                </Helmet>
                <Navbar profile={data.profile} events={data.events} keyEvents={data.keyEvents} />
                <LiveClassHeader profile={data.profile} keyEvents={data.keyEvents} ID={this.props.match.params.ID}/>
                <div className='LiveClassPage-AvailabilityList'>
                <AvailabilityList profile={data.profile} keyEvents={data.keyEvents} ID={this.props.match.params.ID}/>
                </div>
              </div>
              )
            }
          </DataContext.Consumer>
        )
    }
}

export default LiveClassPage;