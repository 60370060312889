import React from 'react';
import './typography.css';

class H3 extends React.Component {
    render() {
        return (
            this.props.style ?
            <h3 style={this.props.style} className={`H3 Highlight ${this.props.className}`}>{this.props.text}</h3>
            :
            <h3 className={`H3 ${this.props.className}`}>{this.props.text}</h3>
        )
    }
}

export default H3;