import React from 'react';
import { message, notification } from 'antd';
import { DataContext } from '../../../Context/Data';
import axios from 'axios';
import { ENVIRONMENT } from '../../../constants';
import { WEBSITE_COMPONENTS } from '../../../constants';
import { Contact1, Contact2 } from '../index';

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            FirstName: "",
            LastName: "",
            Email: "",
            PhoneNumber: "",
            Message: "",
        }
        this.handleChange = this.handleChange.bind(this);
        this.submitContact = this.submitContact.bind(this);
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value,
        })
    }

    validateEmail = (email) => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
        return (true)
        }
        message.error("Please enter a valid email.")
        return (false)
    }

    validateTextInput = (text, name) => {
        if (String(text).length === 0) {
            message.error(`Please enter a ${name}.`)
            return false;
        } else {
            return true;
        }
    }

    submitContact = async (trainerEmail) => {
        const emailValidate = this.validateEmail(this.state.Email);
        if (!emailValidate) return;
        const nameValidate = this.validateTextInput(this.state.FirstName, "First Name");
        if (!nameValidate) return;
        const data = {
            name: `${this.state.FirstName} ${this.state.LastName}`,
            email: this.state.Email,
            phone: this.state.PhoneNumber,
            message: this.state.Message,
            TrainerEmail: trainerEmail,
        }
        const sendEmail = await axios.post(`${ENVIRONMENT.BACKEND_URL}/custom-website/contact`, data);
        if (sendEmail.data.error) {
            notification.error({
                message: "Something went wrong!",
                description: `Sorry we couldn't send you message, please send me an email at ${trainerEmail}`,
                duration: 0,
            })
        } else {
            notification.success({
                message: "Success",
                description: "Thanks for your message, I'll be in contact with you soon.",
            })
        }
    }


    render() {
        return (
            <DataContext.Consumer>
                {
                    data => {
                        const websiteData = data.websiteTemplate;
                        const contact = (
                            websiteData?.SelectedDesigns?.CONTACT === WEBSITE_COMPONENTS.CONTACTME.CONTACT1 ?
                            <Contact1
                            handleChange = {this.handleChange}
                            submitContact = {() => this.submitContact(websiteData.Email)}
                            />
                            :
                            websiteData?.SelectedDesigns?.CONTACT === WEBSITE_COMPONENTS.CONTACTME.CONTACT2 ?
                            <Contact2
                            handleChange = {this.handleChange}
                            submitContact = {() => this.submitContact(websiteData.Email)}
                            />
                            :
                            null
                        )
                        return contact;
                    }
        }
        </DataContext.Consumer>
        )
    }
}

export default Contact;