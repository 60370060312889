import React from 'react';
import { Navbar } from '../../Components';
import { Helmet } from 'react-helmet';
import { DataContext } from '../../Context/Data';
import { Row, Col } from 'antd';
import { H1, H3, P, PhrosButton } from '../../Common';

class FreeBookingSuccess extends React.Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }
    render() {
        return (
        <DataContext.Consumer>
            {
            data => (
                <div>
                <Helmet>
                <title>{data.profile.BusinessName ? data.profile.BusinessName : 'My Personal Training Website'}</title>
                <meta name="description" content={data.profile.BusinessStatement} />
                <link rel="icon" type="image/png" href={data.profile.LogoURL} />
                </Helmet>
                <Navbar profile={data.profile} events={data.events} />
                <Row justify='center' style={{ height: '60vh'}} align='middle'>
                    <Col xs={23} sm={15}>
                        <H1 text="Thanks for registering!" style={{ textAlign: 'center' }} />
                        <H3 text="Please look out for an email from no-reply@phros.ca for your booking confirmation (make sure to check your junk folder!)." />
                        <P text={`${data.profile.FirstName} ${data.profile.LastName}`} />
                        <Row justify='center'>
                            <Col xs={24} sm={10}>
                                <PhrosButton
                                styles={{ background: data.websiteTemplate?.ColourPalette?.DARK }}
                                text='view your purchase' link='/my-fitness' />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            )
            }
        </DataContext.Consumer>
        )
    }
}

export default FreeBookingSuccess;