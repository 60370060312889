import "./FAQ1.css";
import React from 'react';
import { Row, Col, Button } from 'antd';
import { checkArray } from '../../../../ValidateInput';
import { ArrowRightOutlined, ArrowDownOutlined } from '@ant-design/icons';

class FAQ1 extends React.Component {
    state = {
        FAQOPEN: {}
    }
    render() {
        return (
            <Row justify="center" style={{ backgroundColor: "white" }}>
            <Col xs={23}>
                <Row>
                    <Col xs={24}>
                        <h3 className="Section-Component-Title">Frequently Asked Questions</h3>
                    </Col>
                </Row>
                {
                    checkArray(this.props.FAQ).map((faq, index) => {
                        return (
                            <Row gutter={[20,20]} className="FAQ1-Row" justify="center">
                                <Col xs={24}>
                                    <Row
                                    justify="space-between"
                                    onClick={() => {
                                        let faqOpen = { ...this.state.FAQOPEN };
                                        let faqState = faqOpen[index] || false;
                                        faqOpen[index] = !faqState;
                                        this.setState({
                                            FAQOPEN: faqOpen,
                                        })
                                    }}
                                    className="FAQ1-Question-Container">
                                        <Col>
                                        <h2 className="FAQ1-Question">{faq.Question}</h2>
                                        </Col>
                                        {
                                            !this.state.FAQOPEN[index] ?
                                            <Col>
                                            <Button
                                                type="text"
                                                icon={<ArrowRightOutlined />}
                                            />
                                            </Col>
                                            :
                                            <Col>
                                            <Button
                                                type="text"
                                                icon={<ArrowDownOutlined />}
                                            />
                                            </Col>
                                        }
                                    </Row>
                                </Col>
                                {
                                    this.state.FAQOPEN[index] ?
                                    <Col xs={24} className="FAQ1-Answer">
                                        <div>
                                            {faq.Answer}
                                        </div>
                                    </Col>
                                    :
                                    null
                                }
                            </Row>
                        )
                    })
                }
            </Col>
            </Row>
        )
    }
}

export default FAQ1;