import React from 'react';
import './PurchasedCard.css';
import { H3, P } from '../../Common';
import { Button } from 'antd';
import { TRAINING_EXPERIENCE_LEVEL } from '../../constants';
import { Link } from 'react-router-dom';
import { DataContext } from '../../Context/Data';

class PurchasedCard extends React.Component {
    render() {
        return (
            <DataContext.Consumer>
                {
                    data => {
                        return (
                            <div className='Video-Card'>
                            {
                                String(this.props.ImageURL).length > 0 ?
                                <img src={this.props.ImageURL} alt={this.props.Title} className="Video-Card-Image" />
                                :
                                null
                            }
                            <div className='Video-Card-Container'>
                            <H3 text={`${this.props.Title}`} />
                            <P text={this.props.Description} />
                            {
                                this.props.IntensityRating && this.props.Level ?
                                <h3>Intensity: {this.props.IntensityRating}/5  - {TRAINING_EXPERIENCE_LEVEL[this.props.Level]}</h3>
                                :
                                null
                            }
                            {
                                this.props.OtherText ?
                                <h3>{this.props.OtherText}</h3>
                                :
                                null
                            }
                            <div className='Video-Card-Button'>
                                {
                                    this.props.Link ?
                                    <Link to={this.props.Link}>
                                        <Button
                                        type="primary"
                                        styles={{
                                            borderRadius: '20px',
                                            background: data.websiteTemplate?.ColourPalette?.DARK }}
                                        >
                                            {this.props.ButtonText}
                                        </Button>
                                    </Link>
                                    : this.props.Anchor ?
                                    <a href={this.props.Anchor} target="_blank" rel="noreferrer">
                                        <Button
                                        type="primary"
                                        styles={{
                                            borderRadius: '20px',
                                            background: data.websiteTemplate?.ColourPalette?.DARK }}
                                        >
                                            {this.props.ButtonText}
                                        </Button>
                                    </a>
                                    : this.props.onClick ?
                                    <Button
                                    block
                                    onClick={this.props.onClick}
                                    type="primary"
                                    styles={{
                                        borderRadius: '20px',
                                        background: data.websiteTemplate?.ColourPalette?.DARK }}
                                    >
                                        {this.props.ButtonText}
                                    </Button>
                                    :
                                    null
                                }
                            </div>
                            <div className='Video-Card-Button'>
                                {
                                    this.props.onClick2 ?
                                    <Button
                                    block
                                    onClick={this.props.onClick2}
                                    danger
                                    styles={{
                                        borderRadius: '20px',
                                        background: data.websiteTemplate?.ColourPalette?.DARK }}
                                    >
                                        {this.props.ButtonText2}
                                    </Button>
                                    :
                                    null
                                }
                            </div>
                            </div>
                        </div>
                        )
                    }
                }
            </DataContext.Consumer>
        )
    }
}

export default PurchasedCard;