import React from 'react';
import './Logo.css';

class Logo extends React.Component {
    render() {
        return (
            <div>
                {
                    this.props.SRC ?
                    <img src={this.props.SRC} alt='PHROS' className='Logo-Image' />
                    :
                    null
                }
            </div>
        )
    }
}
export default Logo;