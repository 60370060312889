import React from 'react';
import { Row, Col, Input } from 'antd';
import PropTypes from 'prop-types';
import './Banner3.css';

/*
Required Props
BannerURL
ProfessionalTitle
FirstName
LastName
BusinessStatement
*/
class Banner3 extends React.Component {
    render() {
        // const bannerImageURl = "https://firebasestorage.googleapis.com/v0/b/phros-dev-ddfdb.appspot.com/o/pt-assets%2Fphros%2Fpexels-rfstudio-3820364.jpg?alt=media&token=aa812eaf-b52f-450f-8d84-116d3f0e4ae9";
        const bannerImageURl = this.props.BannerImageURL;
        return (
            <Row justify="center">
            <Col xs={23} className="Banner3-Main-Col">
                <Row justify="center" align="middle" gutter={[10,10]}>
                    <Col xs={24} sm={12}>
                    <h2 className="Banner3-Name">{this.props.FirstName} {this.props.LastName}</h2>
                    <h3 className="Banner3-BusinessStatement">{this.props.BusinessStatement}</h3>
                    <Row gutter={[10,10]}>
                        <Col xs={24} sm={14}>
                        <Input onChange={(e) => this.props.handleChange("Email", e.target.value)} placeholder="Enter E-mail" className="Banner3-Input" />
                        </Col>
                        <Col xs={24} sm={10}>
                            <button
                            onClick={this.props.submitContact}
                            className="Banner3-Contact-Button"
                            >
                                Contact Me
                            </button>
                        </Col>
                    </Row>
                    </Col>
                    <Col xs={24} sm={12}>
                    <img className="Banner3-Img" src={bannerImageURl} alt={this.props.ProfessionalTitle} />
                    </Col>
                </Row>
            </Col>
            </Row>
        )
    }
}

Banner3.propTypes = {
    BannerImageURL: PropTypes.string.isRequired,
    ProfessionalTitle: PropTypes.string.isRequired,
    FirstName: PropTypes.string.isRequired,
    LastName: PropTypes.string.isRequired,
    BusinessStatement: PropTypes.string.isRequired,
}

export default Banner3;