import React from 'react';
import './ProgramOverview.css';
import { AuthContext } from '../../../Context/Auth';
import { DataContext } from '../../../Context/Data';
import { ENVIRONMENT, UNITS, WORKOUT_PROGRESS, UID } from '../../../constants';
import { SimpleTextCard, Navbar } from '../../../Components';
import { Popconfirm, Button, Calendar, notification, Spin, Row, Col, DatePicker, Radio, InputNumber } from 'antd';
import axios from 'axios';
import { checkArray, checkObj } from '../../../ValidateInput';
import moment from 'moment';
import { CheckCircleFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { generateWorkoutProgressKey } from '../../../util';

class ProgramOverview extends React.Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.state = {
            UserProgram: {},
            Exercises: {},
            Loading: true,
            ExerciseInputs: {},
            Inputs: {},
        }
    }

    componentDidMount() {
        this.getData();
    }

    saveProgramInputs = async () => {
        const { currentUser } = this.context;;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const payload = {
            ProgramID: this.props.match.params.ID,
            ID: this.state.UserProgram?.UserProgramID,
            UID: UID,
            Inputs: this.state.Inputs,
        }
        const response = await axios.post(`${ENVIRONMENT.BACKEND_URL}/user-programs/save-program-inputs`, payload, config);
        if (response.data.error) {
            return notification.error({
                message: "Error",
                description: `Failed to save the program inputs (${response.data.error}). If this persists please contact us at support@phros.ca.`
            })
        } else {
            return notification.success({
                message: "Success",
                description: "Successfully saved the program inputs."
            })
        }
    }

    resetProgress = async () => {
        const { currentUser } = this.context;;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const payload = {
            ProgramID: this.props.match.params.ID,
            ID: this.state.UserProgram?.UserProgramID,
            UID: UID,
        }
        const response = await axios.post(`${ENVIRONMENT.BACKEND_URL}/user-programs/reset-progress`, payload, config);
        if (response.data.error) {
            return notification.error({
                message: "Error",
                description: `Failed to reset the program (${response.data.error}). If this persists please contact us at support@phros.ca.`
            })
        } else {
            return notification.success({
                message: "Success",
                description: "Successfully reset all program inputs and progress."
            })
        }
    }

    // Get Exercise Data (Workout Program and Exercise)
    getData = async () => {
        const { currentUser } = this.context;;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const payload = {
            ID: this.props.match.params.ID,
            UID: UID,
        }
        const response = await axios.post(`${ENVIRONMENT.BACKEND_URL}/user-programs/get-user-program-with-id`, payload, config);
        if (response.data.error) {
            return notification.error ({
                message: "Error",
                description: `Failed to get the program data (${response.data.error})`,
                duration: null,
            })
        } else {
            const inputs = response.data.Program[this.props.match.params.ID]?.Inputs || {
                StartDate: moment(new Date()),
                Metric: UNITS.WEIGHT_INPUT.LB,
            };
            this.setState({
                UserProgram: response.data.Program[this.props.match.params.ID],
                Loading: false,
                Inputs: inputs,
            })
        }

        // Loop through Entire Program, Get Inputs needed (those with %)
        const exerciseInputs = {};
        const program = checkArray(response.data.Program[this.props.match.params.ID].Program.Program);
        for (let i = 0; i<program.length; i++) {
            const weeks = checkArray(program[i].week);
            for (let j=0; j<weeks.length; j++) {
                const day = checkArray(weeks[j].day);
                for (let k=0; k<day.length; k++) {
                    const exercises = checkArray(day[k].WorkoutExercises);
                    for (let m=0; m<exercises.length; m++) {
                        const exercise = checkArray(exercises[m].exercise);
                        for (let n=0; n<exercise.length; n++) {
                            const id = exercise[n]?.ExerciseID || null;
                            const instructions = checkArray(exercise[n].Instructions);
                            for (let p=0; p<=instructions.length; p++) {
                                const inst = checkObj(instructions[p]);
                                const weightType = inst.WeightType || null;
                                if (weightType === UNITS.WEIGHT_INPUT.PERCENTAGE) {
                                    exerciseInputs[id] = true;
                                }
                            }
                        }
                    }
                }
            }
        }
        const exercises = {
            ...response.data.UserExercises,
            ...response.data.LibraryExercises,
        }
        this.setState({
            Exercises: exercises,
            ExerciseInputs: exerciseInputs,
        })
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value,
        })
    }

    getListData = (value) => {
        const start = this.state.Inputs?.StartDate || new Date();
        const startDate = new Date(new Date(start).setHours(0,0,0,0));
        const currentDate = new Date(new Date(value).setHours(0,0,0,0));

        // Calculate weeks between
        const weekTimeDifference = currentDate.getTime() - startDate.getTime();
        const weekDifference = Math.floor((weekTimeDifference/(1000 * 3600 *24))/7);

        // Calculate Days Between
        const dayTimeDifference = currentDate.getTime() - startDate.getTime();
        const dayDifference = Math.floor(dayTimeDifference / (1000 * 3600 *24))-7*weekDifference;

        if (dayDifference < 0 || weekDifference < 0) return [];

        // Get the Workout
        const day = checkArray(this.state.UserProgram?.Program?.Program?.[weekDifference]?.week?.[dayDifference]?.day);
        const workouts = day.reduce((arr, wk, index) => {
            const completed_key = generateWorkoutProgressKey(weekDifference, dayDifference, index, -1, -1, -1, WORKOUT_PROGRESS.COMPLETED);
            const completed = this.state.UserProgram?.WorkoutProgress?.[completed_key] || false;
            const item = {
            type: 'success',
            Title: wk.WorkoutName || `Workout ${index+1}`,
            WeekIndex: weekDifference,
            DayIndex: dayDifference,
            Completed: completed,
          }
          return [...arr, item];
        }, []);
        return workouts;
      }

      dateCellRender = (value) => {
        const listData = this.getListData(value);
        return (
          listData.map((item, workoutIndex) => (
              item.Completed ?
                <Link to={`/program/${this.props.match.params.ID}/${item.WeekIndex}/${item.DayIndex}/${workoutIndex}`}>
                    <Button
                    icon={<CheckCircleFilled />}
                    className="ProgramOverview-Button-Complete"
                    type="primary" block size="small">{item.Title}</Button>
                </Link>
              :
                <Link to={`/program/${this.props.match.params.ID}/${item.WeekIndex}/${item.DayIndex}/${workoutIndex}`}>
                    <Button
                    className="ProgramOverview-Calendar-Workout-Button"
                    type="primary" block size="small">{item.Title}</Button>
                </Link>
          ))
        )
      }

    render() {
        return (
        <DataContext.Consumer>
        {
        data => {
            return (
            <div className="ProgramOverview-Main-Div">
            <Navbar />
            {
                this.state.Loading ?
                <Row justify="center"><Col><Spin size="large" /></Col></Row>
                :
                <Row justify="center">
                    <Col xs={23}>
                        <SimpleTextCard
                            Title={this.state.UserProgram?.Program?.Title}
                            Description={new Date().toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                            color={data.websiteTemplate?.ColourPalette?.DARK}
                        />
                        <Row className="ProgramOverview-Row" gutter={[10,10]}>
                            <Col xs={24}>
                                <h2 className="Customer-Fitness-Section-Title">Program Inputs</h2>
                            </Col>
                            <Col xs={24}>
                                <Row gutter={[5,5]} align="top">
                                    <Col xs={9}>
                                    <h4>Start Date</h4>
                                    </Col>
                                    <Col xs={15} sm={6}>
                                    <DatePicker value={moment(this.state.Inputs.StartDate)}
                                    onChange={(value) => {
                                        let inputs = { ...this.state.Inputs };
                                        inputs.StartDate = value;
                                        this.handleChange("Inputs", inputs);
                                    }}
                                    onSelect={(value) => {
                                        let inputs = { ...this.state.Inputs };
                                        inputs.StartDate = value;
                                        this.handleChange("Inputs", inputs);
                                    }}
                                    className="ProgramOverview-Input" />
                                    <p className="ProgramOverview-Input-Subtext">Enter the start date of the workout program.</p>
                                    </Col>
                                </Row>
                                <Row gutter={[5,5]} align="top">
                                    <Col xs={9}>
                                    <h4>Metric</h4>
                                    </Col>
                                    <Col xs={15} sm={6}>
                                    <Radio.Group value={this.state.Inputs.Metric}
                                    onChange={(e) => {
                                        let inputs = { ...this.state.Inputs };
                                        inputs.Metric = e.target.value;
                                        this.handleChange("Inputs", inputs);
                                    }}>
                                        <Radio value={UNITS.WEIGHT_INPUT.KG}>{UNITS.WEIGHT_INPUT.KG}</Radio>
                                        <Radio value={UNITS.WEIGHT_INPUT.LB}>{UNITS.WEIGHT_INPUT.LB}</Radio>
                                    </Radio.Group>
                                    <p className="ProgramOverview-Input-Subtext">Select your metric.</p>
                                    </Col>
                                </Row>
                                {
                                    Object.keys(this.state.ExerciseInputs).map((key) => {
                                        return (
                                            <Row gutter={[5,5]} align="top">
                                                <Col xs={9}>
                                                <h4>{this.state.Exercises[key]?.Title}</h4>
                                                </Col>
                                                <Col xs={15} sm={6}>
                                                <InputNumber
                                                value={this.state.Inputs[key]}
                                                onChange={(value) => {
                                                    let inputs = { ...this.state.Inputs };
                                                    inputs[key] = value;
                                                    this.handleChange("Inputs", inputs)
                                                }}
                                                className="ProgramOverview-Input" min={0} />
                                                <p className="ProgramOverview-Input-Subtext">Enter your 1 rep max for the "{this.state.Exercises[key]?.Title}" exercise.</p>
                                                </Col>
                                            </Row>
                                        )
                                    })
                                }
                            </Col>
                            <Col xs={24} sm={12} md={4}>
                                <Button type="primary" block
                                style={{
                                    backgroundColor: data.websiteTemplate?.ColourPalette?.DARK,
                                    borderColor: data.websiteTemplate?.ColourPalette?.DARK
                                }}
                                className="ProgramOverview-Button"
                                onClick={() => this.saveProgramInputs()}
                                >
                                    Save Program Inputs
                                </Button>
                            </Col>
                            <Col xs={24} sm={12} md={4}>
                                <Popconfirm
                                title="Are you sure you want to reset the program? Resetting the program will erase all inputs and workout progress. This action can't be reversed."
                                onConfirm={() => this.resetProgress()}
                                >
                                    <Button type="primary"
                                    block className="ProgramOverview-Button">
                                        Reset Program
                                    </Button>
                                </Popconfirm>
                            </Col>
                        </Row>
                        <Row>
                        <Col xs={24}>
                            <Calendar dateCellRender={this.dateCellRender} />
                        </Col>
                        </Row>
                    </Col>
                </Row>
            }
            </div>
            )
        }
        }
        </DataContext.Consumer>
        )
    }
}

export default ProgramOverview;