import React from 'react';
import './Card.css';
import { Row, Col } from 'antd';
import { H3, P } from '../index';

class Card extends React.Component {
    render() {
        return (
            <div className='Card'>
                <Row justify='center'>
                    <Col className='Card-Icon'>
                    {this.props.icon}
                    </Col>
                </Row>
                <Row justify='center'>
                    <Col>
                    <H3 text={this.props.text} highlight />
                    </Col>
                </Row>
                <Row>
                </Row>
                <Row>
                    <P text={this.props.paraText} />
                </Row>
            </div>
        )
    }
}

export default Card;