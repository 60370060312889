import React from 'react';
import './BookLiveClassPayment.css';
import { Row, Col, Input, Checkbox, message } from 'antd';
import { H1, H3, P, PhrosButton } from '../../Common';
import { COLOUR_PALETTE } from '../../constants';
import StripePower from '../../Assets/stripe-power.png';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { ENVIRONMENT, UID } from '../../constants';
import PhoneValidator from 'phone';
import * as EmailValidator from 'email-validator';
import { Redirect } from 'react-router-dom';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

class BookLiveClassPayment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Name: '',
            Email: '',
            PhoneNumber: '',
            AgreeTerms: false,
            AgreeMarketing: false,
            ButtonLoading: false,
            Redirect: false,
        }

        this.handleChange = this.handleChange.bind(this);
        this.checkFormDetails = this.checkFormDetails.bind(this);
    }

    checkFormDetails = async () => {
        if (String(this.state.Name).length === 0) {
            return message.error('Please enter your name.');
        }
        const validateEmail = EmailValidator.validate(this.state.Email);
        if (!validateEmail) {
            return message.error('Please enter a valid email.');
        }
        if (String(this.state.PhoneNumber).length === 0) {
            return message.error('Please enter a valid phone number.');
        }
        if (this.state.AgreeTerms === false) {
            return message.error('Please agree to the terms and conditions.');
        }

        this.setState({
            ButtonLoading: true
        });

        const payload = {
            uid: UID,
            id: this.props.ID,
            aid: this.props.AID,
            email: this.state.Email,
            customerName: this.state.Name,
            phoneNumber: PhoneValidator(this.state.PhoneNumber),
            AgreedTerms: this.state.AgreeTerms,
            AgreedMarketing: this.state.AgreeMarketing,
        }
        const response = await axios.post(`${ENVIRONMENT.BACKEND_URL}/stripe/create-checkout-session`, payload);
        if (response.data.error) {
            return message.error('Failed to checkout, please contact me!');
        } else if (response.data.successfulBooking) {
            this.setState({
                Redirect: true,
            });
            return true;
        }

        const stripe = await stripePromise;
        const result = await stripe.redirectToCheckout({
            sessionId: response.data.id,
        });
        if (result.error) {
            return message.error(result.error.message);
        }
    }

    getTime = (eventInfo) => {
        const startTime = eventInfo.Availability[0].StartTime;
        const endTime = eventInfo.Availability[0].EndTime;
        const miliseconds = new Date(endTime) - new Date(startTime);
        const minutes = Math.floor(miliseconds / 1000 / 60);
        return minutes;
    }

    getLocalTimeString(Availabilities) {
        const avail = Availabilities.reduce((val, avail) => {
            if(avail.ID === this.props.AID) {
                return avail;
            } else {
                return val;
            }
        }, {});
        let startTime = new Date(avail.StartTime);
        startTime = startTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});

        let endTime = new Date(avail.EndTime);
        endTime = endTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});

        let date = new Date(avail.Date);
        date = date.toLocaleDateString();

        return `${date}, ${startTime} - ${endTime}`;
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value,
        })
    }

    render() {
        return (
            <div>
                {
                    this.state.Redirect ?
                    <Redirect to="/free-live-class-booking" />
                    :
                    null
                }
                <Row>
                    <Col xs={24}>
                        <H1 text={this.props.profile.BusinessName} style={{ marginBottom: '1px' }} />
                        <P text={`${this.props.profile.FirstName} ${this.props.profile.LastName}`} />
                        <H3 text={`${this.props.keyEvents[this.props.ID].Name}, $${this.props.keyEvents[this.props.ID].Price} ${this.props.keyEvents[this.props.ID].Currency}`} />
                        <H3 text={this.getLocalTimeString(this.props.keyEvents[this.props.ID].Availability)} />
                    </Col>
                </Row>

                <Row justify='center' gutter={[20,20]}>
                    <Col xs={24}>
                        <P text='Name' />
                        <Input onChange={(e) => this.handleChange('Name', e.target.value)} placeholder='Name' size='large' style={{ borderRadius: '10px' }} value={this.state.Name} />
                    </Col>
                    <Col xs={24}>
                        <P text='Email' />
                        <Input onChange={(e) => this.handleChange('Email', e.target.value)} placeholder='Email' size='large' style={{ borderRadius: '10px' }} value={this.state.Email} />
                    </Col>
                    <Col xs={24}>
                        <P text='Phone Number' />
                        <Input onChange={(e) => this.handleChange('PhoneNumber', e.target.value)} placeholder='Phone Number' size='large' style={{ borderRadius: '10px' }} value={this.state.PhoneNumber} />
                    </Col>
                    <Col xs={24}>
                        <Checkbox value={this.state.AgreeTerms} onChange={(e) => this.handleChange('AgreeTerms', e.target.checked)}>
                            I agree to the <a href={this.props.keyEvents[this.props.ID].WaiverURL} rel="noreferrer" target="_blank">terms and conditions</a>.
                        </Checkbox>
                    </Col>
                    <Col xs={24}>
                        <Checkbox value={this.state.AgreeMarketing} onChange={(e) => this.handleChange('AgreeMarketing', e.target.checked)}>
                            I agree to receive marketing material from {this.props.profile.FirstName} {this.props.profile.LastName}
                        </Checkbox>
                    </Col>
                    <Col xs={24}>
                        <PhrosButton
                        ButtonLoading={this.state.ButtonLoading}
                        onClick={this.checkFormDetails}
                        text='Checkout' styles={{ background: COLOUR_PALETTE[this.props.profile.ColourScheme].DARK }}/>
                    </Col>
                    <Col xs={24} style={{ marginBottom: '20px' }}>
                        <P text="Payments through CLOUDSAFARI INC." />
                        <a href="https://stripe.com" target="_blank" rel="noreferrer">
                        <img src={StripePower} alt="Powered by Stripe" className="BookLiveClassPayment-Stripe"/>
                        </a>
                    </Col>
                    {
                        /*
                        <Col xs={24}>
                            <P text={`Once you book a class, we’ll send you the class link so that you can add it to your Calendar. Please check your junk folder to make sure you got it. If you do not receive a link within 20 minutes, please contact me at ${this.props.profile.Email} `}/>
                        </Col>
                        */
                    }
                </Row>
            </div>
        )
    }
}

export default BookLiveClassPayment;