import React from 'react';
import './Programs.css';
import { Navbar, ClassesHeader, ProgramCard } from '../../Components';
import { Helmet } from 'react-helmet';
import { DataContext } from '../../Context/Data';
import { Row, Col } from 'antd';

class Programs extends React.Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }
  render() {
    return (
      <DataContext.Consumer>
        {
          data => (
            <div>
            <Helmet>
              <title>{data.profile.BusinessName ? data.profile.BusinessName : 'My Personal Training Website'}</title>
              <meta name="description" content={data.profile.BusinessStatement} />
              <link rel="icon" type="image/png" href={data.profile.LogoURL} />
            </Helmet>
            <Navbar />
            <ClassesHeader profile={data.profile} websiteTemplate={data.websiteTemplate} />
            <Row justify="space-around">
                <Col xs={23} sm={22}>
                    <Row gutter={[30,30]} align='stretch'>
                        {
                            data.programs.map((program) => {
                                return (
                                    <Col xs={24} sm={8}>
                                        <ProgramCard program={program} profile={data.profile} />
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Col>
            </Row>
          </div>
          )
        }
      </DataContext.Consumer>
    )
  }
}

export default Programs;