import React from 'react';
import './CustomerFitness.css';
import { Navbar, PurchasedCard, CarouselCard, PurchaseCard, SimpleTextCard } from '../../Components';
import { AuthContext } from '../../Context/Auth';
import axios from 'axios';
import { ENVIRONMENT, ONLINE_IN_PERSON, RESOURCE_CAPACITY, UID } from '../../constants';
import { Row, Col, Spin, notification, Button, Input, Popconfirm } from 'antd';
import { DataContext } from '../../Context/Data';
import Modal from 'antd/lib/modal/Modal';
import { FileOutlined } from '@ant-design/icons';
import moment from 'moment';
import { checkArray } from '../../ValidateInput';

class CustomerFitness extends React.Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.state = {
            LiveClasses: [],
            OnDemandClasses: [],
            Workouts: [],
            Bootcamps: [],
            Programs: [],
            open: false,
            VideoURL: '',
            ThumbnailURL: '',
            Loading: true,
            BootcampModalOpen: false,
            BootcampIndex: 0,
            GiveBootcampOpen: false,
            GiveBootcampIndex: 0,
            BootcampEmail: '',
            BootcampConfirmEmail: '',
            Memberships: [],
            MembershipModal: false,
            Selected: {},
            AddedToMembership: {},
            AvailableToSelect: {},
            Membership: {},
        }
        this.viewVideo = this.viewVideo.bind(this);
        this.handleBootcampModal = this.handleBootcampModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.addToSelected = this.addToSelected.bind(this);
        this.removeFromSelected = this.removeFromSelected.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    getData = async () => {
        const { currentUser } = this.context;;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const payload = {
            UID: UID,
        }
        const response = await axios.post(`${ENVIRONMENT.BACKEND_URL}/customer/purchases`, payload, config);

        this.setState({
            Loading: false,
        })
        const LiveClasses = response.data.LiveClasses;
        const OnDemandVideos = response.data.OnDemandVideos;
        const Programs = response.data.Programs;
        const Bootcamps = response.data.Bootcamps;
        const memberships = response.data.Memberships;
        if (LiveClasses?.error || OnDemandVideos?.error || Programs?.error || Bootcamps?.error) {
            notification.error({
                message: 'Error',
                description: "Failed to get all your purchases, please contact me!"
            })
        }
        console.log(Programs);
        this.setState({
            LiveClasses: LiveClasses,
            OnDemandClasses: OnDemandVideos,
            Programs: Programs,
            Bootcamps: Bootcamps,
            Memberships: memberships,
        })
    }

    handleModalVisible = () => {
        this.setState({
            open: !this.state.open,
        })
    }

    viewVideo = (VideoURL, ThumbnailURL) => {
        this.setState({
            VideoURL: VideoURL,
            ThumbnailURL: ThumbnailURL,
            open: true,
        })
    }

    handleBootcampModal = () => {
        this.setState({
            BootcampModalOpen: !this.state.BootcampModalOpen,
        })
    }

    viewBootcamp = (BootcampIndex) => {
        this.setState({
            BootcampIndex: BootcampIndex,
            BootcampModalOpen: true,
        })
    }

    giveBootcampModalState = () => {
        this.setState({
            GiveBootcampOpen: !this.state.GiveBootcampOpen,
        })
    }

    giveBootcampToFriend = (BootcampIndex) => {
        this.setState({
            GiveBootcampIndex: BootcampIndex,
            GiveBootcampOpen: true,
        })
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value,
        })
    }

    handleAddToMembership = async () => {
        const { currentUser } = this.context;;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const payload = {
            Selected: this.state.Selected,
            UID: UID,
        }
        const response = await axios.post(`${ENVIRONMENT.BACKEND_URL}/customer/add-to-membership`, payload, config);
        if (response.data.error) {
            notification.error({
                message: "Error",
                description: `${response.data.error} - If you're having trouble please contact us at support@phros.ca`
            })
        } else {
            await this.getData();
            notification.success({
                message: "Success",
                description: "Successfully added your selection to the membership plan. Please refresh the page to view the changes.",
                duration: 0
            })
            this.setState({
                MembershipModal: false,
            })
        }
    }

    handleGiveToFriend = async () => {
        if (this.state.BootcampEmail !== this.state.BootcampConfirmEmail) {
            return notification.error({
                message: 'Error',
                description: 'Emails do not match. Please enter in the correct email.'
            })
        }
        if (!String(this.state.BootcampEmail).includes('@') || String(this.state.BootcampEmail).length <= 3) {
            return notification.error({
                message: 'Error',
                description: "Looks like that isn't an email, please enter in the correct email."
            })
        }
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const bootcampId = this.state.Bootcamps[this.state.GiveBootcampIndex].ID;
        const payload = {
            TrainerUID: UID,
            BootcampID: bootcampId,
            Email: this.state.BootcampEmail,
        }
        const response = await axios.post(`${ENVIRONMENT.BACKEND_URL}/customer/give-bootcamp-to-friend`, payload, config);
        if (response.data.error) {
            return notification.error({
                message: 'Error',
                description: 'Something went wrong, please send us an email at support@phros.ca to get support on this.'
            })
        } else {
            const bootcamps = this.state.Bootcamps;
            bootcamps.splice(this.state.BootcampIndex, 1);
            this.setState({
                Bootcamps: bootcamps,
                GiveBootcampOpen: false,
            })
            return notification.success({
                message: 'Success',
                description: 'Bootcamp sent to your friend! Please make sure they login or sign up with the same email you entered to access the bootcamp.'
            })
        }
    }

    addToSelected = (name, key, value) => {
        let original = this.state.Selected[name] || {};
        const selected = {
            ...this.state.Selected,
            [name]: {
                ...original,
                [key]: value,
            }
        }
        this.setState({
            Selected: selected,
        })
    }

    removeFromSelected = (name, key) => {
        let original = this.state.Selected[name];
        delete original[key];
        const selected = {
            ...this.state.Selected,
            [name]: original,
        }
        this.setState({
            Selected: selected,
        })
    }

    render() {
        const { currentUser } = this.context;
        console.log(currentUser);
        return (
            <DataContext.Consumer>
            {
            data => (
            <div className="Customer-Fitness-Main-Div">
                <Navbar />
                <Row justify='center' style={{ marginTop: '20px' }}>
                <Col xs={23} sm={22}>
                    {
                        !this.state.Loading ?
                    <div>
                        <Row justify="center" align="middle" style={{ marginBottom: "30px" }}>
                            <Col xs={24}>
                                <SimpleTextCard
                                    Title="Welcome!"
                                    Description={new Date().toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                                    color={data.websiteTemplate?.ColourPalette?.DARK}
                                />
                            </Col>
                        </Row>
                        {
                            data.profile?.PLAN?.FEATURES?.LIVECLASSES && checkArray(this.state.LiveClasses).length > 0 ?
                            <Row gutter={[5,5]} className="Customer-Fitness-PurchaseCard-Row">
                                <Col xs={24}>
                                    <h2 className="Customer-Fitness-Section-Title">Upcoming Live Sessions</h2>
                                </Col>
                                {
                                    this.state.LiveClasses.map((liveClass) => {
                                        return (
                                            liveClass?.Availability?.map(avail => {
                                                const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
                                                const date = new Date(avail.Date).toLocaleDateString("en-US", options);
                                                const startTime = new Date(avail.StartTime).toLocaleTimeString( navigator.language, {
                                                    hour: '2-digit',
                                                    minute:'2-digit'
                                                  });
                                                const endTime = new Date(avail.EndTime).toLocaleTimeString(navigator.language, {
                                                    hour: '2-digit',
                                                    minute:'2-digit'
                                                  });
                                                if (moment(new Date()).diff(new Date(avail.Date), 'days') > 0) {
                                                    return null;
                                                }
                                                let onlineOrInPerson = avail.OnlineOrInPerson || ONLINE_IN_PERSON.ONLINE.value;
                                                let meetingLink = avail.MeetingLink;
                                                let location = "";
                                                if (onlineOrInPerson === ONLINE_IN_PERSON.IN_PERSON.value) {
                                                    meetingLink = `https://www.google.com/maps/search/?api=1&query=${avail?.Latitude},${avail?.Longitude}`
                                                    location = avail.Address;
                                                }
                                                return (
                                                <Col xs={12} sm={8}>
                                                    <PurchaseCard
                                                        Title={liveClass.Name}
                                                        Description={ location.length > 0 ? `${date}, ${startTime} to ${endTime}. Located at ${location}.` : `${date}, ${startTime} to ${endTime}.`}
                                                        src={liveClass.PictureURL}
                                                        href={meetingLink}
                                                    />
                                                </Col>
                                                )
                                            })
                                        )
                                    })
                                }
                            </Row>
                            :
                            null
                        }
                        {
                            data.profile?.PLAN?.FEATURES?.ONDEMAND && checkArray(this.state.OnDemandClasses).length > 0 ?
                            <Row gutter={[5,5]} className="Customer-Fitness-PurchaseCard-Row">
                                <Col xs={24}>
                                    <h2 className="Customer-Fitness-Section-Title">On-Demand Videos</h2>
                                </Col>
                                {
                                    this.state.OnDemandClasses.map((onDemand) => {
                                        return (
                                            <Col xs={12} sm={8}>
                                                <PurchaseCard
                                                    Title={onDemand.Title}
                                                    Description={`Intensity: ${onDemand.IntensityRating}/5`}
                                                    src={onDemand.ThumbnailURL}
                                                    onClick={() => {
                                                        this.viewVideo(onDemand.VideoURL, onDemand.ThumbnailURL);
                                                    }}
                                                />
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                            :
                            null
                        }
                        {
                            data.profile?.PLAN?.FEATURES?.TRAININGPROGRAM && checkArray(this.state.Programs).length > 0 ?
                                <Row gutter={[5,5]} className="Customer-Fitness-PurchaseCard-Row">
                                    <Col xs={24}>
                                        <h2 className="Customer-Fitness-Section-Title">Training Programs</h2>
                                    </Col>
                                    {
                                        this.state.Programs?.map((wk) => {
                                            return (
                                                <Col xs={12} sm={8}>
                                                    <PurchaseCard
                                                        Title={wk.Title}
                                                        Description={`Intensity: ${wk.IntensityRating}/5`}
                                                        src={wk.PictureURL}
                                                        Link={`/program/${wk.ID}`}
                                                    />
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            :
                            null
                        }
                        {
                            data.profile?.PLAN?.FEATURES?.LIVECLASSES && checkArray(this.state.Bootcamps).length > 0 ?
                                <Row gutter={[5,5]} className="Customer-Fitness-PurchaseCard-Row">
                                    <Col xs={24}>
                                        <h2 className="Customer-Fitness-Section-Title">Bootcamps</h2>
                                    </Col>
                                    {
                                        this.state.Bootcamps.map((boot, index) => {
                                            return (
                                                <Col xs={12} sm={8}>
                                                    <PurchaseCard
                                                        Title={boot.Title}
                                                        Description={`Intensity: ${boot.IntensityRating}/5`}
                                                        src={boot.PictureURL}
                                                        onClick={() => this.viewBootcamp(index)}
                                                    />
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            :
                            null
                        }
                        {
                            data.profile?.PLAN ?
                                <Row gutter={[5,5]}>
                                    <Col xs={24} className="Customer-Fitness-PurchaseCard-Row">
                                        <h2 className="Customer-Fitness-Section-Title">Memberships</h2>
                                    </Col>
                                    {
                                        this.state.Memberships.map((memb, index) => {
                                            return (
                                                <Col xs={12} sm={8}>
                                                    <PurchaseCard
                                                        Title={memb.Name}
                                                        Description=""
                                                        src={memb.ImageURL}
                                                        onClick={() => {
                                                            this.handleChange("Selected", {
                                                                MembershipID: memb.ID,
                                                            });
                                                            this.handleChange("Membership", memb);
                                                            this.handleChange("AvailableToSelect", memb.SelectedResources);
                                                            this.handleChange("AddedToMembership", memb.Selected);
                                                            this.handleChange("MembershipModal", true);
                                                        }}
                                                    />
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            :
                            null
                        }
                    </div>
                    :
                    <Row justify='center'>
                        <Col>
                        <Spin size="large" />
                        </Col>
                    </Row>
                    }
                </Col>
                </Row>
                <Modal
                width={600}
                footer={null}
                onCancel={this.handleModalVisible}
                visible={this.state.open}>
                    <Row>
                        <Col xs={24}>
                            <div onContextMenu={e => e.preventDefault()}>
                            <video width="100%" height="400" controls controlsList="nodownload">
                                <source src={this.state.VideoURL} />
                            </video>
                            </div>
                        </Col>
                    </Row>
                </Modal>
                <Modal
                width={600}
                footer={null}
                onCancel={this.handleBootcampModal}
                visible={this.state.BootcampModalOpen}
                >
                    {
                        String(this.state.Bootcamps[this.state.BootcampIndex]?.DocumentURL).length > 0 ?
                        <Row style={{ marginTop: '20px' }}>
                            <Col xs={24}>
                                <a href={`${this.state.Bootcamps[this.state.BootcampIndex]?.DocumentURL}`} target="_blank" rel="noreferrer">
                                <Button size="large" block icon={<FileOutlined style={{ fontSize: '20px' }} />}>View Bootcamp Document</Button>
                                </a>
                            </Col>
                        </Row>
                        :
                        null
                    }
                    <Row gutter={[10,10]} style={{ marginTop: '20px' }}>
                        {
                            this.state.Bootcamps[this.state.BootcampIndex]?.LiveClasses?.map(liveClass => {
                                let current = new Date().getTime();
                                let lastDate = current;
                                for (let i = 0; i<liveClass?.Availability?.length; i++) {
                                    let avail = liveClass.Availability[i];
                                    let availDate = new Date(avail.Date).getTime();
                                    if (availDate >= lastDate) {
                                        lastDate = availDate;
                                    }
                                }
                                if (lastDate > current) return null;
                                return (
                                    liveClass?.Availability?.map(avail => {
                                        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
                                        const date = new Date(avail.Date).toLocaleDateString("en-US", options);
                                        const startTime = new Date(avail.StartTime).toLocaleTimeString( navigator.language, {
                                            hour: '2-digit',
                                            minute:'2-digit'
                                          });
                                        const endTime = new Date(avail.EndTime).toLocaleTimeString(navigator.language, {
                                            hour: '2-digit',
                                            minute:'2-digit'
                                          });
                                        if (new Date(avail.Date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
                                            return null;
                                        }
                                        return (
                                        <Col xs={24}>
                                            <PurchasedCard
                                                Title={liveClass.Name}
                                                IntensityRating={liveClass.IntensityRating}
                                                Level={liveClass.Level}
                                                ImageURL={liveClass.PictureURL}
                                                Anchor={avail.MeetingLink}
                                                profile={data.profile}
                                                Description={`${date}, ${startTime} to ${endTime}`}
                                                ButtonText={"Join Class"}
                                            />
                                        </Col>
                                        )
                                    })
                                )
                            })
                        }
                    </Row>
                </Modal>
                <Modal
                width={600}
                visible={this.state.GiveBootcampOpen}
                onCancel={this.giveBootcampModalState}
                okText="Confirm"
                onOk={this.handleGiveToFriend}
                >
                    <Row gutter={[20,20]}>
                        <Col xs={24}>
                            <h2>Give Bootcamp to a Friend</h2>
                            <p>Send this bootcamp to a friend by entering in their email below. Once confirmed, the bootcamp will be transferred to their account. Make sure you get the correct email - once transferred they need to login/sign-up with the same email to access the bootcamp.</p>
                        </Col>
                        <Col xs={24}>
                            <Input value={this.state.BootcampEmail} onChange={(e) => this.handleChange('BootcampEmail', e.target.value)} size='large' placeholder="Email" />
                        </Col>
                        <Col xs={24}>
                            <Input value={this.state.BootcampConfirmEmail} onChange={(e) => this.handleChange('BootcampConfirmEmail', e.target.value)} size='large' placeholder="Confirm Email" />
                        </Col>
                    </Row>
                </Modal>
                <Modal
                width={1000}
                visible={this.state.MembershipModal}
                onCancel = {() => {
                    this.handleChange("Membership", {});
                    this.handleChange("AddedToMembership", {});
                    this.handleChange("Selected", {});
                    this.handleChange("AvailableToSelect", {});
                    this.handleChange("MembershipModal", false);
                }}
                closable
                footer={null}
                >
                    <Row gutter={[20,20]}>
                        <Col xs={24}>
                            <h2>Live Classes</h2>
                            <Row gutter={[20,0]} style={{ marginBottom: '30px' }}>
                                <Col xs={24}>
                                <h4>Available to select for the {this.state.Membership?.RecurringInterval}:</h4>
                                </Col>
                                <Col>
                                {
                                    this.state.AvailableToSelect?.LiveClasses && this.state.AddedToMembership?.LiveClasses ?
                                    `Live Classes: ${this.state.AvailableToSelect?.LiveClasses?.Capacity === RESOURCE_CAPACITY.UNLIMITED.value ? "Unlimited" : this.state.AvailableToSelect?.LiveClasses?.Limit-Object.keys(this.state.AddedToMembership?.LiveClasses).length}`
                                    :
                                    null
                                }
                                </Col>
                                <Col>
                                {
                                    this.state.AvailableToSelect?.OnDemandClasses && this.state.AddedToMembership?.OnDemandVideos ?
                                    `On Demand Videos: ${this.state.AvailableToSelect?.OnDemandClasses?.Capacity === RESOURCE_CAPACITY.UNLIMITED.value ? "Unlimited" : this.state.AvailableToSelect?.OnDemandClasses?.Limit-Object.keys(this.state.AddedToMembership?.OnDemandVideos).length}`
                                    :
                                    null
                                }
                                </Col>
                                <Col>
                                {
                                    this.state.AvailableToSelect?.TrainingPrograms && this.state.AddedToMembership?.OnDemandVideos ?
                                    `Workout Programs: ${this.state.AvailableToSelect?.TrainingPrograms?.Capacity === RESOURCE_CAPACITY.UNLIMITED.value ? "Unlimited" : this.state.AvailableToSelect?.TrainingPrograms?.Limit-Object.keys(this.state.AddedToMembership?.Programs).length}`
                                    :
                                    null
                                }
                                </Col>
                            </Row>
                            <Row gutter={[20,20]}>
                            {
                                data.events.map((liveClass) => {
                                    const title = liveClass.Name;
                                    const image = liveClass.PictureURL;
                                    return (
                                        liveClass.Availability.map((avail) => {
                                            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
                                            const date = new Date(avail.Date).toLocaleDateString("en-US", options);
                                            const startTime = new Date(avail.StartTime).toLocaleTimeString( navigator.language, {
                                                hour: '2-digit',
                                                minute:'2-digit'
                                            });
                                            const endTime = new Date(avail.EndTime).toLocaleTimeString(navigator.language, {
                                                hour: '2-digit',
                                                minute:'2-digit'
                                            });
                                            if (new Date(avail.Date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
                                                return null;
                                            }
                                            let selected = false;
                                            const liveClasses = this.state.Selected.LiveClasses || {};
                                            if (avail.ID in liveClasses) {
                                                selected = true;
                                            }

                                            let added = false;
                                            const addedLiveClasses = this.state.Membership?.Selected?.LiveClasses || {};
                                            if (avail.ID in addedLiveClasses) {
                                                added = true;
                                            }
                                            return (
                                                <Col xs={24} sm={6}>
                                                <CarouselCard
                                                Added = {added}
                                                Selected={selected}
                                                handleAddClick={() => this.addToSelected("LiveClasses", avail.ID, liveClass.ID)}
                                                handleRemoveClick={() => this.removeFromSelected("LiveClasses", avail.ID)}
                                                name="LiveClass" LiveClass ID={liveClass.ID} handleChange={this.handleChange} title={title} secondaryTitle={`${date}, ${startTime} to ${endTime}`} src={image} alt={title} />
                                                </Col>
                                            )
                                        })
                                    )
                                })
                            }
                            </Row>
                        </Col>
                        <Col xs={24}>
                            <h2>On Demand Classes</h2>
                            <Row gutter={[20,20]}>
                            {
                                data.onDemandVideos.map((vid) => {
                                    const title = vid.Title;
                                    const image = vid.ThumbnailURL;
                                    let selected = false;
                                    const selectedVideos = this.state.Selected.OnDemandVideos || {};
                                    if (vid.ID in selectedVideos) {
                                        selected = true;
                                    }
                                    let added = false;
                                    const addedOnDemandVids = this.state.Membership?.Selected?.OnDemandVideos || {};
                                    if (vid.ID in addedOnDemandVids) {
                                        added = true;
                                    }
                                    return (
                                        <Col xs={24} sm={6}>
                                            <CarouselCard
                                            Added = {added}
                                            Selected={selected}
                                            handleAddClick={() => this.addToSelected("OnDemandVideos", vid.ID, true)}
                                            handleRemoveClick={() => this.removeFromSelected("OnDemandVideos", vid.ID)}
                                            name="Video" ID={vid.ID} handleChange={this.handleChange} title={title} src={image} alt={title} />
                                        </Col>
                                    )
                                })
                            }
                            </Row>
                        </Col>
                        <Col xs={24}>
                            <h2>Workout Programs</h2>
                            <Row gutter={[20,20]} align='stretch'>
                                {
                                    data.programs.map((program) => {
                                        const title = program.Title;
                                        const image = program.PictureURL;
                                        let selected = false;
                                        const selectedPrograms = this.state.Selected.Programs || {};
                                        if (program.ID in selectedPrograms) {
                                            selected = true
                                        }
                                        let added = false;
                                        const addedPrograms = this.state.Membership?.Selected?.Programs || {};
                                        if (program.ID in addedPrograms) {
                                            added = true;
                                        }
                                        return (
                                            <Col xs={24} sm={6}>
                                                <CarouselCard
                                                Added = {added}
                                                Selected = {selected}
                                                handleAddClick={() => this.addToSelected("Programs", program.ID, true)}
                                                handleRemoveClick={() => this.removeFromSelected("Programs", program.ID)}
                                                name="Program" ID={program.ID} handleChange={this.handleChange} title={title} src={image} alt={title} />
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Col>
                        <Col xs={24} style={{ textAlign: 'right' }}>
                            <Popconfirm
                            title="Are you sure you want to add these to your membership plan? Once selected, this cannot be reversed."
                            onConfirm={this.handleAddToMembership}
                            >
                            <Button type="primary" className="CarouselCard-Button">Add Selection to Membership</Button>
                            </Popconfirm>
                        </Col>
                    </Row>
                </Modal>
            </div>
                )
            }
        </DataContext.Consumer>
        )
    }
};

export default CustomerFitness;