import './Testimonial3.css';
import React from 'react';
import { Row, Col, Pagination, } from 'antd';
import { checkArray } from '../../../../ValidateInput';

class Testimonial3 extends React.Component {
    state = {
        Name: "",
        Description: "",
        PageNumber: 1,
        ItemsPerPage: 3,
        StartIndex: 0,
        EndIndex: 3,
    }

    componentDidMount() {
        if (checkArray(this.props.Testimonials).length > 0) {
            this.setState({
                Name: this.props.Testimonials[0]?.Name,
                Description: this.props.Testimonials[0]?.Description,
            })
        }
    }
    render() {
        return (
            <Row justify="center" style={{ backgroundColor: "white", padding: "20px" }}>
            <Col xs={24}>
                <h2 className="Contact1-Title">Testimonials</h2>
            </Col>
            <Col xs={24} sm={12} md={8}>
                <Row gutter={[10,10]} justify="center">
                    {
                        checkArray(this.props.Testimonials).map((test, index) => {
                            if (this.state.StartIndex <= index && index < this.state.EndIndex) {
                                return (
                                    <Col xs={24}>
                                        <Row align='middle'
                                        onClick={() => {
                                            this.setState({
                                                Name: test.Name,
                                                Description: test.Description,
                                            })
                                        }}
                                        className="Testimonial3-Select-Card">
                                            <Col>
                                                <img src={test.ImageURL} alt={test.Title} className="Testimonial3-Image" />
                                            </Col>
                                            <Col>
                                                <h2 className="Testimonial3-Title">{test.Name}</h2>
                                            </Col>
                                        </Row>
                                    </Col>
                                )
                            }
                            return null;
                        })
                    }
                    <Col xs={24} className="Testimonial3-Pagination">
                    <Pagination onChange={(page) => {
                        let startIndex = (this.state.ItemsPerPage)*(page-1);
                        let endIndex = (this.state.ItemsPerPage)*(page);
                        this.setState({
                            StartIndex: startIndex,
                            EndIndex: endIndex,
                            PageNumber: page,
                        })
                    }}
                    current={this.state.PageNumber} pageSize={this.state.ItemsPerPage} size="small" total={checkArray(this.props.Testimonials).length} />
                    </Col>
                </Row>
            </Col>
            <Col xs={24} sm={12} md={16}>
                <div className="Testimonial3-Text-Div">
                    <h2 className="Testimonial3-Title-Displayed">{this.state.Name}</h2>
                    <p className="Testimonial3-Description">{this.state.Description}</p>
                </div>
            </Col>
            </Row>
        )
    }
}

export default Testimonial3;