import React from 'react';
import { SOCIAL_MEDIA } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter, faLinkedin, faPinterest, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { COLOUR_PALETTE } from '../../constants';
import './SocialMedia.css';

class SocialMedia extends React.Component {
    render() {
        const socialMediaStyle = {
            color: COLOUR_PALETTE[this.props.ColourScheme].DARK,
        }
        return (
            <a
                href={this.props.URL}
                className='SocialMedia-Icon'
                target="_blank"
                rel="noreferrer"
                style={socialMediaStyle}>
                {
                    this.props.name === SOCIAL_MEDIA.FACEBOOK ?
                    <FontAwesomeIcon icon={faFacebook} /> :
                    this.props.name === SOCIAL_MEDIA.INSTAGRAM ?
                    <FontAwesomeIcon icon={faInstagram} /> :
                    this.props.name === SOCIAL_MEDIA.TWITTER ?
                    <FontAwesomeIcon icon={faTwitter} /> :
                    this.props.name === SOCIAL_MEDIA.LINKEDIN ?
                    <FontAwesomeIcon icon={faLinkedin} /> :
                    this.props.name === SOCIAL_MEDIA.PINTEREST?
                    <FontAwesomeIcon icon={faPinterest} /> :
                    this.props.name === SOCIAL_MEDIA.YOUTUBE ?
                    <FontAwesomeIcon icon={faYoutube} /> :
                    this.props.name
                }
            </a>
        )
    }
}

export default SocialMedia;