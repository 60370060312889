import './Testimonial2.css';
import React from 'react';
import { Row, Col } from 'antd';
import { checkArray } from '../../../../ValidateInput';

class Testimonial2 extends React.Component {
    render() {
        return (
            <Row justify="center"  style={{ backgroundColor: "white", padding: "10px" }}>
            <Col xs={24}>
                <h2 className="Contact1-Title">Testimonials</h2>
            </Col>
            <Col xs={24}>
                <Row gutter={[30,30]}>
                    {
                        checkArray(this.props.Testimonials).map((test) => {
                            return (
                                <Col xs={24} sm={12} lg={8}>
                                    <Row className="Testimonial2-Card">
                                        <Col xs={24}>
                                            <img src={test.ImageURL} alt={test.Name} className="Testimonial2-Image" />
                                        </Col>
                                        <Col xs={24}>
                                            <div className="Testimonial2-Div">
                                            <h2 className="Testimonial2-Title">{test.Name}</h2>
                                            <p className="Testimonial2-Description">{test.Description}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Col>
            </Row>
        )
    }
}

export default Testimonial2;