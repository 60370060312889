import React from 'react';
import './Workouts.css';
import { Navbar } from '../../Components';
import { Helmet } from 'react-helmet';
import { DataContext } from '../../Context/Data';
import { AuthContext } from '../../Context/Auth';
import { ENVIRONMENT, UID } from '../../constants';
import { Row, Col, notification, Collapse, Divider, Button, Checkbox } from 'antd';
import axios from 'axios';
import { H2 } from '../../Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

class UserWorkout extends React.Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.state = {
            Workout: {},
            Exercises: [],
        }
    }

    componentDidMount() {
        window.scrollTo(0,0);
        this.getWorkoutData();
    }

    saveUserWorkout = async () => {
        const { currentUser } = this.context;;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const payload = {
            ID: this.props.match.params.ID,
            Workout: this.state.Workout.Workout
        }
        await axios.post(`${ENVIRONMENT.BACKEND_URL}/user-workouts/save-user-workout`, payload, config);
    }

    completeInstruction = async (weekIndex, dayIndex, exerciseIndex, instructionIndex, value) => {
        let program = this.state.Workout.Workout;
        if (!program.Completed) {
            program.Completed = {};
        }
        if (!program.Completed[weekIndex]) {
            program.Completed[weekIndex] = {};
        }
        if (!program.Completed[weekIndex][dayIndex]) {
            program.Completed[weekIndex][dayIndex] = {};
        }
        if (!program.Completed[weekIndex][dayIndex][exerciseIndex]) {
            program.Completed[weekIndex][dayIndex][exerciseIndex] = {};
        }
        if (!program.Completed[weekIndex][dayIndex][exerciseIndex][instructionIndex]) {
            program.Completed[weekIndex][dayIndex][exerciseIndex][instructionIndex] = {}
        }
        program.Completed[weekIndex][dayIndex][exerciseIndex][instructionIndex].Complete = value;
        let workout = this.state.Workout;
        workout.Workout = program;
        this.setState({
            Workout: workout,
        })
        await this.saveUserWorkout();
    }

    completeExercise = async (weekIndex, dayIndex, exerciseIndex, value) => {
        let program = this.state.Workout.Workout;
        if (!program.Completed) {
            program.Completed = {};
        }
        if (!program.Completed[weekIndex]) {
            program.Completed[weekIndex] = {};
        }
        if (!program.Completed[weekIndex][dayIndex]) {
            program.Completed[weekIndex][dayIndex] = {};
        }
        if (!program.Completed[weekIndex][dayIndex][exerciseIndex]) {
            program.Completed[weekIndex][dayIndex][exerciseIndex] = {};
        }
        program.Completed[weekIndex][dayIndex][exerciseIndex].Complete = value;
        let workout = this.state.Workout;
        workout.Workout = program;
        this.setState({
            Workout: workout,
        })
        await this.saveUserWorkout();
    }

    completeDay = async (weekIndex, dayIndex, value) => {
        let program = this.state.Workout.Workout;
        if (!program.Completed) {
            program.Completed = {};
        }
        if (!program.Completed[weekIndex]) {
            program.Completed[weekIndex] = {};
        }
        if (!program.Completed[weekIndex][dayIndex]) {
            program.Completed[weekIndex][dayIndex] = {};
        }
        program.Completed[weekIndex][dayIndex].Complete = value;
        let workout = this.state.Workout;
        workout.Workout = program;
        this.setState({
            Workout: workout,
        })
        await this.saveUserWorkout();
    }

    completeWeek = async (weekIndex, value) => {
        let program = this.state.Workout.Workout;
        if (!program.Completed) {
            program.Completed = {};
        }
        if (!program.Completed[weekIndex]) {
            program.Completed[weekIndex] = {};
        }
        program.Completed[weekIndex].Complete = value;
        let workout = this.state.Workout;
        workout.Workout = program;
        this.setState({
            Workout: workout,
        })
        await this.saveUserWorkout();
    }

    getWorkoutData = async () => {
        const { currentUser } = this.context;;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const payload = {
            ID: this.props.match.params.ID,
            UID: UID,
        }
        const response = await axios.post(`${ENVIRONMENT.BACKEND_URL}/user-workouts/get-user-workout-with-id`, payload, config);

        if (response.data.error) {
            return notification.error({
                message: "Error",
                description: "Error getting the workout, please contact me!"
            })
        } else {
            this.setState({
                Workout: response.data.workout,
                Exercises: response.data.exercises,
            })
        }
    }

  render() {
    return (
      <DataContext.Consumer>
        {
          data => (
            <div>
            <Helmet>
              <title>{data.profile.BusinessName ? data.profile.BusinessName : 'My Personal Training Website'}</title>
              <meta name="description" content={data.profile.BusinessStatement} />
              <link rel="icon" type="image/png" href={data.profile.LogoURL} />
            </Helmet>
            <Navbar />
            <Row justify="center">
            <Col xs={23}>
                <Row>
                    <Col xs={24}>
                        {
                            this.state.Workout.Workout?.Title?
                            <H2 text={`${this.state.Workout.Workout?.Title}`} />
                            :
                            null
                        }
                    </Col>
                </Row>
                {
                    this.state.Workout.Workout?.Weeks?.map((wk, weekIndex) => {
                        const weekComplete = this.state.Workout.Workout?.Completed?.[weekIndex]?.Complete;
                        return (
                            <Collapse>
                                <Collapse.Panel header={`Week ${weekIndex+1}`} extra={weekComplete ? <FontAwesomeIcon icon={faCheckCircle} className="UserWorkout-Check" /> : null}>
                                    {
                                        wk.Week?.map((day, dayIndex) => {
                                            const dayComplete = this.state.Workout.Workout?.Completed?.[weekIndex]?.[dayIndex]?.Complete;
                                            return (
                                                <Collapse>
                                                    <Collapse.Panel header={`Day ${dayIndex+1}`} extra={dayComplete ? <FontAwesomeIcon icon={faCheckCircle} className="UserWorkout-Check" /> : null}>
                                                        {
                                                            day.Exercises?.map((exerObj, exerciseIndex) => {
                                                                const exerID = exerObj.ID;
                                                                const exercise = this.state.Exercises[exerID];
                                                                const exerciseComplete = this.state.Workout.Workout?.Completed?.[weekIndex]?.[dayIndex]?.[exerciseIndex]?.Complete;
                                                                return (
                                                                    <Collapse>
                                                                        <Collapse.Panel header={exercise.Title} extra={exerciseComplete ? <FontAwesomeIcon icon={faCheckCircle} className="UserWorkout-Check" /> : null}>
                                                                            {
                                                                                exerObj.Instructions?.map((inst, instructionIndex) => {
                                                                                    const instructionComplete = this.state.Workout.Workout?.Completed?.[weekIndex]?.[dayIndex]?.[exerciseIndex]?.[instructionIndex]?.Complete;
                                                                                    return (
                                                                                        <div>
                                                                                            <Row justify='space-between'>
                                                                                                <Col>
                                                                                                <h3>Set {instructionIndex+1}, {inst.Target} </h3>
                                                                                                </Col>
                                                                                                <Col>
                                                                                                <Checkbox checked={instructionComplete} onChange={(e) => this.completeInstruction(weekIndex, dayIndex, exerciseIndex, instructionIndex, e.target.checked)} className="UserWorkout-Inst-Checkbox" />
                                                                                                </Col>
                                                                                            </Row>
                                                                                            {
                                                                                                String(inst.Weight).length > 0 ?
                                                                                                <Row className="UserWorkout-Text-Spacing">
                                                                                                    Weight: {inst.Weight}
                                                                                                </Row>
                                                                                                :
                                                                                                null
                                                                                            }
                                                                                            {
                                                                                                String(inst.ExtraNotes).length > 0 ?
                                                                                                <Row className="UserWorkout-Text-Spacing">
                                                                                                Extra Notes: {inst.ExtraNotes}
                                                                                                </Row>
                                                                                                :
                                                                                                null
                                                                                            }
                                                                                            <Divider />
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                            <Row className="UserWorkout-Text-Spacing">
                                                                                <Col xs={24} sm={8}>
                                                                                        {
                                                                                            exerciseComplete ?
                                                                                            <Button danger block onClick={() => this.completeExercise(weekIndex, dayIndex, exerciseIndex, !exerciseComplete)}>
                                                                                             Uncheck {exercise.Title}
                                                                                            </Button>
                                                                                            :
                                                                                            <Button block onClick={() => this.completeExercise(weekIndex, dayIndex, exerciseIndex, !exerciseComplete)}>
                                                                                                Complete {exercise.Title}
                                                                                            </Button>
                                                                                        }
                                                                                </Col>
                                                                            </Row>
                                                                        </Collapse.Panel>
                                                                    </Collapse>
                                                                )
                                                            })
                                                        }
                                                        <Row className="UserWorkout-Text-Spacing">
                                                            <Col xs={24}>
                                                            Notes for day {dayIndex+1}: {day.Notes}
                                                            </Col>
                                                        </Row>
                                                        <Row className="UserWorkout-Text-Spacing">
                                                            <Col xs={24} sm={8}>
                                                                {
                                                                    dayComplete ?
                                                                    <Button danger block onClick={() => this.completeDay(weekIndex, dayIndex, !dayComplete)}>
                                                                        Uncheck Day {dayIndex+1}
                                                                    </Button>
                                                                    :
                                                                    <Button block onClick={() => this.completeDay(weekIndex, dayIndex, !dayComplete)}>
                                                                        Complete Day {dayIndex+1}
                                                                    </Button>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </Collapse.Panel>
                                                </Collapse>
                                            )
                                        })
                                    }
                                    <Row>
                                    <Col xs={24} sm={8} className="UserWorkout-Text-Spacing">
                                        {
                                            weekComplete ?
                                            <Button danger block onClick={() => this.completeWeek(weekIndex, !weekComplete)}>
                                                Uncheck Week {weekIndex+1}
                                            </Button>
                                            :
                                            <Button block onClick={() => this.completeWeek(weekIndex, !weekComplete)}>
                                                Complete Week {weekIndex+1}
                                            </Button>
                                        }
                                    </Col>
                                    </Row>
                                </Collapse.Panel>
                            </Collapse>
                        )
                    })
                }
            </Col>
            </Row>
          </div>
          )
        }
      </DataContext.Consumer>
    )
  }
}

export default UserWorkout;