import React from 'react';
import './Footer.css';
import { Row, Col, Divider } from 'antd';
import { Logo, LinkText, P, H3 } from '../../Common';
import { DataContext } from '../../Context/Data';

class Footer extends React.Component {
    render() {
        return (
            <DataContext.Consumer>
            {
              data => (
            <Row justify='center'>
                <Col xs={23} sm={22} className="Footer-Outer-Col">
                    <Row>
                        <Col xs={24}>
                            <Divider className='Footer-Divider' />
                        </Col>
                    </Row>
                    <Row justify='space-around' gutter={[10,10]}>
                        <Col xs={20} sm={8}>
                            <Row align='middle' gutter={[10,10]}>
                                <Col>
                                <Logo SRC={data.profile?.LogoURL} />
                                </Col>
                                <Col>
                                <H3 text={data.profile?.BusinessName} />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={8}>
                            <H3 text="Fitness" />
                            <LinkText P text="home" link="" />
                            {
                                data.events.length > 0 && data.profile?.PLAN?.FEATURES?.LIVECLASSES ?
                                <LinkText P text="live classes" link="live-classes" />
                                :
                                null
                            }
                            {
                                data.bootcamps.length > 0 && data.profile?.PLAN?.FEATURES?.LIVECLASSES ?
                                <LinkText P text="bootcamps" link="bootcamps" />
                                :
                                null
                            }
                            {
                            data.onDemandVideos.length > 0 && data.profile?.PLAN?.FEATURES?.ONDEMAND ?
                            <LinkText P text="on-demand classes" link="on-demand-classes" />
                            : null
                            }
                            {
                            data.profile?.PLAN?.FEATURES?.MEMBERSHIPS && data.memberships?.length > 0 && (data.profile?.PLAN?.NAME === "ADVANCED" || data.profile?.PLAN?.NAME === "INTERMEDIATE") ?
                            <LinkText P text="memberships" link="/memberships" />
                            :
                            null
                        }
                        </Col>
                        <Col xs={8}>
                            <H3 text="Contact Us" />
                            <a href={`mailto:${data.profile?.Email}`}><P text={data.profile?.Email} /></a>
                            <a href={`tel:${data.profile?.PhoneNumber}`}><P text={data.profile?.PhoneNumber} /></a>
                        </Col>
                    </Row>
                </Col>
            </Row>
            )
            }
            </DataContext.Consumer>
        )
    }
}

export default Footer;