import React from 'react';
import './BookLiveClassDetails.css';
import { Row, Col } from 'antd';
import { H2, H3 } from '../../Common';
import { COLOUR_PALETTE, TRAINING_EXPERIENCE_LEVEL } from '../../constants';
import BackgroundSVG from './BackgroundSVG';
import { renderToStaticMarkup } from 'react-dom/server';

class BookLiveClassDetails extends React.Component {
    getLocalTimeString(Availabilities) {
        const avail = Availabilities.reduce((val, avail) => {
            if(avail.ID === this.props.AID) {
                return avail;
            } else {
                return val;
            }
        }, {});
        let startTime = new Date(avail.StartTime);
        startTime = startTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});

        let endTime = new Date(avail.EndTime);
        endTime = endTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});

        let date = new Date(avail.Date);
        date = date.toLocaleDateString();

        return `${date}, ${startTime} - ${endTime}`;
    }

    render() {
        const svgString = encodeURIComponent(
            renderToStaticMarkup(
                <BackgroundSVG
                fill={COLOUR_PALETTE[this.props.profile.ColourScheme].LIGHT}
                />
            )
        );

        return (
            <div>
                <Row>
                    <Col xs={24}
                    style={{
                        backgroundImage: `url("data:image/svg+xml;utf8,${svgString}")`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        textAlign: 'center',
                    }}
                    >
                        <img src={this.props.keyEvents[this.props.ID].PictureURL} alt={this.props.profile.BusinessName} className='BookLiveClassDetails-Image' />
                    </Col>
                </Row>
                <Row>
                    <Col xs={0} sm={24}>
                        <H2 text={this.props.keyEvents[this.props.ID].Name} />
                        <H3 text={`Price: $${this.props.keyEvents[this.props.ID].Price} ${this.props.keyEvents[this.props.ID].Currency}`} />
                        <H3 text={`Intensity Level ${this.props.keyEvents[this.props.ID].IntensityRating}/5`} />
                        <H3 text={`Level: ${TRAINING_EXPERIENCE_LEVEL[this.props.keyEvents[this.props.ID].Level]}`} />
                        <H3 text={this.getLocalTimeString(this.props.keyEvents[this.props.ID].Availability)} />
                    </Col>
                </Row>
            </div>
        )
    }
}

export default BookLiveClassDetails;