import React from 'react';
import './LiveClassCard.css';
import { H2, H3, P, PhrosButton } from '../../Common';
import { Row, Col, Modal, message } from 'antd';
import { TRAINING_EXPERIENCE_LEVEL } from '../../constants';
import { connect } from 'react-redux';
import { addLiveClass  } from '../../Redux/actions';
import { AvailabilityList } from '../../Components';
import { DataContext } from '../../Context/Data';


class LiveClassCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
        }
    }
    changeModalVisibility = () => {
        this.setState({
            modalOpen: !this.state.modalOpen
        })
    }

    render() {
        return (
            <DataContext.Consumer>
            {
            data => (
            <div className='Video-Card'>
                <img src={this.props.liveClass.PictureURL} alt={this.props.liveClass.Name} className="Video-Card-Image" />
                <div className='Video-Card-Container'>
                <H3 text={`${this.props.liveClass.Name}`} />
                {
                    this.props.liveClass.Price === 0 ?
                    <P text="Free Class" />
                    :
                    <P text={`$${this.props.liveClass.Price} ${this.props.liveClass.Currency}`} />
                }
                <h3>Intensity: {this.props.liveClass.IntensityRating}/5  - {TRAINING_EXPERIENCE_LEVEL[this.props.liveClass.Level]}</h3>
                <div className='Video-Card-Button'>
                    <PhrosButton onClick={this.changeModalVisibility} text='view availability' styles={{ background: data.websiteTemplate?.DARK }}/>
                </div>
                </div>
                <Modal cancelText="Close"
                footer={null} width={800} visible={this.state.modalOpen} onCancel={this.changeModalVisibility}>
                    <Row gutter={[20,20]} style={{ padding: '10px' }}>
                        <Col xs={24}>
                            <img src={this.props.liveClass.PictureURL} alt={this.props.liveClass.Name} className="Video-Card-Modal-Image" />
                        </Col>
                        <Col xs={24}>
                            <div>
                            <H2 text={`${this.props.liveClass.Name}`} />
                            {
                                this.props.liveClass.Price === 0 ?
                                <H3 text="Free Class" />
                                :
                                <H3 text={`$${this.props.liveClass.Price} ${this.props.liveClass.Currency}`} />
                            }
                            <div className='Video-Card-Space'>
                            <P text={this.props.liveClass.Description} />
                            </div>
                            <div className='Video-Card-Space'>
                                {
                                    this.props.liveClass.IntensityRating <= 5 ?
                                    <P style={{ fontWeight: 400 }} text={`Intensity Rating: ${this.props.liveClass.IntensityRating}/5`} />
                                    :
                                    <p>Open Intensity</p>
                                }
                            </div>
                            <div className='Video-Card-Space'>
                            <P style={{ fontWeight: 400 }} text={`Level: ${TRAINING_EXPERIENCE_LEVEL[this.props.liveClass.Level]}`} />
                            </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                        <AvailabilityList profile={data.profile} keyEvents={data.keyEvents} ID={this.props.liveClass.ID}/>
                        </Col>
                    </Row>
                </Modal>
            </div>
                )
            }
        </DataContext.Consumer>
        )
    }
}

const mapStateToProps = state => ({
    LiveClasses: state.LiveClasses,
});

const mapDispatchToProps = dispatch => ({
    addLiveClass: liveClass => dispatch(addLiveClass(liveClass))
})

export default connect(mapStateToProps, mapDispatchToProps)(LiveClassCard);