import React from 'react';
import './ProgramWorkout.css';
import { AuthContext } from '../../../Context/Auth';
import { DataContext } from '../../../Context/Data';
import { ENVIRONMENT, WORKOUT_PROGRESS, UID } from '../../../constants';
import { SimpleTextCard, Navbar } from '../../../Components';
import { Input, Button, notification, Spin, Row, Col } from 'antd';
import axios from 'axios';
import { checkArray, checkObj } from '../../../ValidateInput';
import { Link } from 'react-router-dom';
import { generateWorkoutProgressKey } from '../../../util';
import { CheckCircleFilled } from '@ant-design/icons';

class ProgramWorkout extends React.Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.state = {
            UserProgram: {},
            Exercises: {},
            Loading: true,
            ExerciseInputs: {},
            Workout: {},
            Completed: false,
            Notes: "",
        }
    }

    componentDidMount() {
        this.getData();
    }

    // Get Exercise Data (Workout Program and Exercise)
    getData = async () => {
        const { currentUser } = this.context;;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const payload = {
            ID: this.props.match.params.ID,
            UID: UID,
        }
        const response = await axios.post(`${ENVIRONMENT.BACKEND_URL}/user-programs/get-user-program-with-id`, payload, config);
        if (response.data.error) {
            return notification.error ({
                message: "Error",
                description: `Failed to get the program data (${response.data.error})`,
                duration: null,
            })
        } else {
            const inputs = response.data.Program[this.props.match.params.ID]?.Inputs || {};
            const program = response.data.Program[this.props.match.params.ID].Program?.Program;
            const WeekIndex = this.props.match.params.WeekIndex;
            const DayIndex = this.props.match.params.DayIndex;
            const WorkoutIndex = this.props.match.params.WorkoutIndex;
            const workout = checkObj(program[WeekIndex]?.week[DayIndex]?.day[WorkoutIndex]);
            const workoutProgress = response.data.Program[this.props.match.params.ID]?.WorkoutProgress || {};

            // Get the Notes and Completion
            const notes_key = generateWorkoutProgressKey(WeekIndex, DayIndex, WorkoutIndex, -1, -1, -1, WORKOUT_PROGRESS.NOTES);
            const completed_key = generateWorkoutProgressKey(WeekIndex, DayIndex, WorkoutIndex, -1, -1, -1, WORKOUT_PROGRESS.COMPLETED);
            const notes = workoutProgress[notes_key] || "";
            const completed = workoutProgress[completed_key] || false;

            this.setState({
                UserProgram: response.data.Program[this.props.match.params.ID],
                Loading: false,
                Inputs: inputs,
                Workout: workout,
                Notes: notes,
                Completed: completed,
            })
            console.log({ workout });
        }
        // Loop through Entire Program, Get Inputs needed (those with %)
        const exercises = {
            ...response.data.UserExercises,
            ...response.data.LibraryExercises,
        }
        this.setState({
            Exercises: exercises,
        })
    }

    completeDay = async  () => {
        this.setState({
            Completed: !this.state.Completed,
        })
        await this.saveProgramInput();
    }

    saveProgramInput = async () => {
        const { currentUser } = this.context;;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const progress = this.state.UserProgram?.WorkoutProgress || {};
        const WeekIndex = this.props.match.params.WeekIndex;
        const DayIndex = this.props.match.params.DayIndex;
        const WorkoutIndex = this.props.match.params.WorkoutIndex;
        const notes_key = generateWorkoutProgressKey(WeekIndex, DayIndex, WorkoutIndex, -1, -1, -1, WORKOUT_PROGRESS.NOTES);
        const completed_key = generateWorkoutProgressKey(WeekIndex, DayIndex, WorkoutIndex, -1, -1, -1, WORKOUT_PROGRESS.COMPLETED);
        const newProgress = {
            ...progress,
            [notes_key]: this.state.Notes,
            [completed_key]: this.state.Completed,
        }
        const payload = {
            ProgramID: this.props.match.params.ID,
            ID: this.state.UserProgram?.UserProgramID,
            UID: UID,
            WorkoutProgress: newProgress,
        }
        const response = await axios.post(`${ENVIRONMENT.BACKEND_URL}/user-programs/save-program-progress`, payload, config);
        if (response.data.error) {
            return notification.error({
                message: "Error",
                description: `Failed to save the program progress (${response.data.error}). If this persists please contact us at support@phros.ca.`
            })
        } else {
            return notification.success({
                message: "Success",
                description: "Successfully saved the program inputs."
            })
        }
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value,
        })
    }

    render() {
    return (
    <DataContext.Consumer>
        {
        data => {
            return (
                <div className="ProgramWorkout-Main-Div">
                    <Navbar />
                    {
                        this.state.Loading ?
                        <Row justify="center">
                            <Col><Spin size="large" /></Col>
                        </Row>
                        :
                        <Row justify="center">
                            <Col xs={23}>
                            <SimpleTextCard
                                Title={this.state.Workout?.WorkoutName}
                                Description={this.state.UserProgram?.Program?.Title}
                                color={data.websiteTemplate?.ColourPalette?.DARK}
                            />
                            <Row gutter={[10,10]} className="ProgramOverview-Row">
                                <Col xs={24}>
                                    <h2 className="Customer-Fitness-Section-Title">{this.state.Workout?.WorkoutName}</h2>
                                    <p className="ProgramOverview-Input-Subtext">Click on each exercise to view the details.</p>
                                </Col>
                                {
                                    checkArray(this.state.Workout?.WorkoutExercises).map((exerciseObj, exerIndex) => {
                                        const exercise = checkArray(exerciseObj?.exercise);
                                        let name = `Exercise ${exerIndex+1}`;
                                        if (exercise.length >= 2) {
                                            name = `Exercise ${exerIndex+1} (Superset)`
                                        }
                                        return (
                                            <Col xs={24} sm={12} md={6}>
                                                <h3 className="Customer-Fitness-Section-Title">{name}</h3>
                                                {
                                                    exercise.map((exer, superIndex) => {
                                                        const WeekIndex = this.props.match.params.WeekIndex;
                                                        const DayIndex = this.props.match.params.DayIndex;
                                                        const WorkoutIndex = this.props.match.params.WorkoutIndex;
                                                        const completed_key = generateWorkoutProgressKey(WeekIndex, DayIndex, WorkoutIndex, exerIndex, superIndex, -1, WORKOUT_PROGRESS.COMPLETED);
                                                        const completed = this.state.UserProgram?.WorkoutProgress?.[completed_key] || false;
                                                        return (
                                                        <div className="ProgramWorkout-Exercise-Link">
                                                            <Link to={`/program/${this.props.match.params.ID}/${this.props.match.params.WeekIndex}/${this.props.match.params.DayIndex}/${this.props.match.params.WorkoutIndex}/${exerIndex}/${superIndex}`}>
                                                            <Button type="primary" block size="large"
                                                            icon={!completed ? null : <CheckCircleFilled />}
                                                            className={!completed ? "ProgramOverview-Button" : "ProgramOverview-Button-Complete"}
                                                            >
                                                            {this.state.Exercises[exer?.ExerciseID]?.Title}
                                                            </Button>
                                                            </Link>
                                                        </div>
                                                        )
                                                    })
                                                }
                                            </Col>
                                        )
                                    })
                                }
                                <Col xs={24}>
                                    <h3 className="Customer-Fitness-Section-Title">Notes</h3>
                                    <Input.TextArea autoSize={{ minRows: 4 }} value={this.state.Notes} onChange={(e) => this.handleChange("Notes", e.target.value)} />
                                    <p className="ProgramOverview-Input-Subtext">Add notes specific to todays workout.</p>
                                </Col>
                                <Col xs={24} sm={12} md={6}>
                                    <Button type="primary" block
                                    style={{
                                        backgroundColor: data.websiteTemplate?.ColourPalette?.DARK,
                                        borderColor: data.websiteTemplate?.ColourPalette?.DARK
                                    }}
                                    className="ProgramOverview-Button"
                                    onClick={() => this.saveProgramInput()}
                                    >
                                        Save Notes
                                    </Button>
                                </Col>
                                <Col xs={24} sm={12} md={6}>
                                    {
                                        this.state.Completed ?
                                        <Button type="primary" block
                                        icon={<CheckCircleFilled />}
                                        className="ProgramOverview-Button-Complete"
                                        onClick={() => this.completeDay()}
                                        >
                                            Uncheck Workout
                                        </Button>
                                        :
                                        <Button type="primary" block
                                        style={{
                                            backgroundColor: data.websiteTemplate?.ColourPalette?.DARK,
                                            borderColor: data.websiteTemplate?.ColourPalette?.DARK
                                        }}
                                        className="ProgramOverview-Button"
                                        onClick={() => this.completeDay()}
                                        >
                                            Complete Workout
                                        </Button>
                                    }
                                </Col>
                            </Row>
                            </Col>
                        </Row>
                    }
                </div>
            )
        }
        }
    </DataContext.Consumer>
    )
    }
}

export default ProgramWorkout;