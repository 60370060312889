import React from 'react';
import './CarouselCard.css';
import { Button } from 'antd';

class CarouselCard extends React.Component {
    render() {
        return (
            <div className="CarouselCard-Phros-Div">
                <img src={this.props.src} alt={this.props.alt} className="CarouselCard-Image" />
                <div className="CarouselCard-Text">
                <h3 className="CarouselCard-Title">{this.props.title}</h3>
                <h4>{this.props.secondaryTitle}</h4>
                </div>
                {
                    this.props.Selected && !this.props.Added ?
                    <Button
                    onClick={this.props.handleRemoveClick}
                    type="primary" className="CarouselCard-Button" block danger>Remove</Button>
                    : !this.props.Selected && !this.props.Added ?
                    <Button type="primary" className="CarouselCard-Button" block
                    onClick={this.props.handleAddClick}>
                        Add to Membership
                    </Button>
                    : this.props.Added ?
                    <Button type="primary" className="CarouselCard-Button" block disabled>Already Added to Membership</Button>
                    : null
                }
            </div>
        )
    }
}

export default CarouselCard;