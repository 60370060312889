import React from 'react';
import { Row, Col, Divider, Select, Button, message, notification, Input, Tag } from 'antd';
import { Navbar, Authentication } from '../../Components';
import { connect } from 'react-redux';
import { H2, H3, P, PhrosButton } from '../../Common';
import './Checkout.css';
import { ENVIRONMENT, UID } from '../../constants';
import { DataContext } from '../../Context/Data';
import { AuthContext } from '../../Context/Auth';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { removeOnDemandVid, removeLiveClass, removeBootcamp, removeProgram } from '../../Redux/actions';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { Redirect } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { LoadingOutlined } from '@ant-design/icons';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);


class CheckoutCard extends React.Component {
    render() {
        return (
            <DataContext.Consumer>
            {
            data => (
            <div>
                <Row gutter={[20,20]}>
                    <Col xs={24} sm={6}>
                        <img src={this.props.image} alt={this.props.title} className='CheckoutCard-Image' />
                    </Col>
                    <Col xs={24} sm={12}>
                        <H3 text={this.props.title} />
                        <P text={this.props.description} />
                        <Row justify='start'>
                            <Col xs={24}>
                            <a href={this.props.waiver} target="_blank" rel="noreferrer">
                            Terms & Conditions (Waiver)
                            </a>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={6} style={{ textAlign: 'right' }}>
                        {
                            this.props.view === "CAD" && this.props.currency === "CAD" ?
                            <H2 text={`$${(this.props.price).toFixed(2)} CAD`} />
                            :
                            this.props.view === "CAD" && this.props.currency === "USD" ?
                            <H2 text={`$${(this.props.price*(1/this.props.usdConversion)).toFixed(2)} CAD`} />
                            :
                            this.props.view === "USD" && this.props.currency === "USD" ?
                            <H2 text={`$${this.props.price} USD`} />
                            :
                            this.props.view === "USD" && this.props.currency === "CAD" ?
                            <H2 text={`$${(this.props.price*this.props.usdConversion).toFixed(2)} USD`} />
                            :
                            this.props.price === 0 ?
                            <H2 text='Free' />
                            :
                            null
                        }
                        <Row>
                            <Col xs={24} style={{ textAlign: 'right' }}>
                            <Button
                            onClick={() => this.props.removeItem(this.props.id)}
                            type='link'>
                                Remove Item
                            </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Divider style={{ backgroundColor: data.websiteTemplate?.ColourPalette?.LIGHT }} />
            </div>
                )
            }
        </DataContext.Consumer>
        )
    }
}

class TotalCost extends React.Component {
    state = {
        USD: 1,
        CAD: 1,
        VIEW: 'CAD',
    }

    render() {
        // Get Original Payment
        const allItems = [...this.props.OnDemandVideos, ...this.props.LiveClasses, ...this.props.Bootcamps, ...this.props.Programs];
        const completePayment = allItems.reduce((payments, item) => {
            if (item.liveClass) {
                item = item.liveClass
            }
            if (item.Currency === "CAD") {
                payments[0] = payments[0] + item.Price;
                return payments;
            } else if (item.Currency === "USD") {
                payments[1] = payments[1] + item.Price;
                return payments;
            } else {
                return payments;
            }
        }, [0, 0]);

        // Get the payment before discount
        const cadBefore = completePayment[0];
        const usdBefore = completePayment[1];
        const totalCad = (cadBefore+usdBefore*(1/this.props.usdConversion)).toFixed(2);
        const totalUSD = (cadBefore*this.props.usdConversion+usdBefore).toFixed(2);

        return (
            <div>
            {
                this.props.OnDemandVideos.map((video) => {
                    return (
                        <div>
                        <Row justify='space-between' style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <Col sm={18}><P text={video.Title}/></Col>
                            <Col xs={6} style={{ textAlign: 'right' }}>
                            {
                            this.props.view === "CAD" && video.Currency === "CAD" ?
                            <P text={`$${(video.Price).toFixed(2)} CAD`} />
                            :
                            this.props.view === "CAD" && video.Currency === "USD" ?
                            <P text={`$${(video.Price*(1/this.props.usdConversion)).toFixed(2)} CAD`} />
                            :
                            this.props.view === "USD" && video.Currency === "USD" ?
                            <P text={`$${video.Price} USD`} />
                            :
                            this.props.view === "USD" && video.Currency === "CAD" ?
                            <P text={`$${(video.Price*this.props.usdConversion).toFixed(2)} USD`} />
                            :
                            video.Price === 0 ?
                            <P text="Free" />
                            :
                            null
                        }
                            </Col>
                        </Row>
                        </div>
                    )
                })
            }
            {
                this.props.LiveClasses.map((data) => {
                    return (
                    <Row justify='space-between' style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <Col xs={18}><P text={data.liveClass.Name} /></Col>
                        <Col xs={6} style={{ textAlign: 'right' }}>
                        {
                            this.props.view === "CAD" && data.liveClass.Currency === "CAD" ?
                            <P text={`$${(data.liveClass.Price).toFixed(2)} CAD`} />
                            :
                            this.props.view === "CAD" && data.liveClass.Currency === "USD" ?
                            <P text={`$${(data.liveClass.Price*(1/this.props.usdConversion)).toFixed(2)} CAD`} />
                            :
                            this.props.view === "USD" && data.liveClass.Currency === "USD" ?
                            <P text={`$${data.liveClass.Price} USD`} />
                            :
                            this.props.view === "USD" && data.liveClass.Currency === "CAD" ?
                            <P text={`$${(data.liveClass.Price*this.props.usdConversion).toFixed(2)} USD`} />
                            :
                            data.liveClass.Price === 0 ?
                            <P text="Free" />
                            :
                            null
                        }
                        </Col>
                    </Row>
                    );
                })
            }
            {
                this.props.Bootcamps.map((boot) => {
                    return (
                    <Row justify='space-between' style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <Col sm={18}><P text={boot.Title} /></Col>
                        <Col xs={6} style={{ textAlign: 'right' }}>
                        {
                            this.props.view === "CAD" && boot.Currency === "CAD" ?
                            <P text={`$${(boot.Price).toFixed(2)} CAD`} />
                            :
                            this.props.view === "CAD" && boot.Currency === "USD" ?
                            <P text={`$${(boot.Price*(1/this.props.usdConversion)).toFixed(2)} CAD`} />
                            :
                            this.props.view === "USD" && boot.Currency === "USD" ?
                            <P text={`$${boot.Price} USD`} />
                            :
                            this.props.view === "USD" && boot.Currency === "CAD" ?
                            <P text={`$${(boot.Price*this.props.usdConversion).toFixed(2)} USD`} />
                            :
                            boot.Price === 0 ?
                            <P text="Free" />
                            :
                            null
                        }
                        </Col>
                    </Row>
                    );
                })
            }
            {
                this.props.Programs.map((prog) => {
                    return (
                    <Row justify='space-between' style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <Col xs={18}><P text={prog.Title} /></Col>
                        <Col xs={6} style={{ textAlign: 'right' }}>
                        {
                            this.props.view === "CAD" && prog.Currency === "CAD" ?
                            <P text={`$${(prog.Price).toFixed(2)} CAD`} />
                            :
                            this.props.view === "CAD" && prog.Currency === "USD" ?
                            <P text={`$${(prog.Price*(1/this.props.usdConversion)).toFixed(2)} CAD`} />
                            :
                            this.props.view === "USD" && prog.Currency === "USD" ?
                            <P text={`$${prog.Price} USD`} />
                            :
                            this.props.view === "USD" && prog.Currency === "CAD" ?
                            <P text={`$${(prog.Price*this.props.usdConversion).toFixed(2)} USD`} />
                            :
                            prog.Price === 0 ?
                            <P text="Free" />
                            :
                            null
                        }
                        </Col>
                    </Row>
                    );
                })
            }
            <Divider />

            <Row justify='space-between'>
                <Col><H2 text="Total: "/></Col>
                {
                    this.props.view === 'CAD' ?
                    <Col><H2 text={`$${totalCad} CAD`} /></Col>
                    :
                    this.props.view === 'USD' ?
                    <Col><H2 text={`$${totalUSD} USD`} /></Col>
                    :
                    null
                }
            </Row>
            </div>
        )
    }
}

class Checkout extends React.Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.state = {
            VIEW: "CAD",
            USD: 1,
            open: false,
            terms: false,
            parq_one: false,
            parq_two: false,
            PARQ: false,
            disabledPurchase: false,
            Redirect: false,
            PromoCode: "",
            PromoLoading: false,
            ActivatedPromo: null,
            PromoTag: null,
            Promo: null,
        }
    }

    componentDidMount() {
        this.getConversion();

        /*
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        */
    }

    getConversion = async () => {
        const response = await axios.get("https://v6.exchangerate-api.com/v6/d59097117aaaab512c0f689e/latest/USD")
        const CAD = response.data.conversion_rates?.CAD;
        const USD = 1/CAD;
        this.setState({
            USD: USD,
        })
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value,
        })
    }

    getLocalTimeString(time) {
        let dateTime = new Date(time);
        dateTime = dateTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
        return dateTime;
    }

    changeModalVisibility = () => {
        this.setState({
            open: !this.state.open,
        });
    }

    handleCheckout = () => {
        this.changeModalVisibility();
    }

    handlePayment = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        if (currentUser) {
            if (this.state.terms === false) {
                return notification.error({
                    message: "Error",
                    description: "Must agree to the terms and conditions before payment."
                })
            }
            if (this.state.PARQ) {
                if (!(this.state.parq_one || this.state.parq_two)) {
                    return notification.error({
                        message: "Error",
                        description: "You must acknowledge the PAR-Q form questions before proceeding to checkout."
                    })
                }
            }
            this.setState({
                disabledPurchase: false
            })
            notification.info({
                message: 'Creating Checkout Session...',
                description: 'Creating your checkout session, please wait a moment!'
            })
            const payload = {
                uid: UID,
                liveClasses: this.props.LiveClasses,
                onDemandClasses: this.props.OnDemandVideos,
                bootcamps: this.props.Bootcamps,
                programs: this.props.Programs,
                CouponID: this.state.ActivatedPromo,
            };
            const response = await axios.post(`${ENVIRONMENT.BACKEND_URL}/stripe/create-checkout-session-for-cart`, payload, config);

            if (response.data.error) {
                return message.error('Failed to checkout, please contact me!');
            } else if (response.data.successfulBooking) {
                this.setState({
                    Redirect: true,
                });
                return true;
            }

            const stripe = await stripePromise;
            const result = await stripe.redirectToCheckout({
                sessionId: response.data.id,
            });
            if (result.error) {
                return message.error(result.error.message);
            }

        } else {
            this.changeModalVisibility();
        }
    }

    handlePromoCode = async () => {
        this.setState({
            PromoLoading: true,
        });
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const payload = {
            uid: UID,
            code: this.state.PromoCode,
        };
        const response = await axios.post(`${ENVIRONMENT.BACKEND_URL}/coupons/apply-coupon`, payload, config);
        if (response.data.error) {
            notification.error({
                message: 'Error',
                description: "Promo code is not valid."
            });
        } else {
            const promo = response.data;
            let couponCheck = true;
            const allItems = [...this.props.OnDemandVideos, ...this.props.LiveClasses, ...this.props.Bootcamps, ...this.props.Programs];

            // Check if the items in cart apply to this product
            const applied_products = response.data.applied_products || [];
            const applicable_products = applied_products.reduce((obj, id) => {
                return {
                    ...obj,
                    [id]: true
                }
            }, {});
            if (applied_products.length > 0) {
                const checkForItem = allItems.reduce((itemCheck, item) => {
                    const itemID = item.ID || item.liveClass.ID;
                    if (itemID in applicable_products) {
                        return true;
                    }
                    return itemCheck;
                }, false);
                if (!checkForItem) {
                    couponCheck = false;
                    notification.error({
                        message: "Invalid Coupon",
                        description: "The item(s) this coupon applies to are not in your cart."
                    })
                }
            }


            // Check for the number of times the coupons been used
            const maxRedeem = promo.coupon?.max_redemptions || null;
            const timesRedeemed = promo.coupon?.times_redeemed || null;

            if (maxRedeem && timesRedeemed && timesRedeemed >= maxRedeem) {
                couponCheck = false;
                notification.error({
                    message: "Invalid Coupon",
                    description: "Coupon has already reached it's maximum use."
                })
            }

            // Check for the Expiry Date
            const today = Math.ceil(new Date().getTime()/1000);
            const redeem_by = promo.coupon?.redeem_by || null;
            if (redeem_by && today > redeem_by) {
                couponCheck = false;
                notification.error({
                    message: "Invalid Coupon",
                    description: "Coupon is expired."
                })
            }

            // Check for the Min Order Value
            const minAmount = (promo.restrictions?.minimum_amount)/100 || null;
            const minAmountCurrency = promo.restrictions?.minimum_amount_currency || null;
            const completePayment = allItems.reduce((payments, item) => {
                if (item.liveClass) {
                    item = item.liveClass
                }
                if (item.Currency === "CAD") {
                    payments[0] = payments[0] + item.Price;
                    return payments;
                } else if (item.Currency === "USD") {
                    payments[1] = payments[1] + item.Price;
                    return payments;
                } else {
                    return payments;
                }
            }, [0, 0]);
            const cadBefore = completePayment[0];
            const usdBefore = completePayment[1];
            const totalCad = (cadBefore+usdBefore*(1/this.state.USD)).toFixed(2);
            const totalUSD = (cadBefore*this.state.USD+usdBefore).toFixed(2);

            if (minAmountCurrency === "usd") {
                if (totalUSD < minAmount) {
                    couponCheck = false;
                    notification.error({
                        message: "Invalid Coupon",
                        description: `Coupon has a minimum order value of $${minAmount} USD.`,
                    });
                }
            } else if (minAmountCurrency === "cad") {
                if (totalCad < minAmount) {
                    couponCheck = false;
                    notification.error({
                        message: "Invalid Coupon",
                        description: `Coupon has a minimum order value of $${minAmount} CAD.`
                    })
                }
            }

            if (couponCheck) {
                notification.success({
                    message: "Success",
                    description: "Successfully applied the promo code. The discount will be shown at checkout."
                });
                this.setState({
                    Promo: response.data,
                    ActivatedPromo: this.state.PromoCode,
                    PromoTag: this.state.PromoCode,
                })
            }
        }
        this.setState({
            PromoLoading: false,
        })
    }

    render() {
        const { currentUser } = this.context;
        return (
        <DataContext.Consumer>
            {
            data => (
            <div>
                {
                    this.state.Redirect ?
                    <Redirect to="/free-booking" />
                    :
                    null
                }
                <Authentication open={this.state.open} handleModalChange={this.changeModalVisibility} />
                <Helmet>
                <title>{data.profile.BusinessName ? data.profile.BusinessName : 'My Personal Training Website'}</title>
                <meta name="description" content={data.profile.BusinessStatement} />
                <link rel="icon" type="image/png" href={data.profile.LogoURL} />
                </Helmet>
                <Navbar />
                <Row justify='center' style={{ marginTop: "20px" }}>
                <Col xs={23} sm={22}>
                <H2 text="Your Fitness Package" />
                <Row justify='space-around' gutter={[20,20]}>
                    <Col xs={24} sm={16}>
                        {
                            this.props.OnDemandVideos.map((video) => {
                                return (
                                    <CheckoutCard
                                        image = {video.ThumbnailURL}
                                        title = {video.Title}
                                        description = {video.Description}
                                        price = {video.Price}
                                        currency = {video.Currency}
                                        view = {this.state.VIEW}
                                        usdConversion = {this.state.USD}
                                        id = {video.ID}
                                        waiver = {video.WaiverURL}
                                        removeItem={this.props.removeOnDemandVid}
                                    />
                                )
                            })
                        }
                        {
                            this.props.LiveClasses.map((data) => {
                                return (
                                    <CheckoutCard
                                        image = {data.liveClass.PictureURL}
                                        title = {data.liveClass.Name}
                                        description = {`${new Date(data.session.Date).toLocaleDateString()} ${this.getLocalTimeString(data.session.StartTime)} - ${this.getLocalTimeString(data.session.EndTime)}; ${data.liveClass.Description}`}
                                        price = {data.liveClass.Price}
                                        currency = {data.liveClass.Currency}
                                        view = {this.state.VIEW}
                                        usdConversion = {this.state.USD}
                                        id = {data.liveClass.ID}
                                        waiver = {data.liveClass.WaiverURL}
                                        removeItem = {this.props.removeLiveClass}
                                    />
                                )
                            })
                        }
                        {
                            this.props.Bootcamps.map((boot) => {
                                return (
                                    <CheckoutCard
                                        image = {boot.PictureURL}
                                        title = {boot.Title}
                                        description = {boot.Description}
                                        price = {boot.Price}
                                        currency = {boot.Currency}
                                        view = {this.state.VIEW}
                                        usdConversion = {this.state.USD}
                                        id = {boot.ID}
                                        waiver = {boot.WaiverURL}
                                        removeItem = {this.props.removeBootcamp}
                                    />
                                )
                            })
                        }
                        {
                            this.props.Programs.map((prog) => {
                                return (
                                    <CheckoutCard
                                        image = {prog.PictureURL}
                                        title = {prog.Title}
                                        description = {prog.Description}
                                        price = {prog.Price}
                                        currency = {prog.Currency}
                                        view = {this.state.VIEW}
                                        usdConversion = {this.state.USD}
                                        id = {prog.ID}
                                        waiver = {prog.WaiverURL}
                                        removeItem = {this.props.removeProgram}
                                    />
                                )
                            })
                        }
                    </Col>
                    <Col xs={24} sm={8}>
                        <div className="Checkout-Total-Cost">
                            <H2 text={data.profile.BusinessName} />
                            <H3 text={`${data.profile.FirstName} ${data.profile.LastName}`} />
                            <Row justify='space-between' className="Checkout-Row">
                                <Col>
                                    Currency:
                                </Col>
                                <Col>
                                <Select value={this.state.VIEW} onChange={(value) => this.handleChange('VIEW', value)}>
                                    <Select.Option key="CAD">CAD</Select.Option>
                                    <Select.Option key="USD">USD</Select.Option>
                                </Select>
                                </Col>
                            </Row>
                            <TotalCost
                                OnDemandVideos = {this.props.OnDemandVideos}
                                LiveClasses = {this.props.LiveClasses}
                                Bootcamps = {this.props.Bootcamps}
                                Programs = {this.props.Programs}
                                view = {this.state.VIEW}
                                usdConversion = {this.state.USD}
                                USD = {this.state.USD}
                            />
                            {
                                this.state.Promo ?
                                <Row className="Checkout-Row">
                                    <Col xs={24}>
                                        {
                                            this.state.Promo?.coupon?.amount_off ?
                                            <p className="Checkout-Promo-Applied-Products">A discount of ${this.state.Promo.coupon.amount_off/100} {String(this.state.Promo.coupon.currency).toUpperCase()} will be applied at the checkout page.</p>
                                            : this.state.Promo?.coupon?.percent_off ?
                                            <p className="Checkout-Promo-Applied-Products">A discount of {this.state.Promo.coupon.percent_off}% will be applied at the checkout page.</p>
                                            : null
                                        }
                                    </Col>
                                    <Col xs={24}>
                                        {
                                            this.state.Promo?.applied_products?.length > 0 ?
                                            <p className="Checkout-Promo-Applied-Products">
                                                This discount only applies to the following products:
                                                <ul>
                                                {
                                                    this.state.Promo?.applied_products?.map((id) => (
                                                        <li>{this.state.Promo?.products[id]}</li>
                                                    ))
                                                }
                                                </ul>
                                            </p>
                                            : null
                                        }
                                    </Col>
                                </Row>
                                :
                                null
                            }
                            {
                                currentUser ?
                                <Row className="Checkout-Row" justify="space-between" gutter={[10,10]}>
                                    <Col xs={18}>
                                        <Input value={this.state.PromoCode} onChange={(e) => this.handleChange("PromoCode", e.target.value)} className="Checkout-Input" style={{ width: "100%" }} placeholder="promo code" />
                                    </Col>
                                    <Col xs={6}>
                                        {
                                            this.state.PromoLoading ?
                                            <Button className="Checkout-Button" block icon={<LoadingOutlined />} />
                                            :
                                            <Button className="Checkout-Button" block onClick={() => this.handlePromoCode()}>Apply</Button>
                                        }
                                    </Col>
                                    {
                                        this.state.PromoTag ?
                                        <Col xs={24}>
                                            <Tag color="blue">{this.state.PromoTag}</Tag>
                                        </Col>
                                        :
                                        null
                                    }

                                </Row>
                                :
                                null
                            }

                            {
                                currentUser ?
                                <div>
                                    <div style={{ padding: '4px' }}>
                                    <Checkbox value={this.state.terms} onChange={(e) => this.handleChange('terms', e.target.checked)}>I agree to the terms and conditions as stated in each waiver form for the purchased items.</Checkbox>
                                    </div>
                                    <div style={{ padding: '4px' }}>
                                    {
                                        data.profile?.PARQForm ?
                                        <div>
                                        <a target="_blank" rel="noreferrer" href="https://firebasestorage.googleapis.com/v0/b/phros-dev-ddfdb.appspot.com/o/pt-assets%2FGeneral%20PAR-Q.pdf?alt=media&token=e744e349-1e25-459b-9ae3-64bd9c0b9580">View PAR-Q Form</a>
                                        <p>Please select one of the following below to proceed with checkout:</p>
                                        </div>
                                        :
                                        null
                                    }
                                    {
                                        data.profile?.PARQForm ?
                                        <Checkbox value={this.state.parq_one} onChange={(e) => this.handleChange('parq_one', e.target.checked)}>I have honestly answered no to all Par-Q questions, in the presence of a witness if I am 18yrs and older or in the presence of a parent/guardian if I am 18yrs and younger.</Checkbox>
                                        :
                                        null
                                    }
                                    </div>
                                    <div style={{ padding: '4px' }}>
                                    {
                                        data.profile?.PARQForm ?
                                        <Checkbox value={this.state.parq_two} onChange={(e) => this.handleChange('parq_two', e.target.checked)}>I have answered yes to one or more questions and have contacted my doctor to discuss which questions I have answered yes to, before starting this program.</Checkbox>
                                        :
                                        null
                                    }
                                    </div>
                                    <PhrosButton disabled={this.state.disabledPurchase} onClick={() => {
                                        this.handleChange('PARQ', data.profile.PARQForm);
                                        this.handlePayment();
                                    }} text='Checkout' styles={{ marginTop: '15px', background: data.websiteTemplate?.ColourPalette?.DARK }} />
                                    <p className="Checkout-Not-Charged-Yet">You won't be charged yet</p>
                                </div>
                                :
                                <PhrosButton onClick={() => {
                                    this.handleChange('PARQ', data.profile.PARQForm);
                                    this.handleCheckout();
                                }} text='Checkout' styles={{ background: data.websiteTemplate?.ColourPalette?.DARK }} />
                            }
                        </div>
                    </Col>
                </Row>
                </Col>
                </Row>
            </div>
                )
            }
        </DataContext.Consumer>
        )
    }
}

const mapStateToProps = state => ({
    OnDemandVideos: state.OnDemandVideos,
    LiveClasses: state.LiveClasses,
    Bootcamps: state.Bootcamps,
    Programs: state.Programs,
})

const mapDispatchToProps = dispatch => ({
    removeOnDemandVid: id => dispatch(removeOnDemandVid(id)),
    removeLiveClass: id => dispatch(removeLiveClass(id)),
    removeBootcamp: id => dispatch(removeBootcamp(id)),
    removeProgram: id => dispatch(removeProgram(id)),
})


export default connect(mapStateToProps, mapDispatchToProps)(Checkout);