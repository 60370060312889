import React from 'react';
import './AboutMe.css';
import { Row, Col } from 'antd';
import DOMPurify from 'dompurify';
import { H2 } from '../../Common';
import { COLOUR_PALETTE } from '../../constants';

class AboutMe extends React.Component {
    render() {
        return (
            <div>
            <Row justify='center'>
            <Col xs={23} sm={22} className="AboutMe-Div">
                <Row justify='space-between' align="middle">
                    <Col xs={24} sm={11}>
                        <Row>
                        <Col>
                        <H2 style={{ background: `linear-gradient(to top, ${COLOUR_PALETTE[this.props.profile.ColourScheme].MEDIUM} 30%, transparent 30%)` }} text="about me"/>
                        </Col>
                        </Row>
                        <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.props.profile?.AboutMe)}} />
                    </Col>
                    <Col xs={24} sm={11} style={{ height: '100%' }}>
                        <img className="AboutMe-Image" src={this.props.profile?.AboutMePictureURL} alt={this.props.profile?.BusinessName} />
                    </Col>
                </Row>
            </Col>
            </Row>
            </div>
        )
    }
}

export default AboutMe;