import React from 'react';
import './typography.css';
import { Link } from 'react-router-dom'
import { H1, H2, H3, P} from '../index';

class LinkText extends React.Component {
    render() {
        let style = {};
        if (this.props.style) {
            style = {...this.props.style};
        }
        return (
            <Link to={this.props.link}>
                {
                    this.props.H1 ?
                    <H1 style={style} text={this.props.text} />
                    : this.props.H2 ?
                    <H2 style={style} text={this.props.text} />
                    : this.props.H3 ?
                    <H3 style={style} text={this.props.text} />
                    : this.props.P ?
                    <P style={style} text={this.props.text} />
                    :
                    null
                }
            </Link>
        )
    }
}

export default LinkText;