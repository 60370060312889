import React from 'react';
import './Memberships.css';
import { Navbar, ClassesHeader, Authentication } from '../../Components';
import { PhrosButton } from '../../Common';
import { Helmet } from 'react-helmet';
import { DataContext } from '../../Context/Data';
import { AuthContext } from '../../Context/Auth';
import { RESOURCE_CAPACITY, ENVIRONMENT, UID } from '../../constants';
import { checkArray } from '../../ValidateInput';
import { Modal, Checkbox, Input, Button, Tag, Row, Col, notification, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

class MembershipCard extends React.Component {
    render() {
        const liveClasses = this.props.Membership?.SelectedResources?.LiveClasses?.Limit;
        const unlimitedLiveClasses = this.props.Membership?.SelectedResources?.LiveClasses?.Capacity === RESOURCE_CAPACITY.UNLIMITED.value;
        const subscriptionInterval = this.props.Membership?.RecurringInterval;
        const onDemandClasses = this.props.Membership?.SelectedResources?.OnDemandClasses.Limit;
        const unlimitedOnDemandClasses = this.props.Membership?.SelectedResources?.OnDemandClasses?.Capacity === RESOURCE_CAPACITY.UNLIMITED.value;
        const workoutPrograms = this.props.Membership?.SelectedResources?.TrainingPrograms?.Limit;
        const unlimitedPrograms = this.props.Membership?.SelectedResources?.TrainingPrograms?.Capacity === RESOURCE_CAPACITY.UNLIMITED.value;
        const description = this.props.Membership?.Description;
        return (
            <div className={`${!this.props.forModal ? "Membership-Div" : null}`}>
                {
                    this.props.Membership?.ImageURL ?
                    <div>
                        <img className="Membership-Card-Image" src={this.props.Membership.ImageURL} alt="Membership Card"/>
                    </div>
                    :
                    null
                }
                <div className="Membership-Text-Body">
                <h3 className="Membership-Card-Name">{this.props.Membership?.Name}</h3>
                <h4 className="Membership-Card-Price">${this.props.Membership?.Price} <span className="Membership-Card-Span">{String(this.props.Membership?.Currency).toUpperCase()} per {subscriptionInterval}</span></h4>
                <h4 className="Membership-Card-Included">What's Included?</h4>
                <ul className="Membership-Card-List">
                    {
                        this.props.profile?.PLAN?.FEATURES?.LIVECLASSES ?
                        <li className="Membership-Card-List-Item">{unlimitedLiveClasses ? "Unlimited Live Classes" : `${liveClasses} Live Classes per ${subscriptionInterval}`}</li>
                        :
                        null
                    }
                    {
                        this.props.profile?.PLAN?.FEATURES?.ONDEMAND ?
                        <li className="Membership-Card-List-Item">{unlimitedOnDemandClasses ? "Unlimited On Demand Classes" : `${onDemandClasses} On Demand Classes per ${subscriptionInterval}`}</li>
                        :
                        null
                    }
                    {
                        this.props.profile?.PLAN?.FEATURES?.TRAININGPROGRAM ?
                        <li className="Membership-Card-List-Item">{unlimitedPrograms ? "Unlimited Workout Programs" : `${workoutPrograms} Workout Programs per ${subscriptionInterval}`}</li>
                        :
                        null
                    }
                </ul>
                {
                    this.props.forModal ?
                    <p className="Memberships-Description">{description}</p>
                    :
                    null
                }
                <div>
                    {
                        this.props.forModal ?
                        null
                        :
                        <PhrosButton text="View More" onClick={() => {
                            this.props.handleMembershipViewMore(this.props.Membership);
                        }} styles={{ background: this.props.ColourPalette.DARK }} />
                    }
                </div>
                </div>
            </div>
        )
    }
}

class Memberships extends React.Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.state = {
            Membership: null,
            ModalVisible: false,
            AuthModal: false,
            terms: false,
            parq_one: false,
            parq_two: false,
            PromoCode: "",
            PromoLoading: false,
            ActivatedPromo: null,
            PromoTag: null,
            Promo: null,
        };
        this.handleMembershipViewMore = this.handleMembershipViewMore.bind(this);
        this.handleModalVisible = this.handleModalVisible.bind(this);
        this.handleAuthModalVisibility = this.handleAuthModalVisibility.bind(this);
    }
  componentDidMount() {
    window.scrollTo(0,0);
  }

  handleMembershipViewMore = (memb) => {
    this.setState({
        Membership: memb
    });
    this.handleModalVisible();
  }

  handleModalVisible = () => {
      this.setState({
          ModalVisible: !this.state.ModalVisible,
      })
  }

  handleAuthModalVisibility = () => {
      this.setState({
          AuthModal: !this.state.AuthModal,
      })
  }

  handleChange = (name, value) => {
      this.setState({
          [name]: value
      })
  }

  handlePayment = async () => {
      const { currentUser } = this.context;
      const token = await currentUser.getIdToken(true);
      const config = {
          headers: {
              Authorization: `Bearer ${token}`
          }
      };
      if (currentUser) {
          if (this.state.terms === false) {
              return notification.error({
                  message: "Error",
                  description: "Must agree to the terms and conditions before payment."
              })
          }
          if (this.state.PARQ) {
              if (!(this.state.parq_one || this.state.parq_two)) {
                  return notification.error({
                      message: "Error",
                      description: "You must acknowledge the PAR-Q form questions before proceeding to checkout."
                  })
              }
          }
          this.setState({
              disabledPurchase: false
          })
          notification.info({
              message: 'Creating Checkout Session...',
              description: 'Creating your checkout session, please wait a moment!'
          })
          const payload = {
              UID: UID,
              MembershipID: this.state.Membership.ID,
          };
          const response = await axios.post(`${ENVIRONMENT.BACKEND_URL}/stripe/create-membership-checkout-session`, payload, config);

          if (response.data.error) {
              return message.error('Failed to checkout, please contact me!');
          } else if (response.data.successfulBooking) {
              this.setState({
                  Redirect: true,
              });
              return true;
          }

          const stripe = await stripePromise;
          const result = await stripe.redirectToCheckout({
              sessionId: response.data.id,
          });
          if (result.error) {
              return message.error(result.error.message);
          }

      } else {
          this.handleAuthModalVisibility();
      }
  }

  handlePromoCode = async () => {
    this.setState({
        PromoLoading: true,
    });
    const { currentUser } = this.context;
    const token = await currentUser.getIdToken(true);
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
    const payload = {
        uid: UID,
        code: this.state.PromoCode,
    };
    const response = await axios.post(`${ENVIRONMENT.BACKEND_URL}/coupons/apply-coupon`, payload, config);
    if (response.data.error) {
        notification.error({
            message: 'Error',
            description: "Promo code is not valid."
        });
    } else {
        const promo = response.data;
        let couponCheck = true;
        const allItems = [...this.props.OnDemandVideos, ...this.props.LiveClasses, ...this.props.Bootcamps, ...this.props.Programs];

        // Check if the items in cart apply to this product
        const applied_products = response.data.applied_products || [];
        const applicable_products = applied_products.reduce((obj, id) => {
            return {
                ...obj,
                [id]: true
            }
        }, {});
        if (applied_products.length > 0) {
            const checkForItem = allItems.reduce((itemCheck, item) => {
                const itemID = item.ID || item.liveClass.ID;
                if (itemID in applicable_products) {
                    return true;
                }
                return itemCheck;
            }, false);
            if (!checkForItem) {
                couponCheck = false;
                notification.error({
                    message: "Invalid Coupon",
                    description: "The item(s) this coupon applies to are not in your cart."
                })
            }
        }


        // Check for the number of times the coupons been used
        const maxRedeem = promo.coupon?.max_redemptions || null;
        const timesRedeemed = promo.coupon?.times_redeemed || null;

        if (maxRedeem && timesRedeemed && timesRedeemed >= maxRedeem) {
            couponCheck = false;
            notification.error({
                message: "Invalid Coupon",
                description: "Coupon has already reached it's maximum use."
            })
        }

        // Check for the Expiry Date
        const today = Math.ceil(new Date().getTime()/1000);
        const redeem_by = promo.coupon?.redeem_by || null;
        if (redeem_by && today > redeem_by) {
            couponCheck = false;
            notification.error({
                message: "Invalid Coupon",
                description: "Coupon is expired."
            })
        }

        // Check for the Min Order Value
        const minAmount = promo.restrictions?.minimum_amount || null;
        const minAmountCurrency = promo.restrictions?.minimum_amount_currency || null;
        const completePayment = allItems.reduce((payments, item) => {
            if (item.liveClass) {
                item = item.liveClass
            }
            if (item.Currency === "CAD") {
                payments[0] = payments[0] + item.Price;
                return payments;
            } else if (item.Currency === "USD") {
                payments[1] = payments[1] + item.Price;
                return payments;
            } else {
                return payments;
            }
        }, [0, 0]);
        const cadBefore = completePayment[0];
        const usdBefore = completePayment[1];
        const totalCad = (cadBefore+usdBefore*(1/this.state.USD)).toFixed(2);
        const totalUSD = (cadBefore*this.state.USD+usdBefore).toFixed(2);

        if (minAmountCurrency === "usd") {
            if (totalUSD < minAmount) {
                couponCheck = false;
                notification.error({
                    message: "Invalid Coupon",
                    description: `Coupon has a minimum order value of ${minAmount} USD.`,
                });
            }
        } else if (minAmountCurrency === "cad") {
            if (totalCad < minAmount) {
                couponCheck = false;
                notification.error({
                    message: "Invalid Coupon",
                    description: `Coupon has a minimum order value of ${minAmount} CAD.`
                })
            }
        }

        if (couponCheck) {
            notification.success({
                message: "Success",
                description: "Successfully applied the promo code. The discount will be shown at checkout."
            });
            this.setState({
                Promo: response.data,
                ActivatedPromo: this.state.PromoCode,
                PromoTag: this.state.PromoCode,
            })
        }
    }
    this.setState({
        PromoLoading: false,
    })
}

  render() {
    const { currentUser } = this.context;
    return (
      <DataContext.Consumer>
        {
          data => (
            <div>
            <Helmet>
              <title>{data.profile.BusinessName ? data.profile.BusinessName : 'My Personal Training Website'}</title>
              <meta name="description" content={data.profile.BusinessStatement} />
              <link rel="icon" type="image/png" href={data.profile.LogoURL} />
            </Helmet>
            <Navbar />
            <ClassesHeader profile={data.profile} websiteTemplate={data.websiteTemplate} />
            <Row justify="center">
            <Col xs={23} sm={22} className="Membership-Outer-Col">
                <Row gutter={[30,30]}>
                    {
                        checkArray(data.memberships).map((memb, index) => (
                            <Col xs={24} sm={8}>
                                <MembershipCard ColourPalette={data?.websiteTemplate?.ColourPalette} handleMembershipViewMore={this.handleMembershipViewMore} Membership={memb} profile={data.profile} forModal={false} />
                            </Col>
                        ))
                    }
                </Row>
            </Col>
            </Row>
            <Authentication open={this.state.AuthModal} handleModalChange={this.handleAuthModalVisibility} />
            <Modal
            closable
            visible={this.state.ModalVisible}
            footer={null}
            onCancel={this.handleModalVisible}>
                <MembershipCard ColourPalette={data?.websiteTemplate?.ColourPalette} Membership={this.state.Membership} profile={data.profile} forModal={true} />
                {
                    this.state.Promo ?
                    <Row className="Checkout-Row">
                        <Col xs={24}>
                            {
                                this.state.Promo?.coupon?.amount_off ?
                                <p className="Checkout-Promo-Applied-Products">A discount of ${this.state.Promo.coupon.amount_off/100} {String(this.state.Promo.coupon.currency).toUpperCase()} will be applied at the checkout page.</p>
                                : this.state.Promo?.coupon?.percent_off ?
                                <p className="Checkout-Promo-Applied-Products">A discount of {this.state.Promo.coupon.percent_off}% will be applied at the checkout page.</p>
                                : null
                            }
                        </Col>
                        <Col xs={24}>
                            {
                                this.state.Promo?.applied_products?.length > 0 ?
                                <p className="Checkout-Promo-Applied-Products">
                                    This discount only applies to the following products:
                                    <ul>
                                    {
                                        this.state.Promo?.applied_products?.map((id) => (
                                            <li>{this.state.Promo?.products[id]}</li>
                                        ))
                                    }
                                    </ul>
                                </p>
                                : null
                            }
                        </Col>
                    </Row>
                    :
                    null
                }
                {
                    currentUser ?
                    <Row className="Checkout-Row" justify="space-between" gutter={[10,10]}>
                        <Col xs={18}>
                            <Input value={this.state.PromoCode} onChange={(e) => this.handleChange("PromoCode", e.target.value)} className="Checkout-Input" style={{ width: "100%" }} placeholder="promo code" />
                        </Col>
                        <Col xs={6}>
                            {
                                this.state.PromoLoading ?
                                <Button className="Checkout-Button" block icon={<LoadingOutlined />} />
                                :
                                <Button className="Checkout-Button" block onClick={() => this.handlePromoCode()}>Apply</Button>
                            }
                        </Col>
                        {
                            this.state.PromoTag ?
                            <Col xs={24}>
                                <Tag color="blue">{this.state.PromoTag}</Tag>
                            </Col>
                            :
                            null
                        }

                    </Row>
                    :
                    null
                }
                {
                currentUser ?
                <div>
                    <div style={{ padding: '4px' }}>
                    <Checkbox value={this.state.terms} onChange={(e) => this.handleChange('terms', e.target.checked)}>I agree to the terms and conditions as stated in each waiver form for the purchased items.</Checkbox>
                    </div>
                    <div style={{ padding: '4px' }}>
                    {
                        data.profile?.PARQForm ?
                        <div>
                        <a target="_blank" rel="noreferrer" href="https://firebasestorage.googleapis.com/v0/b/phros-dev-ddfdb.appspot.com/o/pt-assets%2FGeneral%20PAR-Q.pdf?alt=media&token=e744e349-1e25-459b-9ae3-64bd9c0b9580">View PAR-Q Form</a>
                        <p>Please select one of the following below to proceed with checkout:</p>
                        </div>
                        :
                        null
                    }
                    {
                        data.profile?.PARQForm ?
                        <Checkbox value={this.state.parq_one} onChange={(e) => this.handleChange('parq_one', e.target.checked)}>I have honestly answered no to all Par-Q questions, in the presence of a witness if I am 18yrs and older or in the presence of a parent/guardian if I am 18yrs and younger.</Checkbox>
                        :
                        null
                    }
                    </div>
                    <div style={{ padding: '4px' }}>
                    {
                        data.profile?.PARQForm ?
                        <Checkbox value={this.state.parq_two} onChange={(e) => this.handleChange('parq_two', e.target.checked)}>I have answered yes to one or more questions and have contacted my doctor to discuss which questions I have answered yes to, before starting this program.</Checkbox>
                        :
                        null
                    }
                    </div>
                    <PhrosButton disabled={this.state.disabledPurchase} onClick={() => {
                        this.handlePayment();
                    }} text='Checkout' styles={{ marginTop: '15px', background: data.websiteTemplate?.ColourPalette?.DARK }} />
                    <p className="Checkout-Not-Charged-Yet">You won't be charged yet</p>
                </div>
                :
                <div>
                <PhrosButton onClick={() => {
                    this.handleAuthModalVisibility();
                }} text='Checkout' styles={{ background: data.websiteTemplate?.ColourPalette?.DARK }} />
                    <p className="Checkout-Not-Charged-Yet">You won't be charged yet</p>
                </div>
            }
            </Modal>
          </div>
          )
        }
      </DataContext.Consumer>
    )
  }
}

export default Memberships;