import React from 'react';
import './Button.css';
import { Link } from 'react-router-dom';
import { Spin } from 'antd';

class Button extends React.Component {
    render() {
        let style = {};
        if (this.props.styles) {
            style = {
                ...this.props.styles,
            }
        }
        return (
            this.props.link ?
            <Link to={this.props.link}>
                <button className={`Button ${this.props.disabled? 'Button-Disabled' : ''}`} disabled={this.props.disabled} onClick={this.props.onClick} style={style}>
                    {this.props.ButtonLoading ? <Spin size='small' style={{ color: 'black' }} /> : null}
                    {this.props.text}
                </button>
            </Link>
            :
            <button className={`Button ${this.props.disabled? 'Button-Disabled' : ''}`} disabled={this.props.disabled}  onClick={this.props.onClick} style={style}>
                {this.props.ButtonLoading ? <Spin size='small' style={{ color: 'black' }} /> : null}
                {this.props.text}
            </button>
        )
    }
}
export default Button;