import React from 'react';
import './VideoCard.css';
import { H2, H3, P, PhrosButton } from '../../Common';
import { Row, Col, Modal, message, Tag } from 'antd';
import { TRAINING_EXPERIENCE_LEVEL } from '../../constants';
import { connect } from 'react-redux';
import { addOnDemandVid  } from '../../Redux/actions';
import { DataContext } from '../../Context/Data';


class VideoCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
        }
        this.handleAddToCart = this.handleAddToCart.bind(this);
    }

    changeModalVisibility = () => {
        this.setState({
            modalOpen: !this.state.modalOpen
        })
    }

    handleAddToCart = () => {
        this.changeModalVisibility();
        this.props.addOnDemandVid(this.props.video);
        message.success(`${this.props.video.Title} added to cart.`);
    }

    render() {
        return (
            <DataContext.Consumer>
                {
                    data => {
                        return (
                            <div className='Video-Card'>
                            <img src={this.props.video.ThumbnailURL} alt={this.props.video.Title} className="Video-Card-Image" />
                            <div className='Video-Card-Container'>
                            <H3 text={`${this.props.video.Title}`} />
                            {
                                this.props.video.Price === 0 ?
                                <P text="Free Class" /> : null
                            }
                            <P text={`$${this.props.video.Price} ${this.props.video.Currency}`} />
                            <h3>Intensity: {this.props.video.IntensityRating}/5  - {TRAINING_EXPERIENCE_LEVEL[this.props.video.Level]}</h3>
                            <div className='Video-Card-Button'>
                                <PhrosButton onClick={this.changeModalVisibility} text='view' styles={{ background: data.websiteTemplate?.ColourPalette?.DARK }}/>
                            </div>
                            </div>
                            <Modal cancelText="Close"
                            footer={null}
                            okText="Add to Cart" width={800} visible={this.state.modalOpen} onOk={this.handleAddToCart} onCancel={this.changeModalVisibility}>
                                <Row gutter={[20,20]} style={{ padding: '10px' }}>
                                    <Col xs={24} sm={12}>
                                        <img src={this.props.video.ThumbnailURL} alt={this.props.video.Title} className="Video-Card-Modal-Image" />
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <div>
                                        <H2 text={`${this.props.video.Title}`} />
                                        {
                                            this.props.video.Price === 0 ?
                                            <H3 text="Free Class" />
                                            :
                                            <H3 text={`$${this.props.video.Price} ${this.props.video.Currency}`} />
                                        }
                                        {
                                            this.props.video.Categories.map((cat) => {
                                                return (
                                                <Tag
                                                color={data.websiteTemplate?.ColourPalette?.MEDIUM}
                                                className="Video-Card-Tag">
                                                    {cat}
                                                </Tag>
                                                );
                                            })
                                        }
                                        <div className='Video-Card-Space'>
                                        <P text={this.props.video.Description} />
                                        </div>
                                        <div className='Video-Card-Space'>
                                        <P style={{ fontWeight: 400 }} text={`Intensity Rating: ${this.props.video.IntensityRating}/5`} />
                                        </div>
                                        <div className='Video-Card-Space'>
                                        <P style={{ fontWeight: 400 }} text={`Level: ${TRAINING_EXPERIENCE_LEVEL[this.props.video.Level]}`} />
                                        </div>
                                        <PhrosButton text='Add to Cart' onClick={this.handleAddToCart} styles={{ background: data.websiteTemplate?.ColourPalette?.MEDIUM }} />
                                        </div>
                                    </Col>
                                </Row>
                            </Modal>
                        </div>
                        )
                    }
                }
            </DataContext.Consumer>
        )
    }
}

const mapStateToProps = state => ({
    OnDemandVideos: state.OnDemandVideos,
});

const mapDispatchToProps = dispatch => ({
    addOnDemandVid: video => dispatch(addOnDemandVid(video))
})

export default connect(mapStateToProps, mapDispatchToProps)(VideoCard);