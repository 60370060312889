import React from 'react';
import { message, notification } from 'antd';
import { DataContext } from '../../../Context/Data';
import axios from 'axios';
import { ENVIRONMENT } from '../../../constants';
import { WEBSITE_COMPONENTS } from '../../../constants';
import { Banner1, Banner2, Banner3, Banner4 } from '../index';

class Banner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Email: "",
        }
        this.handleChange = this.handleChange.bind(this);
        this.submitContact = this.submitContact.bind(this);
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value,
        })
    }

    validateEmail = (email) => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
        return (true)
        }
        message.error("Please enter a valid email.")
        return (false)
    }

    validateTextInput = (text, name) => {
        if (String(text).length === 0) {
            message.error(`Please enter a ${name}.`)
            return false;
        } else {
            return true;
        }
    }

    submitContact = async (trainerEmail) => {
        const emailValidate = this.validateEmail(this.state.Email);
        if (!emailValidate) return;
        const data = {
            name: "",
            email: this.state.Email,
            phone: "",
            message: "",
            TrainerEmail: trainerEmail,
        }
        const sendEmail = await axios.post(`${ENVIRONMENT.BACKEND_URL}/custom-website/contact`, data);
        if (sendEmail.data.error) {
            notification.error({
                message: "Something went wrong!",
                description: `Sorry we couldn't send you message, please send me an email at ${trainerEmail}`,
                duration: 0,
            })
        } else {
            notification.success({
                message: "Success",
                description: "Thanks for your message, I'll be in contact with you soon.",
            })
        }
    }


    render() {
        return (
            <DataContext.Consumer>
                {
                    data => {
                        const websiteData = data.websiteTemplate;
                        const banner = (
                            websiteData?.SelectedDesigns?.BANNER === WEBSITE_COMPONENTS.BANNERS.BANNER1 ?
                            <Banner1
                                BannerImageURL = {websiteData?.BannerImageURL}
                                ProfessionalTitle = {websiteData?.ProfessionalTitle}
                                FirstName = {websiteData?.FirstName}
                                LastName = {websiteData?.LastName}
                                BusinessStatement = {websiteData?.BusinessStatement}
                                ColourPalette = {websiteData?.ColourPalette}
                                handleChange = {this.handleChange}
                                submitContact = {() => this.submitContact(websiteData?.Email)}
                            />
                            :
                            websiteData?.SelectedDesigns?.BANNER === WEBSITE_COMPONENTS.BANNERS.BANNER2 ?
                            <Banner2
                                BannerImageURL = {websiteData?.BannerImageURL}
                                ProfessionalTitle = {websiteData?.ProfessionalTitle}
                                FirstName = {websiteData?.FirstName}
                                LastName = {websiteData?.LastName}
                                BusinessStatement = {websiteData?.BusinessStatement}
                                ColourPalette = {websiteData?.ColourPalette}
                                handleChange = {this.handleChange}
                                submitContact = {() => this.submitContact(websiteData?.Email)}
                            />
                            :
                            websiteData?.SelectedDesigns?.BANNER === WEBSITE_COMPONENTS.BANNERS.BANNER3 ?
                            <Banner3
                                BannerImageURL = {websiteData?.BannerImageURL}
                                ProfessionalTitle = {websiteData?.ProfessionalTitle}
                                FirstName = {websiteData?.FirstName}
                                LastName = {websiteData?.LastName}
                                BusinessStatement = {websiteData?.BusinessStatement}
                                ColourPalette = {websiteData?.ColourPalette}
                                handleChange = {this.handleChange}
                                submitContact = {() => this.submitContact(websiteData?.Email)}
                            />
                            :
                            websiteData?.SelectedDesigns?.BANNER === WEBSITE_COMPONENTS.BANNERS.BANNER4 ?
                            <Banner4
                                BannerImageURL = {websiteData?.BannerImageURL}
                                ProfessionalTitle = {websiteData?.ProfessionalTitle}
                                FirstName = {websiteData?.FirstName}
                                LastName = {websiteData?.LastName}
                                BusinessStatement = {websiteData?.BusinessStatement}
                                ColourPalette = {websiteData?.ColourPalette}
                                handleChange = {this.handleChange}
                                submitContact = {() => this.submitContact(websiteData?.Email)}
                            />
                            :
                            null
                        )
                        return banner;
                    }
        }
        </DataContext.Consumer>
        )
    }
}

export default Banner;