import React from 'react';
import './Navbar1.css';
import { Row, Col, Button, Menu, Drawer, Dropdown, Divider, Badge } from 'antd';
import { Link } from 'react-router-dom';
import { checkArray } from '../../../../ValidateInput';
import { SocialIcon } from 'react-social-icons';
import { DownOutlined, ShoppingOutlined, MenuOutlined } from "@ant-design/icons";
import { HashLink } from 'react-router-hash-link';
import { connect } from 'react-redux';

/*
Props:
LogoURL
BusinessName
ExistingWebsite
ExistingWebsiteURL
SocialMedia
Profile
*/

class Navbar1 extends React.Component {
    state = {
        visible: false,
    }

    render() {
        const cartTotal = checkArray(this.props.OnDemandVideos).length + checkArray(this.props.LiveClasses).length + checkArray(this.props.Bootcamps).length + checkArray(this.props.Programs).length || 0;
        const dropdownMenu = (
            <Menu>
                {
                    this.props.Profile?.PLAN?.FEATURES?.LIVECLASSES ?
                    <Menu.Item>
                        <Link className="Navbar1-Large-Link-Text" to="/live-classes">Live Classes</Link>
                    </Menu.Item>
                    :
                    null
                }
                {
                    this.props.Profile?.PLAN?.FEATURES?.LIVECLASSES ?
                    <Menu.Item>
                        <Link className="Navbar1-Large-Link-Text" to="/bootcamps">Bootcamps</Link>
                    </Menu.Item>
                    :
                    null
                }
                {
                    this.props.Profile?.PLAN?.FEATURES?.ONDEMAND ?
                    <Menu.Item>
                        <Link className="Navbar1-Large-Link-Text" to="/on-demand-classes">On Demand Videos</Link>
                    </Menu.Item>
                    :
                    null
                }
                {
                    this.props.Profile?.PLAN?.FEATURES?.TRAININGPROGRAM ?
                    <Menu.Item>
                        <Link className="Navbar1-Large-Link-Text" to="/programs">Workout Programs</Link>
                    </Menu.Item>
                    :
                    null
                }
                {
                    this.props.Profile?.PLAN?.FEATURES?.MEMBERSHIPS ?
                    <Menu.Item>
                        <Link className="Navbar1-Large-Link-Text" to="/memberships">Memberships</Link>
                    </Menu.Item>
                    :
                    null
                }
            </Menu>
        )
        return (
            <Row className="Navbar1-Main-Row">
            <Col xs={0} md={24}>
                <Row justify="space-between" align="middle">
                    <Col>
                        <Row align="middle">
                            <Col>
                            {
                                this.props.ExistingWebsite ?
                                <a href={this.props.ExistingWebsiteURL}>
                                <img className="Navbar1-Large-Logo" src={this.props.LogoURL} alt={this.props.BusinessName} />
                                </a>
                                :
                                <Link to="/">
                                <img className="Navbar1-Large-Logo" src={this.props.LogoURL} alt={this.props.BusinessName} />
                                </Link>
                            }
                            </Col>
                            <Col>
                            {
                                this.props.ExistingWebsite ?
                                <a href={this.props.ExistingWebsiteURL}>
                                <h3 className="Navbar1-Large-BusinessName">{this.props.BusinessName}</h3>
                                </a>
                                :
                                <Link to="/">
                                <h3 className="Navbar1-Large-BusinessName">{this.props.BusinessName}</h3>
                                </Link>
                            }
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row gutter={[20,10]} align="middle">
                            <Col>
                                {
                                    this.props.ExistingWebsite ?
                                    <a className="Navbar1-Large-Link-Text" href={this.props.ExistingWebsiteURL}>Home</a>
                                    :
                                    <Link to="/" className="Navbar1-Large-Link-Text">Home</Link>
                                }
                            </Col>
                            <Col>
                                <HashLink to="/#contact-me" className="Navbar1-Large-Link-Text">Contact Me</HashLink>
                            </Col>
                            <Col>
                               <Dropdown overlay={dropdownMenu}>
                                <Button className="Navbar1-Large-Link-Text" type="link" onClick={e => e.preventDefault()}>
                                Shop <DownOutlined />
                                </Button>
                               </Dropdown>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row gutter={[10,10]} align="middle">
                            {
                                checkArray(this.props.SocialMedia).map(sm => (
                                    <Col>
                                    <SocialIcon bgColor="black" fgColor="white" className="Navbar1-React-Social-Icon"  url={sm} />
                                    </Col>
                                ))
                            }
                            <Col>
                            <Link to="/checkout">
                            <Badge count={cartTotal} showZero style={{ backgroundColor: "black", borderColor: "black" }}>
                                    <Button type="link" icon={<ShoppingOutlined className="Navbar1-Shopping-Cart" />} />
                            </Badge>
                            </Link>
                            </Col>
                            {
                                this.props.currentUser ?
                                <Col>
                                  <Link to="/my-fitness" className="Navbar1-Large-Link-Text">My Fitness</Link>
                                </Col>
                                :
                                null
                            }
                            {
                                !this.props.currentUser ?
                                <Col className="Navbar-Small-Drawer-Link">
                                <button className="Navbar1-Large-Login-Button" onClick={() => this.props.handleSignIn()}>Login</button>
                                </Col>
                                :
                                <Col className="Navbar-Small-Drawer-Link">
                                <button className="Navbar1-Large-Login-Button" onClick={() => this.props.handleSignOut()}>Sign Out</button>
                                </Col>
                            }
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col xs={24} sm={0}>
                <Row justify="space-between" align="middle">
                    <Col>
                    {
                        this.props.ExistingWebsite ?
                        <a href={this.props.ExistingWebsiteURL}>
                        <img className="Navbar1-Small-Logo" src={this.props.LogoURL} alt={this.props.BusinessName} />
                        </a>
                        :
                        <Link to="/">
                        <img className="Navbar1-Small-Logo" src={this.props.LogoURL} alt={this.props.BusinessName} />
                        </Link>
                    }
                    </Col>
                    <Col>
                        <Button onClick={() => {
                            this.setState({
                                visible: true,
                            })
                        }} icon={<MenuOutlined />} />
                    </Col>
                </Row>
                <Row justify="center" className="Navbar-Drawer">
                    <Col xs={24}>
                    <Drawer
                    placement="right"
                    closable
                    onClose={() => {
                        this.setState({
                            visible: false,
                        })
                    }}
                    visible={this.state.visible}>
                        <Row justify="center">
                            <Col xs={24} className="Navbar-Small-Logo-Name">
                            {
                                this.props.ExistingWebsite ?
                                <a href={this.props.ExistingWebsiteURL}>
                                <img className="Navbar1-Small-Logo" src={this.props.LogoURL} alt={this.props.BusinessName} />
                                <h3 className="Navbar1-Small-BusinessName">{this.props.BusinessName}</h3>
                                </a>
                                :
                                <Link to="/">
                                <img className="Navbar1-Small-Logo" src={this.props.LogoURL} alt={this.props.BusinessName} />
                                <h3 className="Navbar1-Small-BusinessName">{this.props.BusinessName}</h3>
                                </Link>
                            }
                            <Divider />
                            </Col>
                            <Col xs={24} className="Navbar-Small-Drawer-Link">
                                {
                                    this.props.ExistingWebsite ?
                                    <a className="Navbar1-Large-Link-Text" href={this.props.ExistingWebsiteURL}>Home</a>
                                    :
                                    <Link to="/" className="Navbar1-Large-Link-Text">Home</Link>
                                }
                            </Col>
                            <Col xs={24} className="Navbar-Small-Drawer-Link">
                            <HashLink to="/#contact-me" className="Navbar1-Large-Link-Text">Contact Me</HashLink>
                            </Col>
                            {
                                this.props.Profile?.PLAN?.FEATURES?.LIVECLASSES ?
                                <Col xs={24} className="Navbar-Small-Drawer-Link">
                                    <Link className="Navbar1-Large-Link-Text" to="/live-classes">Live Classes</Link>
                                </Col>
                                :
                                null
                            }
                            {
                                this.props.Profile?.PLAN?.FEATURES?.ONDEMAND ?
                                <Col xs={24} className="Navbar-Small-Drawer-Link">
                                    <Link className="Navbar1-Large-Link-Text" to="/on-demand-classes">On Demand Videos</Link>
                                </Col>
                                :
                                null
                            }
                            {
                                this.props.Profile?.PLAN?.FEATURES?.TRAININGPROGRAM ?
                                <Col xs={24} className="Navbar-Small-Drawer-Link">
                                    <Link className="Navbar1-Large-Link-Text" to="/programs">Workout Programs</Link>
                                </Col>
                                :
                                null
                            }
                            {
                                this.props.Profile?.PLAN?.FEATURES?.MEMBERSHIPS ?
                                <Col xs={24} className="Navbar-Small-Drawer-Link">
                                    <Link className="Navbar1-Large-Link-Text" to="/memberships">Memberships</Link>
                                </Col>
                                :
                                null
                            }
                            <Col xs={24} className="Navbar-Small-Drawer-Link">
                            <Link to="/checkout">
                            <Button type="link" icon={<ShoppingOutlined className="Navbar1-Shopping-Cart" />} />
                            </Link>
                            </Col>
                            {
                                this.props.currentUser ?
                                <Col xs={24} className="Navbar-Small-Drawer-Link">
                                  <Link to="/my-fitness" className="Navbar1-Large-Link-Text">My Fitness</Link>
                                </Col>
                                :
                                null
                            }
                            {
                                !this.props.currentUser ?
                                <Col xs={24} className="Navbar-Small-Drawer-Link">
                                <button className="Navbar1-Large-Login-Button" onClick={() => this.props.handleSignIn()}>Login</button>
                                </Col>
                                :
                                <Col xs={24} className="Navbar-Small-Drawer-Link">
                                <button className="Navbar1-Large-Login-Button" onClick={() => this.props.handleSignOut()}>Sign Out</button>
                                </Col>
                            }

                            <Col xs={24} className="Navbar-Small-Drawer-Link">
                                <Row justify="center" gutter={[10,10]}>
                                {
                                    checkArray(this.props.SocialMedia).map(sm => (
                                        <Col>
                                        <SocialIcon bgColor="black" fgColor="white" className="Navbar1-React-Social-Icon"  url={sm} />
                                        </Col>
                                    ))
                                }
                                </Row>
                            </Col>
                        </Row>
                    </Drawer>
                    </Col>
                </Row>
            </Col>
            <div className="Shopping-Cart-Float">
                <Link to="/checkout">
                    <Badge count={cartTotal} showZero style={{ backgroundColor: "black", borderColor: "black" }}>
                            <Button type="link" icon={<ShoppingOutlined className="Navbar1-Shopping-Cart" />} />
                    </Badge>
                </Link>
            </div>
            </Row>
        )
    }
}

const mapStateToProps = state => ({
    OnDemandVideos: state.OnDemandVideos,
    LiveClasses: state.LiveClasses,
    Bootcamps: state.Bootcamps,
    Programs: state.Programs,
})

export default connect(mapStateToProps, null)(Navbar1);