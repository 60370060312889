import React from 'react';
import './BookLiveClass.css';
import { Navbar, BookLiveClassDetails, BookLiveClassPayment } from '../../Components';
import { Helmet } from 'react-helmet';
import { DataContext } from '../../Context/Data';
import { Row, Col } from 'antd';

class BookLiveClass extends React.Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }
    render() {
        return (
            <DataContext.Consumer>
            {
              data => (
                <div>
                <Helmet>
                  <title>{data.profile.BusinessName ? data.profile.BusinessName : 'My Personal Training Website'}</title>
                  <meta name="description" content={data.profile.BusinessStatement} />
                  <link rel="icon" type="image/png" href={data.profile.LogoURL} />
                </Helmet>
                <Navbar profile={data.profile} events={data.events} />
                <div>
                    <Row justify='center'>
                    <Col xs={23} sm={22}>
                        <Row justify='space-around'>
                            <Col xs={24} sm={11}>
                                <BookLiveClassDetails profile={data.profile} keyEvents={data.keyEvents} ID={this.props.match.params.ID} AID={this.props.match.params.AID} />
                            </Col>
                            <Col xs={24} sm={11}>
                                <BookLiveClassPayment profile={data.profile} keyEvents={data.keyEvents} ID={this.props.match.params.ID} AID={this.props.match.params.AID} />
                            </Col>
                        </Row>
                    </Col>
                    </Row>
                </div>
              </div>
              )
            }
          </DataContext.Consumer>
        )
    }
}

export default BookLiveClass;