import React from 'react';
import './Gallery1.css';
import { Row, Col } from 'antd';
import { checkArray } from '../../../../ValidateInput';

class Gallery1 extends React.Component {
    render() {
        return (
        <Row justify="center">
        <Col xs={23}>
            <Row gutter={[20,20]} justify="center">
            {
                checkArray(this.props.Gallery).map((img) => {
                    return (
                        <Col xs={24} sm={8}>
                            <img src={img.ImageURL} alt={img.ALT} className="Gallery1-Image" />
                        </Col>
                    )
                })
            }
            </Row>
        </Col>
        </Row>
        )
    }
}

export default Gallery1;