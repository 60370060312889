import React from 'react';
import './typography.css';

class H1 extends React.Component {
    render() {
        return (
            this.props.style ?
            <h1 style={this.props.style} className={`H1 ${this.props.className}`}>{this.props.text}</h1>
            :
            <h1 className={`H1 ${this.props.className}`}>{this.props.text}</h1>
        )
    }
}

export default H1;