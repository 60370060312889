import React from 'react';
import './LiveClasses.css';
import { Navbar, ClassesHeader, LiveClassCard } from '../../Components';
import { Helmet } from 'react-helmet';
import { DataContext } from '../../Context/Data';
import { Row, Col } from 'antd';


class LiveClasses extends React.Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }
    render() {
        return (
            <DataContext.Consumer>
            {
              data => (
                <div>
                <Helmet>
                  <title>{data.profile.BusinessName ? data.profile.BusinessName : 'My Personal Training Website'}</title>
                  <meta name="description" content={data.profile.BusinessStatement} />
                  <link rel="icon" type="image/png" href={data.profile.LogoURL} />
                </Helmet>
                <Navbar />
                <ClassesHeader profile={data.profile} websiteTemplate={data.websiteTemplate} />
                <Row justify='center'>
                  <Col xs={23} sm={22}>

                  <Row gutter={[30,30]} align='stretch'>
                  {
                    data.events.map((liveClass) => {
                      return (
                        <Col xs={24} sm={8}>
                          <LiveClassCard liveClass={liveClass} profile={data.profile} />
                        </Col>
                      )
                    })
                  }
                  </Row>

                  </Col>
                </Row>
              </div>
              )
            }
          </DataContext.Consumer>
        )
    }
}

export default LiveClasses;