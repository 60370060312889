import React from 'react';
import './Header.css';
import { Row, Col, Button } from 'antd';
import { H1, H2, H3 } from '../../Common';
import { COLOUR_PALETTE } from '../../constants';
import HeaderImageSVG from './HeaderImageSVG';
import { renderToStaticMarkup } from 'react-dom/server';

class Header extends React.Component {
    render() {
        const svgString = encodeURIComponent(
            renderToStaticMarkup(
                <HeaderImageSVG
                fill={COLOUR_PALETTE[this.props.profile.ColourScheme].LIGHT}
                />
            )
        );

        return (
            <div>
                <Row justify='center' align='middle' className='Header-Main-Row'>
                    <Col xs={0} sm={22}
                    className='Header-Background'
                    style={{
                        backgroundImage: `url("data:image/svg+xml;utf8,${svgString}")`,
                        backgroundRepeat: 'no-repeat',
                    }}
                    >
                        <Row justify='center' align='middle'>
                            <Col xs={24} sm={8} className='Header-Image-Col' style={{ height: '75vh' }}>
                                <img src={this.props.profile.PictureURL} alt={this.props.profile.BusinessName} className='Header-Picture' />
                            </Col>
                            <Col xs={24} sm={12} className='Header-Text-Col'>
                                <H1 text={this.props.profile.BusinessName} className='Header-Title' />
                                <H2 style={{}} spanStyle={{ background: `linear-gradient(to top, ${COLOUR_PALETTE[this.props.profile.ColourScheme].MEDIUM} 30%, transparent 30%)` }} text={`${this.props.profile.FirstName} ${this.props.profile.LastName}`} />
                                <H3 text={this.props.profile.BusinessStatement} />
                                <Row gutter={[10,10]}>
                                    <Col xs={10}>
                                        <Button
                                        size="large"
                                        style={{
                                            background: COLOUR_PALETTE[this.props.profile.ColourScheme].DARK,
                                            border:  COLOUR_PALETTE[this.props.profile.ColourScheme].DARK,
                                        }}
                                        className="Header-Button"
                                        >
                                            <a href={`mailto:${this.props.profile.Email}`}>
                                                Contact Me
                                            </a>
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={23} sm={0}
                    style={{
                        backgroundImage: `url("data:image/svg+xml;utf8,${svgString}")`,
                        backgroundRepeat: 'no-repeat',
                    }}
                    className='Header-Background'>
                        <Row justify='center' align='middle'>
                            <Col xs={24} sm={12} className='Header-Text-Col'>
                                <H1 text={this.props.profile.BusinessName} className='Header-Title' />
                                <H2 style={{}} spanStyle={{ background: `linear-gradient(to top, ${COLOUR_PALETTE[this.props.profile.ColourScheme].MEDIUM} 30%, transparent 30%)` }} text={`${this.props.profile.FirstName}  ${this.props.profile.LastName}`} />
                                <H3 text={this.props.profile.BusinessStatement} />
                                <Row gutter={[10,10]} justify="center">
                                    <Col xs={18}>
                                    <Button
                                    block
                                    size="large"
                                    style={{
                                        background: COLOUR_PALETTE[this.props.profile.ColourScheme].DARK,
                                        border:  COLOUR_PALETTE[this.props.profile.ColourScheme].DARK,
                                    }}
                                    className="Header-Button"
                                    >
                                        <a href={`mailto:${this.props.profile.Email}`}>
                                            Contact Me
                                        </a>
                                    </Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} sm={8} className='Header-Image-Col'>
                                <img src={this.props.profile.PictureURL} alt='Yoga Online' className='Header-Picture' />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Header;