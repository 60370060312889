import React from 'react';
import { Navbar, } from '../../Components';
import { Helmet } from 'react-helmet';
import { DataContext } from '../../Context/Data';
import { AuthContext } from '../../Context/Auth';
import { ENVIRONMENT, UID } from '../../constants';
import { Row, Col, notification, Calendar, Spin, DatePicker, Button } from 'antd';
import axios from 'axios';
import { checkArray, checkBool } from '../../ValidateInput';
import moment from 'moment';
import { Link } from 'react-router-dom';
import './Programs.css';
import { CheckCircleFilled } from '@ant-design/icons';

class UserProgram extends React.Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.state = {
            Program: {},
            Exercises: [],
            loading: true,
            CustomerData: {},
            StartDate: moment(new Date()),
        }
    }

    componentDidMount() {
        window.scrollTo(0,0);
        this.getWorkoutData();
    }

    getWorkoutData = async () => {
        const { currentUser } = this.context;;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const payload = {
            ID: this.props.match.params.ID,
            UID: UID,
        }
        const response = await axios.post(`${ENVIRONMENT.BACKEND_URL}/user-workouts/get-user-workout-with-id`, payload, config);

        if (response.data.error) {
            return notification.error({
                message: "Error",
                description: `Error getting the workout (${response.data.error})!`
            })
        } else {
          const date = response.data.CustomerData?.StartDate || new Date();
            this.setState({
                Program: response.data.workout,
                Exercises: response.data.exercises,
                loading: false,
                CustomerData: response.data.CustomerData,
                StartDate: moment(date),
            })
        }
    }

    saveProgramData = async () => {
      const { currentUser } = this.context;;
      const token = await currentUser.getIdToken(true);
      const config = {
          headers: {
              Authorization: `Bearer ${token}`
          }
      };
      const payload = {
          ID: this.props.match.params.ID,
          UID: UID,
          StartDate: this.state.StartDate,
      }
      const response = await axios.post(`${ENVIRONMENT.BACKEND_URL}/user-workouts/save-workout-program-progress`, payload, config);

      if (response.data.error) {
          return notification.error({
              message: "Error",
              description: `Error saving the workout (${response.data.error})`
          })
      } else {
        return notification.success({
          message: "Success",
          description: "Successfully saved."
        })
      }
    }

    getListData = (value) => {
      const startDate = new Date(new Date(this.state.StartDate).setHours(0,0,0,0));
      const currentDate = new Date(new Date(value).setHours(0,0,0,0));

      // Calculate weeks between
      const weekTimeDifference = currentDate.getTime() - startDate.getTime();
      const weekDifference = Math.floor((weekTimeDifference/(1000 * 3600 *24))/7);

      // Calculate Days Between
      const dayTimeDifference = currentDate.getTime() - startDate.getTime();
      const dayDifference = Math.floor(dayTimeDifference / (1000 * 3600 *24))-7*weekDifference;

      if (dayDifference < 0 || weekDifference < 0) return [];

      // Get the Workout
      const day = checkArray(this.state.Program?.Program?.[weekDifference]?.week?.[dayDifference]?.day);
      const completed = checkBool(this.state.CustomerData?.Progress?.[weekDifference]?.week?.[dayDifference]?.day?.Completed);
      const workouts = day.reduce((arr, wk, index) => {
        const item = {
          type: 'success',
          Title: wk.WorkoutName || `Workout ${index+1}`,
          WeekIndex: weekDifference,
          DayIndex: dayDifference,
          Completed: completed,
        }
        return [...arr, item];
      }, []);

      return workouts;
    }

    dateCellRender = (value) => {
      const listData = this.getListData(value);
      return (
        listData.map((item, workoutIndex) => (
            item.Completed ?
            <div className="UserProgram-Day-Complete-Div">
              <CheckCircleFilled className="UserProgram-Day-Complete" />
            </div>
            :
            <Link to={`/program/${this.props.match.params.ID}/${item.WeekIndex}/${item.DayIndex}/${workoutIndex}`}>
              <Button className="UserProgram-Button-Progress" type="primary" block size="small">{item.Title}</Button>
          </Link>
        ))
      )
    }

  handleChange = (name, value) => {
    this.setState({
      [name]: value,
    })
  }

  render() {
    return (
      <DataContext.Consumer>
        {
          data => (
            <div>
            <Helmet>
              <title>{data.profile.BusinessName ? data.profile.BusinessName : 'My Personal Training Website'}</title>
              <meta name="description" content={data.profile.BusinessStatement} />
              <link rel="icon" type="image/png" href={data.profile.LogoURL} />
            </Helmet>
            <Navbar />
            {
              this.state.loading ?
              <Row justify="center" align="middle">
              <Col>
                <Spin size="large" />
              </Col>
              </Row>
              :
              <Row justify="center">
              <Col xs={23} sm={22}>
                <Row>
                  <Col xs={24}><h2>Your Workout Program</h2></Col>
                  <Col xs={24}>
                    <h3>Select/Change Program Start Date</h3>
                    <DatePicker value={this.state.StartDate} onSelect={(value) => this.handleChange("StartDate", value)} />
                    <Button className="UserProgram-Save-Workout" onClick={this.saveProgramData}>Save Workout Start Date</Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <Calendar dateCellRender={this.dateCellRender} />
                  </Col>
                </Row>
              </Col>
              </Row>
            }
          </div>
          )
        }
      </DataContext.Consumer>
    )
  }
}

export default UserProgram;