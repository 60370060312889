const textToSubLink = (text) => {
    let newText = String(text).replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,"");
    newText = newText.replace(/\s+/g, '-').toLowerCase();
    return newText;
}

const generateWorkoutProgressKey = (week, day, workout, exercise = -1, superset = -1, set = -1, name) => {
    let key = "";
    if (set >= 0) {
        key = `${week}_${day}_${workout}_${exercise}_${superset}_${set}_${name}`;
    } else if (superset >= 0) {
        key = `${week}_${day}_${workout}_${exercise}_${superset}_${name}`;
    } else if (exercise >= 0) {
        key = `${week}_${day}_${workout}_${exercise}_${name}`;
    } else {
        key = `${week}_${day}_${workout}_${name}`;
    }
    return key;
}

module.exports = {
    textToSublink: textToSubLink,
    generateWorkoutProgressKey,
}