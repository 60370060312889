import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthContext } from './Context/Auth';

class PrivateRoute extends React.Component {
    static contextType = AuthContext;
    render() {
        const { currentUser } = this.context;
        const Component = this.props.component;
        return (
            currentUser ?
            <Route {...this.props} render={(props) => <Component {...props}/>} />
            :
            <Redirect to='/' />
        )
    }
}

export default PrivateRoute;