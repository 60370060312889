import React from 'react';
import './AvailabilityList.css';
import { Row, Col, Tag, notification, Button } from 'antd';
import { H2, H3, P, PhrosButton, } from '../../Common';
import { ONLINE_IN_PERSON } from '../../constants';
import { connect } from 'react-redux';
import { addLiveClass } from '../../Redux/actions';
import { DataContext } from '../../Context/Data';
import { Redirect } from 'react-router-dom';

class Availability extends React.Component {
    getLocalTimeString(time) {
        let dateTime = new Date(time);
        dateTime = dateTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
        return dateTime;
    }
    render() {
        return (
            <Row align='middle' justify='space-around' className='LiveClass-Row' style={{ borderColor: this.props.ColourScheme.MEDIUM }}>
                <Col xs={24} sm={18} >
                    <H2 text={new Date(this.props.data.Date).toDateString()} />
                    <H3 text={`${this.getLocalTimeString(this.props.data.StartTime)} - ${this.getLocalTimeString(this.props.data.EndTime)}`} />
                    {
                        this.props.data?.OnlineOrInPerson === ONLINE_IN_PERSON.IN_PERSON.value ?
                        <p className="Availability-In-Person">In-person class located at <a href={`https://www.google.com/maps/search/?api=1&query=${this.props.data?.Latitude},${this.props.data?.Longitude}`} target="_blank" rel="noreferrer">{this.props.data?.Address}</a>.</p>
                        :
                        <Tag color="#2a2b2e" className="AvailabilityList-Tag">Virtual/Online</Tag>
                    }
                    {
                        this.props.spots < 10 ?
                        <p className="Availability-Spots">Only {this.props.spots} spots remaining!</p>
                        :
                        null
                    }
                </Col>
                <Col xs={24} sm={5}>
                    <PhrosButton onClick={() => this.props.handleAddToCart(this.props.event, this.props.data)} text="add to cart" styles={{ background: this.props.ColourScheme.DARK }} />
                </Col>
            </Row>
        )
    }
}
class AvailabilityList extends React.Component {

    state = {
        ViewCartRedirect: false
    }

    handleAddToCart = (event, session) => {
        this.props.addLiveClass(event, session);
        notification.success({
            message: `${event.Name} added to cart!`,
            description: <Button onClick={() => this.setState({ ViewCartRedirect: true })}>View Cart</Button>
        })
    }

    render() {
        let availability = this.props.keyEvents[this.props.ID].Availability;
        let totalCap = this.props.keyEvents[this.props.ID].Capacity;
        try {
            availability.sort((a, b) => {
                return new Date(a.Date) - new Date(b.Date);
            })
        } catch(e) {
            console.log(e);
        }
        return (
            <DataContext.Consumer>
                {
                    data => {
                        return (
                            <Row justify='center'>
                                <Col xs={23} sm={22}>
                                    <>
                                    {
                                        this.state.ViewCartRedirect ?
                                        <Redirect to="/checkout" />
                                        :
                                        null
                                    }
                                    </>
                                    <Row>
                                        <Col>
                                            <P text="If you book a virtual/online event, you'll receive the event link on purchase." />
                                        </Col>
                                    </Row>
                                    {
                                        availability.map((avail) => {
                                            const purchased = avail.Purchased || 0;
                                            const spots = totalCap - purchased;
                                            if (spots > -2) {
                                                return <Availability spots={spots} handleAddToCart={this.handleAddToCart} data={avail} event={this.props.keyEvents[this.props.ID]} ColourScheme={data.websiteTemplate?.ColourPalette}/>
                                            } else {
                                                return null;
                                            }
                                        })
                                    }
                                </Col>
                            </Row>
                        )
                    }
                }
            </DataContext.Consumer>

        )
    }
}

const mapStateToProps = state => ({
    LiveClasses: state.LiveClasses,
});

const mapDispatchToProps = dispatch => ({
    addLiveClass: (event, session) => dispatch(addLiveClass(event, session))
})

export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityList);