import React from 'react';
import { H2, H3, P, PhrosButton } from '../../Common';
import { Row, Col, Modal, notification } from 'antd';
import { TRAINING_EXPERIENCE_LEVEL } from '../../constants';
import { connect } from 'react-redux';
import { addBootcamp } from '../../Redux/actions';
import './BootcampCard.css';
import { DataContext } from '../../Context/Data';


class BootcampCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
        }
        this.handleAddToCart = this.handleAddToCart.bind(this);
    }

    changeModalVisibility = () => {
        this.setState({
            modalOpen: !this.state.modalOpen
        })
    }

    handleAddToCart = () => {
        this.changeModalVisibility();
        this.props.addBootcamp(this.props.bootcamp);
        notification.success({
            message: 'Added to cart!',
            description: `${this.props.bootcamp?.Title} added to cart.`
        });
    }

    getLocalTimeString(time) {
        let dateTime = new Date(time);
        dateTime = dateTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
        return dateTime;
    }

    render() {
        let liveclasses = this.props.bootcamp.LiveClasses;
        try {
            liveclasses.sort((a, b) => {
                return new Date(this.props.keyAvailability[a.AvailabilityID].Date) - new Date(this.props.keyAvailability[b.AvailabilityID].Date);
            })
        } catch(e) {
            console.log(e);
        }

        const purchased = this.props.bootcamp.Purchased || 0;
        const capacity = this.props.bootcamp.Capacity || 100;
        const spots = capacity-purchased;

        return (
            <DataContext.Consumer>
                {
                    data => {
                        return (
                            <div className='Video-Card'>
                            <img src={this.props.bootcamp?.PictureURL} alt={this.props.bootcamp?.Title} className="Video-Card-Image" />
                            <div className='Video-Card-Container'>
                            <H3 text={`${this.props.bootcamp?.Title}`} />
                            {
                                this.props.bootcamp?.Price === 0 ?
                                <P text="Free Class" /> : null
                            }
                            <P text={`$${this.props.bootcamp?.Price} ${this.props.bootcamp?.Currency}, ${this.props.bootcamp?.LiveClasses.length} Live Classes`} />
                            <h3>Intensity: {this.props.bootcamp?.IntensityRating}/5  - {TRAINING_EXPERIENCE_LEVEL[this.props.bootcamp?.Level]}</h3>
                            <div className='Video-Card-Button'>
                                <PhrosButton onClick={this.changeModalVisibility} text='view' styles={{ background: data.websiteTemplate?.DARK }}/>
                            </div>
                            </div>
                            <Modal cancelText="Close"
                            footer={null}
                            okText="Add to Cart" width={800} visible={this.state.modalOpen} onOk={this.handleAddToCart} onCancel={this.changeModalVisibility}>
                                <Row gutter={[20,20]} style={{ padding: '10px' }}>
                                    <Col xs={24} sm={12}>
                                        <img src={this.props.bootcamp.PictureURL} alt={this.props.bootcamp?.Title} className="Video-Card-Modal-Image" />
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <div>
                                        <H2 text={`${this.props.bootcamp?.Title}`} />
                                        {
                                            this.props.bootcamp?.Price === 0 ?
                                            <H3 text="Free Class" />
                                            :
                                            <H3 text={`$${this.props.bootcamp?.Price} ${this.props.bootcamp?.Currency}`} />
                                        }

                                        <H3 text={`${this.props.bootcamp?.LiveClasses.length} Live Classes in this Bootcamp`} />
                                        <div className='Video-Card-Space'>
                                        <P text={this.props.bootcamp?.Description} />
                                        </div>
                                        <div className='Video-Card-Space'>
                                        <P style={{ fontWeight: 400 }} text={`Intensity Rating: ${this.props.bootcamp?.IntensityRating}/5`} />
                                        </div>
                                        <div className='Video-Card-Space'>
                                        <P style={{ fontWeight: 400 }} text={`Level: ${TRAINING_EXPERIENCE_LEVEL[this.props.bootcamp?.Level]}`} />
                                        </div>
                                        {
                                            spots <= 10 ?
                                            <div className='Video-Card-Space'>
                                                <p className="Availability-Spots">Only {spots} spots available!</p>
                                            </div>
                                            :
                                            null
                                        }

                                        <PhrosButton text='Add to Cart' onClick={this.handleAddToCart} styles={{ background: data.websiteTemplate?.MEDIUM }} />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    {
                                        liveclasses.map((lc) => {
                                            const startTime = this.props.keyAvailability[lc.AvailabilityID]?.StartTime;
                                            const endTime = this.props.keyAvailability[lc.AvailabilityID]?.EndTime;
                                            return (
                                                <Col xs={24} className='LiveClass-Row' style={{ borderColor: data.websiteTemplate?.MEDIUM }}>
                                                    <Row justify='space-between'>
                                                        <Col>
                                                        <h2>{this.props.keyEvents[lc.ID]?.Name}</h2>
                                                        </Col>
                                                        <Col>
                                                        <p>{new Date(this.props.keyAvailability[lc.AvailabilityID]?.Date).toDateString()} {this.getLocalTimeString(startTime)} - {this.getLocalTimeString(endTime)}</p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </Modal>
                        </div>
                        )
                    }
                }
            </DataContext.Consumer>
        )
    }
}

const mapStateToProps = state => ({
    Bootcamps: state.Bootcamps,
});

const mapDispatchToProps = dispatch => ({
    addBootcamp: bootcamp => dispatch(addBootcamp(bootcamp))
})

export default connect(mapStateToProps, mapDispatchToProps)(BootcampCard);