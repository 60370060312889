import React from 'react';
import { Link } from 'react-router-dom';
import './PurchaseCard.css';


class PurchaseCard extends React.Component {
    render() {
        const cardContent = (
            <div className="PurchaseCard-Container">
                <img className="PurchaseCard-Image" src={this.props.src} alt={this.props.alt} />
                <div className="PurchaseCard-Bottom-Left">
                    <h2 className="PurchaseCard-Title">{this.props.Title}</h2>
                    <p className="PurchaseCard-Description">{this.props.Description}</p>
                </div>
            </div>
        )
        return (
            this.props.Link ?
            <Link to={this.props.Link}>{cardContent}</Link>
            : this.props.onClick ?
            <div onClick={this.props.onClick}>{cardContent}</div>
            : this.props.href ?
            <a href={this.props.href} target="_blank" rel="noreferrer">{cardContent}</a>
            :
            null
        )
    }
}

export default PurchaseCard;