import React from 'react';
import './ClassesHeader.css';
import { Row, Col } from 'antd';
import ClassesHeaderSVG from './ClassesHeaderSVG';
import { renderToStaticMarkup } from 'react-dom/server';
import { COLOUR_PALETTE } from '../../constants';
import { H2, H3 } from '../../Common';

class ClassesHeader extends React.Component {
    render() {
        const svgString = encodeURIComponent(
            renderToStaticMarkup(
                <ClassesHeaderSVG
                fill={this.props.websiteTemplate?.ColourPalette?.DARK}
                />
            )
        );

        return (
            <Row justify='center' style={{ marginTop: "20px" }}>
                <Col xs={23} sm={22}
                style={{
                    backgroundImage: `url("data:image/svg+xml;utf8,${svgString}")`,
                    backgroundRepeat: 'no-repeat',
                }}
                className='ClassesHeader-Background'
                >
                    <Row justify='center'>
                        <Col xs={24} className='ClassesHeader-Col'>
                            <img src={this.props.websiteTemplate?.BannerImageURL} alt={`${this.props.profile.BusinessName}`} className='ClassesHeader-Image' />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} className='ClassesHeader-Col'>
                            <H2 text={this.props.websiteTemplate?.BusinessName} className='ClassHeader-Title' />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} className='ClassesHeader-Col'>
                        <H3 text={`${this.props.websiteTemplate?.FirstName} ${this.props.websiteTemplate?.LastName}`} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default ClassesHeader;