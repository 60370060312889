import { ADD_VIDEO,
    ADD_LIVE_CLASS,
    REMOVE_VIDEO,
    REMOVE_LIVE_CLASS,
    ADD_BOOTCAMP,
    REMOVE_BOOTCAMP,
    ADD_PROGRAM,
    REMOVE_PROGRAM, } from './actionTypes';

const initialState = {
    OnDemandVideos: [],
    LiveClasses: [],
    Bootcamps: [],
    Programs: [],
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case ADD_VIDEO: {
            const vid = action.payload;
            const videos = [...state.OnDemandVideos, vid];
            return {
                ...state,
                OnDemandVideos: videos,
            }
        }
        case ADD_LIVE_CLASS:
            const liveClass = action.payload.event;
            const session = action.payload.session;
            const data = {
                liveClass,
                session,
            }
            const liveClasses = [...state.LiveClasses, data];
            return {
                ...state,
                LiveClasses: liveClasses,
            }

        case ADD_BOOTCAMP:
            const bootcamp = action.payload;
            const bootcamps = [...state.Bootcamps, bootcamp];
            return {
                ...state,
                Bootcamps: bootcamps
            }

        case ADD_PROGRAM:
            const program = action.payload;
            const programs = [...state.Programs, program];
            return {
                ...state,
                Programs: programs,
            }

        case REMOVE_PROGRAM:
            const programId = action.payload;
            let programRemove = [...state.Programs];
            const programIndex = programRemove.findIndex((prog) => prog.ID === programId);
            programRemove.splice(programIndex, 1);
            return {
                ...state,
                Programs: programRemove,
            }

        case REMOVE_VIDEO:
            const id = action.payload;
            let videos = [...state.OnDemandVideos];
            const index = videos.findIndex(vid => vid.ID === id);
            videos.splice(index, 1);
            return {
                ...state,
                OnDemandVideos: videos
            }

        case REMOVE_LIVE_CLASS:
            const liveClassID = action.payload;
            let classes = [...state.LiveClasses];
            const liveClassIndex = classes.findIndex(data => data.liveClass.ID === liveClassID);
            classes.splice(liveClassIndex, 1);
            return {
                ...state,
                LiveClasses: classes
            }

        case REMOVE_BOOTCAMP:
            const bootcampID = action.payload;
            let bootcampClasses = [...state.Bootcamps];
            const bootcampIndex = bootcampClasses.findIndex(data => data.ID === bootcampID);
            bootcampClasses.splice(bootcampIndex, 1);
            return {
                ...state,
                Bootcamps: bootcampClasses
            }


        default:
            return state;
    }
}

export default reducer;