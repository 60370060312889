import './Contact1.css';
import React from 'react';
import { Row, Col, Input } from 'antd';

class Contact1 extends React.Component {
    render() {
        return (
        <Row justify="center" style={{ backgroundColor: "white" }}>
        <Col xs={23}>
            <Row gutter={[20,20]}>
                <Col xs={24}>
                    <h2 className="Contact1-Title">Contact Me</h2>
                </Col>
                <Col xs={24} sm={12}>
                    <Input onChange={(e) => this.props.handleChange("FirstName", e.target.value)} size='large' className="Contact1-Input" placeholder="First Name" />
                </Col>
                <Col xs={24} sm={12}>
                    <Input onChange={(e) => this.props.handleChange("LastName", e.target.value)} size='large' className="Contact1-Input" placeholder="Last Name" />
                </Col>
                <Col xs={24} sm={12}>
                    <Input onChange={(e) => this.props.handleChange("Email", e.target.value)}  size='large' className="Contact1-Input" placeholder="Email" />
                </Col>
                <Col xs={24} sm={12}>
                    <Input onChange={(e) => this.props.handleChange("PhoneNumber", e.target.value)}  size='large' className="Contact1-Input" placeholder="Phone Number" />
                </Col>
                <Col xs={24}>
                    <Input.TextArea onChange={(e) => this.props.handleChange("Message", e.target.value)}  size='large' className="Contact1-Input" autoSize={{ minRows: 4 }} placeholder="Description" />
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <button className="Contact1-Contact-Me" onClick={() => this.props.submitContact()}>Contact Me</button>
                </Col>
            </Row>
        </Col>
        </Row>
        )
    }
}

export default Contact1;