const SOCIAL_MEDIA = {
    INSTAGRAM: 'INSTAGRAM',
    FACEBOOK: 'FACEBOOK',
    TWITTER: 'TWITTER',
    PINTEREST: 'PINTEREST',
    LINKEDIN: 'LINKEDIN',
    YOUTUBE: 'YOUTUBE',
}

const COLOUR_PALETTE = {
    PALETTE_1: {
        NAME: 'Palette 1',
        DARK: '#242582',
        MEDIUM: '#C38D9E',
        LIGHT: '#EDF5E1',
    },
    PALETTE_2: {
        NAME: 'Palette 2',
        DARK: '#1A2930',
        MEDIUM: '#659EBC',
        LIGHT: '#F7CE3E',
    },
    PALETTE_3: {
        NAME: 'Palette 3',
        DARK: '#41B3A3',
        MEDIUM: '#85CDCA',
        LIGHT: '#E8A87C',
    },
    PALETTE_4: {
        NAME: 'Palette 4',
        DARK: '#659EBC',
        MEDIUM: '#DAAD86',
        LIGHT: '#FBEEC1',
    },
    PALETTE_5: {
        NAME: 'Palette 5',
        DARK: '#05396B',
        MEDIUM: '#5CDB94',
        LIGHT: '#EDF5E0',
    },
    PALETTE_6: {
        NAME: 'Palette 6',
        DARK: '#55BDCA',
        MEDIUM: '#96CAEF',
        LIGHT: '#CAFAFE',
    },
    PALETTE_7: {
        NAME: 'Palette 7',
        DARK: '#5D5C61',
        MEDIUM: '#7395AE',
        LIGHT: '#B0A295',
    },
    PALETTE_8: {
        NAME: 'Palette 8',
        DARK: '#AFD274',
        MEDIUM: '#E7717D',
        LIGHT: '#C2C9CF',
    },
    PALETTE_9: {
        NAME: 'Palette 9',
        DARK: '#4B5320',
        MEDIUM: '#FC4444',
        LIGHT: '#AFD274',
    }
}
const TRAINING_EXPERIENCE_LEVEL = {
    'BEGINNER': 'Beginner',
    'INTERMEDIATE': 'Intermediate',
    'ADVANCED': 'Advanced',
    'EXPERT': 'Expert',
    "ALLLEVELS": "All Levels",
}

const TRAINING_INTENSITY_LEVEL = [1,2,3,4,5];

const FITNESS_CATEGORIES = ['Cardiovascular', 'Stamina', 'Strength', 'Flexibility', 'Power', 'Speed', 'Coordination', 'Agility', 'Balance', 'Control', 'Weight Loss', 'Bodybuilding', 'Yoga', 'Powerlifting', 'Weightlifting']

const environment = {
    DEV: {
        BACKEND_URL: "http://localhost:8080"
    },
    PROD: {
        BACKEND_URL: "https://api2.phros.ca"
    }
}

const UNITS = {
    WEIGHT: ['lbs', 'kgs'],
    TIME: ['seconds', 'minutes', 'hours'],
    TARGET_INPUT: { REPS: 'reps', TIMED: 'timed', OTHER: 'other' },
    WEIGHT_INPUT: { PERCENTAGE: 'percentage', LB: "lb", KG: "kg", BODYWEIGHT: 'bodyweight', OTHER: 'other' },
    LB_TO_KG: 0.453592,
};

const RESOURCE_CAPACITY = {
    CAPPED: {
        value: "restricted",
        name: "Restricted",
    },
    UNLIMITED: {
        value: "unlimited",
        name: "Unlimited"
    }
}

const TRIAL_PERIOD = {
    NOTRIAL: {
        value: "no-trial",
        name: "No"
    },
    TRIAL: {
        value: "trial",
        name: "Yes"
    }
}

const ONLINE_IN_PERSON = {
    ONLINE: {
        name: "Online",
        value: "ONLINE",
    },
    IN_PERSON: {
        name: "In Person",
        value: "INPERSON"
    }
}

const WORKOUT_PROGRESS = {
    NOTES: "Notes",
    COMPLETED: "Completed",
}

const WEBSITE_SECTIONS = {
    ABOUTME: {
        NAME: "About Me",
        VALUE: "ABOUTME",
    },
    FAQ: {
        NAME: "FAQ",
        VALUE: "FAQ",
    },
    SERVICES: {
        NAME: "Services",
        VALUE: "SERVICES",
    },
    GALLERY: {
        NAME: "Gallery",
        VALUE: "GALLERY",
    },
    TESTIMONIALS: {
        NAME: "Testimonials",
        VALUE: "TESTIMONIALS"
    },
    CONTACT: {
        NAME: "Contact Form",
        VALUE: "CONTACT",
    },
}

const WEBSITE_LINKS = {
    MEMBERSHIPS: "/memberships",
    LIVECLASSES: "/live-classes",
    WORKOUTPROGRAMS: "/programs",
    ONDEMANDCLASSES: "/on-demand-classes",
    CHECKOUT: "/checkout",
    MYFITNESS: "/my-fitness",
    CONTACT: "/#contact"
}

const PHROS_WEB_DESIGN_ORDER = {
    SELECT_SECTIONS: "SELECT_SECTIONS",
    ADD_BASIC_INFORMATION: "ADD_BASIC_INFORMATION",
    SELECT_ORDER: "SELECT_SECTION_ORDER",
}

const WEBSITE_COMPONENTS = {
    BANNERS: {
        BANNER1: "Banner 1",
        BANNER2: "Banner 2",
        BANNER3: "Banner 3",
        BANNER4: "Banner 4",
    },
    NAVBARS: {
        NAVBAR1: "Navigation Bar 1",
        NAVBAR2: "Navigation Bar 2",
        NAVBAR3: "Navigation Bar 3",
    },
    ABOUTME: {
        ABOUTME1: "About Me 1",
        ABOUTME2: "About Me 2",
        ABOUTME3: "About Me 3",
    },
    GALLERY: {
        GALLERY1: "Gallery 1",
        GALLERY2: "Gallery 2",
    },
    SERVICE: {
        SERVICE1: "Service 1",
        SERVICE2: "Service 2",
        SERVICE3: "Service 3",
    },
    FAQ: {
        FAQ1: "FAQ 1",
        FAQ2: "FAQ 2",
    },
    TESTIMONIALS: {
        TESTIMONIAL1: "Testimonial 1",
        TESTIMONIAL2: "Testimonial 2",
        TESTIMONIAL3: "Testimonial 3",
    },
    CONTACTME: {
        CONTACT1: "Contact Me 1",
        CONTACT2: "Contact Me 2",
    }
}

/*
const BREAKPOINTS = {
    XS: '0px - 575px',
    SM: '576px - 767px',
    MD: '768px - 991px',
    LG: '992px - 1199px',
}
*/

const UID = window.document.getElementById("CLIENT_UID").innerHTML;

module.exports = {
    SOCIAL_MEDIA,
    COLOUR_PALETTE,
    TRAINING_EXPERIENCE_LEVEL,
    TRAINING_INTENSITY_LEVEL,
    ENVIRONMENT: environment.PROD,
    FITNESS_CATEGORIES,
    UNITS,
    RESOURCE_CAPACITY,
    TRIAL_PERIOD,
    ONLINE_IN_PERSON,
    WORKOUT_PROGRESS,
    WEBSITE_COMPONENTS,
    WEBSITE_LINKS,
    PHROS_WEB_DESIGN_ORDER,
    WEBSITE_SECTIONS,
    UID,
}