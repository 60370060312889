import React, { useContext, useState, useEffect } from 'react';
import { ENVIRONMENT, UID } from '../constants';
import axios from 'axios';
import { message, Spin, Row, Col } from 'antd';
import { sortBy } from 'lodash';

export const DataContext = React.createContext();

export function useAuth() {
    return useContext(DataContext);
}

export function DataProvider({ children }) {
    const [profile, setProfileData] = useState(false);
    const [events, setEventData] = useState([]);
    const [keyEvents, setKeyEventData] = useState(false);
    const [keyAvailability, setKeyAvailability] = useState(false);
    const [onDemandVideos, setOnDemandVideos] = useState([]);
    const [keyOnDemandVideos, setKeyOnDemandVideos] = useState(false);
    const [bootcamps, setBootcampData] = useState([]);
    const [keyBootcamps, setKeyBootcamps] = useState(false);
    const [loading, setLoading] = useState(true);
    const [programs, setPrograms] = useState([]);
    const [keyPrograms, setKeyPrograms] = useState(false);
    const [memberships, setMemberships] = useState([]);
    const [keyMemberships, setKeyMemberships] = useState(false);
    const [websiteTemplate, setWebsiteTemplateData] = useState(false);

    useEffect(() => {
        // Get the Profile Data
        const getData = async () => {
            const payload = {
                uid: UID,
            };
            const response = await axios.post(`${ENVIRONMENT.BACKEND_URL}/data/get-website-data`, payload);
            const profileData = response.data.profileData;
            const events = response.data.events;
            const onDemandVids = response.data.onDemandVids;
            const bootcamps = response.data.bootcamps;
            const programs = response.data.programs;
            const memberships = response.data.memberships;
            const websiteData = response.data.websiteData;
            if (profileData.error || events.error || onDemandVids.error || bootcamps.error || programs.error || memberships.error || websiteData.error) {
                return message.error('Something went wrong :( please reload the page.');
            }
            console.log({ profileData, events, onDemandVideos, bootcamps, programs, memberships, websiteData })
            setProfileData(profileData);
            setEventData(events);
            setOnDemandVideos(onDemandVids);
            setBootcampData(bootcamps);
            setPrograms(programs);
            setMemberships(memberships);
            setWebsiteTemplateData(websiteData);

            // Sort out the Events
            try {
                const sortedEvents = sortBy(events, [(lc) => { return lc.Availability[0].Date }]);
                setEventData(sortedEvents);
            } catch (e) {
                setEventData(events);
            }

            const keyEvents = await events.reduce((obj, eve) => {
                return {
                    ...obj,
                    [eve.ID]: eve,
                }
            }, {});
            setKeyEventData(keyEvents);

            const keyAvailability = await events.reduce((obj, eve) => {
                const availability = eve.Availability;
                const keyAvail = availability.reduce((availObj, avail) => {
                    return {
                        ...availObj,
                        [avail.ID]: avail,
                    }
                }, {})
                return {
                    ...obj,
                    ...keyAvail,
                }
            }, {});
            setKeyAvailability(keyAvailability);

            const keyVideos = await onDemandVids.reduce((obj, vid) => {
                return {
                    ...obj,
                    [vid.ID]: vid,
                }
            }, {});
            setKeyOnDemandVideos(keyVideos);

            const keyBootcamps = await bootcamps.reduce((obj, boot) => {
                return {
                    ...obj,
                    [boot.ID]: boot,
                }
            }, {});
            setKeyBootcamps(keyBootcamps);

            const keyPrograms = await programs.reduce((obj, prog) => {
                return {
                    ...obj,
                    [prog.ID]: prog,
                }
            }, {});
            setKeyPrograms(keyPrograms);

            const keyMemberships = await memberships.reduce((obj, memb) => {
                return {
                    ...obj,
                    [memb.ID]: memb,
                }
            }, {});
            setKeyMemberships(keyMemberships);

            setLoading(false)
        }
        getData();
    }, []);

    const value = {
        profile,
        events,
        keyEvents,
        onDemandVideos,
        keyOnDemandVideos,
        bootcamps,
        keyBootcamps,
        keyAvailability,
        programs,
        keyPrograms,
        memberships,
        keyMemberships,
        websiteTemplate,
    };

    return (
        <DataContext.Provider value={value}>
            {
                loading ?
                <Row justify='center' align='middle' style={{ height: '100vh' }}>
                    <Col>
                        <Spin size='large' />
                    </Col>
                </Row>
                :
                children
            }
        </DataContext.Provider>
    )
}

